import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import styles from "./paperStyle-jss";

const useStyles = makeStyles((theme) => styles(theme));

const PaperBlock = (props) => {
  const { title, desc, children, whiteBg, noMargin, colorMode, overflowX } =
    props;

  const classes = useStyles();

  const color = (mode) => {
    switch (mode) {
      case "light":
        return classes.colorLight;
      case "dark":
        return classes.colorDark;
      default:
        return classes.none;
    }
  };
  return (
    <div>
      <Paper
        className={classNames(
          classes.root,
          noMargin && classes.noMargin,
          color(colorMode)
        )}
        elevation={0}
      >
        <div className={classes.descBlock}>
          {/* <span className={classes.iconTitle}>
            <Icon>{icon}</Icon>
          </span> */}
          <div className={classes.titleText}>
            <Typography variant="h6" component="h2" className={classes.title}>
              {title}
            </Typography>
            <Typography component="p" className={classes.description}>
              {desc}
            </Typography>
          </div>
        </div>
        <section
          className={classNames(
            classes.content,
            whiteBg && classes.whiteBg,
            overflowX && classes.overflowX
          )}
        >
          {children}
        </section>
      </Paper>
    </div>
  );
};

PaperBlock.propTypes = {
  // title: PropTypes.string.isRequired,
  // desc: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  whiteBg: PropTypes.bool,
  colorMode: PropTypes.string,
  noMargin: PropTypes.bool,
  overflowX: PropTypes.bool,
};

PaperBlock.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: "none",
  overflowX: false,
  icon: "flag",
};

export default PaperBlock;

import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import Loading from "react-loading-bar";
import {
  withTheme,
  withStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import "assets/styles/components/vendors/react-loading-bar/index.css";
// import MenuContent from "api/ui/menu";
// import { useHistory } from "react-router-dom";
import LanguageProvider from "containers/LanguageProvider";
import applicationTheme from "assets/styles/theme/applicationTheme";

const styles = {
  root: {
    width: "100%",
    minHeight: "100%",
    marginTop: 0,
    zIndex: 1,
  },
};

// Export context for themeing mode
export const AppContext = React.createContext();

const ThemeWrapper = (props) => {
  const { classes, messages, children } = props;
  // const history = useHistory();
  const [locale, setLocale] = useState("en");
  const [subMenuOpen, setSubMenuOpen] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [mode, setMode] = useState("light");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [theme, setTheme] = useState(createTheme(applicationTheme(mode)));

  const menuFunction = (key, keyParent) => {
    setSubMenuOpen([key, keyParent]);
  };

  useEffect(() => {
    setTheme(createTheme(applicationTheme(mode)));
  }, [mode]);

  useEffect(() => {
    playProgress();
    return () => {
      onProgressShow();
    };
    // eslint-disable-next-line
  }, []);

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const onProgressShow = () => {
    setPageLoaded(true);
  };

  const onProgressHide = () => {
    setPageLoaded(true);
  };

  const playProgress = () => {
    onProgressShow();
    setTimeout(() => {
      onProgressHide();
    }, 500);
  };

  const handleChangeMode = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.pageLoader}>
          <Loading
            show={false}
            color={theme.palette.primary.main}
            showSpinner={false}
          />
        </div>
        <AppContext.Provider
          value={{
            mode,
            handleChangeMode,
            handleSidebarOpen,
            sidebarOpen,
            pageLoaded,
            setPageLoaded,
            menuFunction,
            subMenuOpen,
            locale,
            setLocale,
          }}
        >
          <LanguageProvider locale={locale} messages={messages}>
            {children}
          </LanguageProvider>
        </AppContext.Provider>
      </div>
    </MuiThemeProvider>
  );
};

ThemeWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withTheme(withStyles(styles)(ThemeWrapper));

import {
  getWithoutHeader,
  postWithHeader,
  deleteWithHeader,
  patchWithHeader,
  getWithHeader,
} from "api/myApi/ApiFunc";
import { FaqLink } from "api/myApi/link";

export const callFetchSingleFaq = (
  lang,
  params,
  dispatch,
  reducer,
  notification,
  onSuc
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  getWithHeader(
    `${FaqLink(lang)}/admin/${params.id}`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          faqDetail: response.data,
        })
      );
      if (onSuc) onSuc(response.data);
    },
    (error) => {
      let errMsg = {
        message: "Error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callFetchFaq = (lang, params, dispatch, reducer, notification) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  getWithoutHeader(
    `${FaqLink(lang)}`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          faqList: response.data.rows,
          faqCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "Error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAddFaq = (
  lang,
  val,
  dataList,
  dispatch,
  reducer,
  onSuc,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${FaqLink(lang)}/add`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList.push({
        id: response.data.id,
        // job_category_translations: [
        //   { name: response.data.name_en, language: "en" },
        //   { name: response.data.name_jp, language: "jp" },
        // ],
      });
      dispatch(
        reducer({
          loading: false,
          faqList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Added",
            type: "success",
            timer: 3000,
          },
        })
      );
      onSuc && onSuc();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );

      dispatch(
        reducer({
          error: Object.values(payloadMsg)[0],
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};
export const callEditFaq = (
  lang,
  id,
  val,
  dataList,
  dispatch,
  reducer,
  onSuc,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${FaqLink(lang)}/${id}`,
    val,
    (response) => {
      dispatch(
        reducer({
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Edited",
            type: "success",
            timer: 3000,
          },
        })
      );
      onSuc();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteFaq = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${FaqLink(lang)}/${id}`,
    "",
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          faqList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Deleted",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

export const callDeleteFaqDetail = (
  lang,
  data,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${FaqLink(lang)}/detail`,
    { data },
    (response) => {
      dispatch(
        notification({
          data: {
            message: "Successfully Edited",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

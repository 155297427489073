import {
  getWithoutHeader,
  postWithHeader,
  deleteWithHeader,
  patchWithHeader,
} from "api/myApi/ApiFunc";
import { MusicGroupLink } from "api/myApi/link";

export const callFetchMusicGroupList = (
  lang,
  params,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithoutHeader(
    `${MusicGroupLink(lang)}/admin`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          musicGroupList: response.data.rows,
          musicGroupCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "Error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAddMusicGroup = (
  lang,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${MusicGroupLink(lang)}`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList.push({
        id: response.data.id,
        music_group_translations: [
          { name: response.data.name_en, language: "en" },
          { name: response.data.name_jp, language: "jp" },
        ],
      });
      dispatch(
        reducer({
          loading: false,
          musicGroupList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Added",
            type: "success",
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );

      dispatch(
        reducer({
          error: Object.values(payloadMsg)[0],
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};
export const callEditMusicGroup = (
  lang,
  id,
  idx,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${MusicGroupLink(lang)}/${id}`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList[idx] = {
        id: id,
        music_group_translations: [
          { name: response.data.name_en, language: "en" },
          { name: response.data.name_jp, language: "jp" },
        ],
      };
      dispatch(
        reducer({
          loading: false,
          musicGroupList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Edited",
            type: "success",
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteMusicGroup = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${MusicGroupLink(lang)}/${id}`,
    "",
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          musicGroupList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Deleted",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  IconButton,
  Button,
  Typography,
  capitalize,
  Drawer,
  FormControl,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { MUIForms, Field, TextFieldMUI } from "components/Forms/MUIForms";
import { required } from "utils/Validation";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { muiTableLabels } from "utils/muiTableLabels";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  popper: {
    zIndex: 1000,
  },
  popperPaper: {
    padding: theme.spacing(2),
  },
  formDiv: {
    width: "325px",
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelIconButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  searchTextField: {
    width: "50%",
    borderRadius: "10px",
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  addIndustryButton: {
    borderRadius: 2,
  },
}));

const OccupationTable = ({
  data,
  handleSubmit,
  deleteSingleData,
  title,
  setType,
  type,
  open,
  setOpen,
  page,
  setPage,
  perPage,
  setPerPage,
  industryCount,
  searchTerm,
  setSearchTerm,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [placement, setPlacement] = useState();
  const loading = useSelector(({ occupation }) => occupation.loading);

  const [initialValue, setInitialValue] = useState();

  const [idIdx, setIdIdx] = useState("");

  const handleClick = (newPlacement, event) => {
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClose = () => {
    setOpen(false);
    setInitialValue({});
  };

  const columns = [
    {
      name: (
        <FormattedMessage
          id="name_in_japanese"
          defaultMessage="Name In Japanese"
        />
      ),
      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage
          id="name_in_english"
          defaultMessage="Name In English"
        />
      ),
      options: {
        filter: true,
      },
    },

    {
      name: <FormattedMessage id="action" defaultMessage="Action" />,
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <>
              <IconButton
                aria-label="edit"
                onClick={(event) => {
                  handleClick("bottom", event);
                  setInitialValue({
                    name_jp: tableMeta.rowData[0],
                    name_en: tableMeta.rowData[1],
                  });

                  setIdIdx({ id: data[index].id, idx: index });

                  setType("edit");
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  const index = tableMeta.rowIndex;
                  deleteSingleData(data[index].id, index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];
  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const tempData = data.map((singleData) => [
        singleData?.occupation_translations.filter(
          (data) => data.language === "jp"
        )[0].name,
        singleData?.occupation_translations.filter(
          (data) => data.language === "en"
        )[0].name,

        singleData.id,
      ]);
      setColumnData(tempData);
    } else {
      setColumnData([]);
    }
  }, [data]);

  const options = {
    filterType: "dropdown",
    page: page,
    serverSide: true,
    count: industryCount,
    onChangePage: (currentPage) => setPage(currentPage),
    responsive: "stacked",
    print: false,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsPerPage: perPage,
    selectableRows: false,
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    customToolbar: () => {
      return (
        <>
          {/* <CustomSearchTextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}
          &nbsp; &nbsp; &nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleClick("bottom-end", e);
              setType("add");
            }}
            className={classes.addIndustryButton}
          >
            <FormattedMessage
              id="add_occupation"
              defaultMessage="Add Occupation"
            />
          </Button>
        </>
      );
    },
    textLabels: {
      ...muiTableLabels(intl),
      body: {
        noMatch: loading ? (
          <CircularProgress size={30} />
        ) : (
          intl.formatMessage({
            id: "muiTableLabels.body.noMatch",
            defaultMessage: "Sorry, no matching records found",
          })
        ),
      },
    },
  };
  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "occupation_list",
          defaultMessage: "Occupation List",
        })}
        data={columnData}
        columns={columns}
        options={options}
      />

      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        classes={{ root: classes.drawDiv }}
        elevation={5}
      >
        <IconButton className={classes.cancelIconButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <MUIForms
          onSubmit={(val) => handleSubmit({ ...val, idIdx: idIdx })}
          className={classes.formDiv}
          initialValues={initialValue}
        >
          <Typography variant="h6">
            <FormattedMessage
              id={`${type}_occupation`}
              defaultMessage={`${capitalize(type)} Occupation`}
            />
          </Typography>

          <FormControl fullWidth className={classes.formControl}>
            <Field
              name="name_en"
              label={intl.formatMessage({
                id: "name_in_english",
                defaultMessage: "Name In English",
              })}
              variant="outlined"
              size="medium"
              validate={[required]}
              component={TextFieldMUI}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <Field
              name="name_jp"
              label={intl.formatMessage({
                id: "name_in_japanese",
                defaultMessage: "Name In Japanese",
              })}
              variant="outlined"
              size="medium"
              validate={[required]}
              component={TextFieldMUI}
              fullWidth
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            {intl.formatMessage({ id: "submit", defaultMessage: "Submit" })}
          </Button>
        </MUIForms>

        <Divider />
      </Drawer>
    </div>
  );
};

PropTypes.OccupationTable = {
  title: PropTypes.string.isRequired,
};

export default OccupationTable;

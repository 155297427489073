import { patchWithHeader, getWithHeader } from "api/myApi/ApiFunc";
import { WithdrawLink } from "api/myApi/link";

export const callFetchPaymentWithdrawReq = (
  lang,
  params,
  dispatch,
  reducer
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  getWithHeader(
    `${WithdrawLink(lang)}/request-admin`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          paymentWithdrawReqList: response.data.rows,
          paymentWithdrawReqCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

// export const callFetchPaymentWithdrawHis = (
//   lang,
//   params,
//   dispatch,
//   reducer
// ) => {
//   dispatch(
//     reducer({
//       loading: true,
//     })
//   );
//   getWithHeader(
//     `${WithdrawLink(lang)}/history`,
//     { params },
//     (response) => {
//       dispatch(
//         reducer({
//           loading: false,
//           paymentWithdrawHisList: response.data.rows,
//           paymentWithdrawHisCount: response.data.count,
//         })
//       );
//     },
//     (error) => {
//       let errMsg = "There was error !! Please Try Again";
//       let payloadMsg = error?.response?.data?.message ?? errMsg;
//       dispatch(
//         reducer({
//           error: payloadMsg,
//           loading: false,
//         })
//       );
//       // message('error', Object.values(payloadMsg)[0]);
//     }
//   );
// };

export const callVerifyWithdrawReq = (
  lang,
  id,
  idx,
  val,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  const formData = new FormData();
  Object.entries(val).map((i) => {
    formData.append(i[0], i[1]);
    return i;
  });

  patchWithHeader(
    `${WithdrawLink(lang)}/withdraw-verify/${id}`,
    formData,
    (response) => {
      const tempList = [...dataList];
      tempList[idx] = {
        ...response.data,
        id: id,
        // event_category_translations: [
        //   { name: response.data.name_en, language: 'en' },
        //   { name: response.data.name_jp, language: 'jp' },
        // ],
      };
      dispatch(
        reducer({
          loading: false,
          paymentWithdrawReqList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Verified",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callReleaseWithdrawReq = (
  lang,
  id,
  idx,
  val,
  dataList,
  dispatch,
  reducer,
  notification,
  sucFunc,
  intl
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${WithdrawLink(lang)}/release-payment/${id}`,
    { status: val },
    () => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          paymentWithdrawReqList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: intl.formatMessage({
              id: `payment_${val}_message_suc`,
              defaultMessage: `Payment ${
                val === "completed" ? "released" : "rejected"
              } successfully`,
            }),
            type: "success",
            timer: 3000,
          },
        })
      );
      if (sucFunc) sucFunc();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

import {
  postWithHeader,
  deleteWithHeader,
  patchWithHeader,
  getWithHeader,
} from 'api/myApi/ApiFunc';
import { EventSupportLink } from 'api/myApi/link';

export const callFetchEventSupportGroupList = (
  lang,
  params,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithHeader(
    `${EventSupportLink(lang)}/group-admin`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          eventSupportGroupList: response.data.rows,
          eventSupportGroupCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: 'Error !! Please Try Again',
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAddEventSupportGroup = (
  lang,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${EventSupportLink(lang)}/add-group`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList.push({
        id: response.data.id,
        event_support_group_translations: [
          { name: response.data.name_en, language: 'en' },
          { name: response.data.name_jp, language: 'jp' },
        ],
      });
      dispatch(
        reducer({
          loading: false,
          eventSupportGroupList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Added',
            type: 'success',
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );

      dispatch(
        reducer({
          error: Object.values(payloadMsg)[0],
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};
export const callEditEventSupportGroup = (
  lang,
  id,
  idx,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${EventSupportLink(lang)}/update-group/${id}`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList[idx] = {
        ...response?.data,
        id: id,
        event_support_group_translations: [
          { name: response.data.name_en, language: 'en' },
          { name: response.data.name_jp, language: 'jp' },
        ],
      };
      dispatch(
        reducer({
          loading: false,
          eventSupportGroupList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Edited',
            type: 'success',
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteEventSupportGroup = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${EventSupportLink(lang)}/group/${id}`,
    '',
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          eventSupportGroupList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Deleted',
            type: 'success',
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

// event Support
export const callFetchEventSupportList = (
  lang,
  params,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithHeader(
    `${EventSupportLink(lang)}/admin`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          eventSupportList: response.data.rows,
          eventSupportCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: 'Error !! Please Try Again',
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAddEventSupport = (
  lang,
  val,
  dispatch,
  reducer,
  successFunction,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${EventSupportLink(lang)}/add`,
    val,
    (response) => {
      dispatch(
        reducer({
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Added',
            type: 'success',
            timer: 3000,
          },
        })
      );
      if (successFunction) successFunction();
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );

      dispatch(
        reducer({
          error: Object.values(payloadMsg)[0],
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};
export const callEditEventSupport = (
  lang,
  id,
  val,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${EventSupportLink(lang)}/${id}`,
    val,
    (response) => {
      dispatch(
        reducer({
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Edited',
            type: 'success',
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteEventSupport = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${EventSupportLink(lang)}/${id}`,
    '',
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          eventSupportList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Deleted',
            type: 'success',
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

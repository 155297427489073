import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'containers/Templates/appStyles-jss';
import { useIntl } from 'react-intl';
import ErrorWrap from 'components/bits/Error/ErrorWrap';
import messages from 'components/bits/Error/messages';

const useStyles = makeStyles((theme) => styles(theme));

const NotFoundDedicated = () => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classNames(classes.appFrameOuter, classes.solidBg)}>
      <main className={classes.outerContent} id="mainContent">
        <ErrorWrap
          title="404"
          invert
          desc={intl.formatMessage(messages.title404)}
        />
      </main>
    </div>
  );
};

export default NotFoundDedicated;

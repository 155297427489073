import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

export default function SimpleSnackbar() {
  const [open, setOpen] = React.useState(false);
  const data = useSelector(({ notification }) => notification.data);
  const [notification, setNotification] = useState("");

  useEffect(() => {
    if (data) {
      setNotification(data);
      setOpen(true);
    }
  }, [data]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={notification?.timer ?? 3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={notification?.type}>
        {notification?.message}
      </Alert>
    </Snackbar>
  );
}

import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CustomDialog } from "components/CustomComponents/CustomModal/Dialog";
import { DangerouslySetInnerHTML } from "components/bits/DangerouslySetInnerHTML";
import { muiTableLabels } from "utils/muiTableLabels";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  addIndustryButton: {
    borderRadius: 2,
  },
  delButton: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: `${theme.palette.error.main}90`,
    },
  },
  imgContainerFaq: {
    width: "100%",
    height: "300px",
    margin: "16px 0",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      background: "#f3f3f390",
    },
  },
}));

const FAQTable = ({
  data,
  page,
  count,
  setPage,
  loading,
  perPage,
  setPerPage,
  deleteSingleData,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [isViewFaqDialogOpen, setIsViewFaqDialogOpen] = useState(false);
  const [faqDetail, setFaqDetail] = useState("");

  const handleViewFaqClick = (i) => () => {
    setFaqDetail(data[i]);
    setIsViewFaqDialogOpen(true);
  };
  const columns = [
    {
      name: <FormattedMessage id="topic" defaultMessage="Topic" />,
      options: {
        filter: true,
      },
    },
    {
      name: <FormattedMessage id="action" defaultMessage="Action" />,
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          return (
            <>
              <Button variant="contained" onClick={handleViewFaqClick(index)}>
                <FormattedMessage id="view" defaultMessage="View" />
              </Button>
              &emsp;
              <Button
                color="primary"
                component={Link}
                variant="contained"
                to={`/app/faq-form/${value}`}
              >
                <FormattedMessage id="modify" defaultMessage="Modify" />
              </Button>
              &emsp;
              <Button
                variant="contained"
                className={classes.delButton}
                onClick={() => {
                  const index = tableMeta.rowIndex;
                  deleteSingleData(data[index].id, index);
                }}
              >
                <FormattedMessage id="delete" defaultMessage="Delete" />
              </Button>
            </>
          );
        },
      },
    },
  ];
  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const tempData = data.map((singleData) => [
        singleData?.faq_titles?.[0]?.title,
        singleData.id,
      ]);
      setColumnData(tempData);
    } else {
      setColumnData([]);
    }
  }, [data]);

  const options = {
    count,
    page: page,
    print: false,
    filter: false,
    search: false,
    download: false,
    serverSide: true,
    viewColumns: false,
    rowsPerPage: perPage,
    selectableRows: false,
    responsive: "stacked",
    filterType: "dropdown",
    rowsPerPageOptions: [10, 20, 50, 100],
    onChangePage: (currentPage) => setPage(currentPage),
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    customToolbar: () => {
      return (
        <>
          &nbsp; &nbsp; &nbsp;
          <Button
            color="primary"
            component={Link}
            to="/app/faq-form"
            variant="contained"
            className={classes.addIndustryButton}
          >
            <FormattedMessage id="add_new" defaultMessage="Add New" />
          </Button>
        </>
      );
    },
    textLabels: {
      ...muiTableLabels(intl),
      body: {
        noMatch: loading ? (
          <CircularProgress size={30} />
        ) : (
          intl.formatMessage({
            id: "muiTableLabels.body.noMatch",
            defaultMessage: "Sorry, no matching records found",
          })
        ),
      },
    },
  };

  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "FAQs",
          defaultMessage: "FAQs",
        })}
        data={columnData}
        columns={columns}
        options={options}
      />
      <CustomDialog
        open={isViewFaqDialogOpen}
        onClose={() => setIsViewFaqDialogOpen(false)}
        // title={faqDetail?.faq_titles?.[0]?.title}
        ContentComponent={
          <Box padding="0 16px 16px">
            <Typography variant="h6" gutterBottom>
              {faqDetail?.faq_titles?.[0]?.title}
            </Typography>
            {faqDetail?.faq_details?.map((i) => (
              <Box key={i.id}>
                <DangerouslySetInnerHTML content={i?.description} />
                {i?.image && (
                  <Box className={classes.imgContainerFaq}>
                    <img src={i?.image} alt={`section-${i.order}`} />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        }
      />
    </div>
  );
};

export default FAQTable;

const initialState = {
  data: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'NOTIFICATION_ACTION':
      return { ...state, ...payload };

    default:
      return state;
  }
};

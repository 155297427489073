import {
  getWithoutHeader,
  postWithHeader,
  deleteWithHeader,
  patchWithHeader,
} from 'api/myApi/ApiFunc';
import { NoticeLink } from 'api/myApi/link';

export const callFetchNoticeList = (
  lang,
  params,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithoutHeader(
    `${NoticeLink(lang)}`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          noticeList: response.data.rows,
          noticeCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: 'Error !! Please Try Again',
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAddNotice = (
  lang,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${NoticeLink(lang)}/add`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList.push({
        ...response?.data,
      });
      dispatch(
        reducer({
          loading: false,
          noticeList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Added',
            type: 'success',
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );

      dispatch(
        reducer({
          error: Object.values(payloadMsg)[0],
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};
export const callEditNotice = (
  lang,
  id,
  idx,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${NoticeLink(lang)}/${id}`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList[idx] = {
        ...response?.data,
      };
      dispatch(
        reducer({
          loading: false,
          noticeList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Edited',
            type: 'success',
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteNotice = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${NoticeLink(lang)}/${id}`,
    '',
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          noticeList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: 'Successfully Deleted',
            type: 'success',
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: 'error',
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

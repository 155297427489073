import { defineMessages } from "react-intl";
export const scope = "boilerplate.containers.LocaleToggle";
export default defineMessages({
  en: {
    id: `${scope}.en`,
    defaultMessage: "English",
  },
  ja: {
    id: `${scope}.ja`,
    defaultMessage: "日本語",
  },
  changeLanguage: {
    id: `${scope}.changeLanguage`,
    defaultMessage: "Change Language",
  },
});

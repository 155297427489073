import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FAQTable from "./FAQTable";
import { chooseLang } from "utils/myFunc";
import { notificationAction } from "redux/notification/action";
import { callFetchFaq, callDeleteFaq } from "redux/faq/apiCall";
import { faqAction } from "redux/faq/action";

const FAQList = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const faqList = useSelector(({ faq }) => faq.faqList);
  const loading = useSelector(({ faq }) => faq.loading);
  const faqCount = useSelector(({ faq }) => faq.faqCount);
  const lang = useSelector(({ settings }) => settings.lang);
  useEffect(() => {
    callFetchFaq(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      faqAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  return (
    <>
      <FAQTable
        page={page}
        data={faqList}
        count={faqCount}
        loading={loading}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        deleteSingleData={(id, idx) => {
          callDeleteFaq(
            chooseLang(lang),
            id,
            idx,
            faqList,
            dispatch,
            faqAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default FAQList;

import { PartnerUserLink } from "api/myApi/link";
import {
  patchWithHeader,
  getWithHeader,
  postWithHeader,
} from "api/myApi/ApiFunc";

export const callFetchPartnerUserReq = (lang, params, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithHeader(
    `${PartnerUserLink(lang)}/user-details`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          list: response.data.rows || response.data,
          count: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

// export const callFetchPartnerUserHis = (
//   lang,
//   params,
//   dispatch,
//   reducer
// ) => {
//   dispatch(
//     reducer({
//       loading: true,
//     })
//   );
//   getWithHeader(
//     `${PartnerUserLink(lang)}/history`,
//     { params },
//     (response) => {
//       dispatch(
//         reducer({
//           loading: false,
//           partnerUserHisList: response.data.rows,
//           partnerUserHisCount: response.data.count,
//         })
//       );
//     },
//     (error) => {
//       let errMsg = "There was error !! Please Try Again";
//       let payloadMsg = error?.response?.data?.message ?? errMsg;
//       dispatch(
//         reducer({
//           error: payloadMsg,
//           loading: false,
//         })
//       );
//       // message('error', Object.values(payloadMsg)[0]);
//     }
//   );
// };

export const callVerifyReq = (
  lang,
  id,
  idx,
  val,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  const formData = new FormData();
  Object.entries(val).map((i) => {
    formData.append(i[0], i[1]);
    return i;
  });

  patchWithHeader(
    `${PartnerUserLink(lang)}/-verify/${id}`,
    formData,
    (response) => {
      const tempList = [...dataList];
      tempList[idx] = {
        ...response.data,
        id: id,
        // event_category_translations: [
        //   { name: response.data.name_en, language: 'en' },
        //   { name: response.data.name_jp, language: 'jp' },
        // ],
      };
      dispatch(
        reducer({
          loading: false,
          partnerUserReqList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Verified",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAcceptRejectPartnerReq = (
  lang,
  id,
  idx,
  val,
  des,
  dataList,
  dispatch,
  reducer,
  notification,
  sucFunc,
  intl
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${PartnerUserLink(lang)}/accept-reject-user/${id}`,
    { status: val, message: des },
    () => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          list: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: intl.formatMessage({
              id: `partner_user_${val}_message_suc`,
              defaultMessage: `Partner User ${
                val === "rejected" ? "rejected" : "accepted"
              } successfully`,
            }),
            type: "success",
            timer: 3000,
          },
        })
      );
      if (sucFunc) sucFunc();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

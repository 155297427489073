import React from "react";
import ThemeWrapper from "./ThemeWrapper";
import { Switch, Route } from "react-router-dom";
import Application from "./Application";
import Auth from "./Auth";
import { Login } from "../pageListAsync";
import { useSelector } from "react-redux";
import Notification from "components/CustomComponents/notification/Notification";

const App = ({ messages }) => {
  const token = useSelector(({ login }) => login?.token?.access_token);

  return (
    <>
      <ThemeWrapper messages={messages}>
        <Switch>
          {token ? (
            <Route component={Application} />
          ) : (
            <Route component={Login} />
          )}
          <Route component={Auth} />
        </Switch>
      </ThemeWrapper>
      <Notification />
    </>
  );
};

export default App;

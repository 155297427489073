import React, { useState, useEffect } from "react";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormattedMessage, useIntl } from "react-intl";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import { setItem } from "utils/storageFunc";
import { muiTableLabels } from "utils/muiTableLabels";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  popper: {
    zIndex: 1000,
  },
  popperPaper: {
    padding: theme.spacing(2),
  },
  formDiv: {
    width: "450px",
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  drawDiv: {
    width: "560px",
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelIconButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  searchTextField: {
    width: "50%",
    borderRadius: "10px",
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  addIndustryButton: {
    borderRadius: 2,
  },
  imageList: {
    position: "relative",
    width: "150px",
    height: "150px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  left_button: {
    position: "absolute",
    left: "-50px",
    top: "50px",
  },
  right_button: {
    position: "absolute",
    top: "50px",
  },
}));

const ImageListComponent = ({ data }) => {
  const classes = useStyles();
  const [active, setActive] = useState(0);

  return (
    <div className={classes.imageList}>
      <IconButton
        className={classes.left_button}
        onClick={() => {
          if (active === 0) {
            setActive(data?.length - 1);
          } else {
            setActive((prev) => prev - 1);
          }
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <img src={data?.[active]} alt="" />
      <IconButton
        className={classes.right_button}
        onClick={() => {
          if (data?.length - 1 > active) {
            setActive((prev) => prev + 1);
          } else {
            setActive(0);
          }
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

const ImageTable = ({
  data,
  deleteSingleData,
  page,
  setPage,
  perPage,
  setPerPage,
  industryCount,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [pages, setPages] = useState({});

  const columns = [
    {
      name: <FormattedMessage id="page" defaultMessage="Page" />,
      options: {
        filter: true,
      },
    },
    {
      name: <FormattedMessage id="type" defaultMessage="Type" />,
      options: {
        filter: true,
      },
    },
    {
      name: <FormattedMessage id="images" defaultMessage="Images" />,
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <ImageListComponent data={value} />
            </>
          );
        },
      },
    },

    {
      name: <FormattedMessage id="action" defaultMessage="Action" />,
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          return (
            <>
              <Link
                to={{ pathname: "/app/banner-images/edit" }}
                onClick={() => {
                  setItem("singleBanner", JSON.stringify(data[index]));
                }}
              >
                <IconButton aria-label="edit">
                  <EditIcon />
                </IconButton>
              </Link>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  const index = tableMeta.rowIndex;
                  deleteSingleData(data[index].id, index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];
  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const tempData = data.map((singleData) => [
        // singleData?.music_group_translations.filter(
        //   (data) => data.language === 'jp'
        // )[0].name,
        // singleData?.music_group_translations.filter(
        //   (data) => data.language === 'en'
        // )[0].name,
        singleData?.page,
        singleData?.type,
        singleData?.images,
      ]);
      setColumnData(tempData);
      const homePagesTypes = [];
      const eventPagesTypes = [];
      data.forEach((element) => {
        if (element?.page === "home") {
          homePagesTypes.push(element?.type);
        }
        if (element?.page === "event") {
          eventPagesTypes.push(element?.type);
        }
      });
      console.log(pages);
      setPages({ home: homePagesTypes, event: eventPagesTypes });
    } else {
      setColumnData([]);
    }
    // eslint-disable-next-line
  }, [data]);

  const options = {
    filterType: "dropdown",
    page: page,
    serverSide: true,
    count: industryCount,
    onChangePage: (currentPage) => setPage(currentPage),
    responsive: "stacked",
    print: false,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsPerPage: perPage,
    selectableRows: false,
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    customToolbar: () => {
      return (
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/app/banner-images/add"
          className={classes.addIndustryButton}
        >
          <FormattedMessage
            id="add_banner_images"
            defaultMessage="Add Banner Images"
          />
        </Button>
      );
    },
    textLabels: {
      ...muiTableLabels(intl),
    },
  };
  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "banner_image_list",
          defaultMessage: "Banner Image List",
        })}
        data={columnData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

PropTypes.ImageTable = {
  title: PropTypes.string.isRequired,
};

export default ImageTable;

import {
  postWithoutHeader,
  patchWithHeader,
  getWithHeader,
} from "api/myApi/ApiFunc";
import { setItem } from "utils/storageFunc";
import { AdminLink } from "api/myApi/link";

const callLogin = (lang, val, dispatch, reducer, notification, history) => {
  dispatch(
    reducer({
      token: null,
      loading: true,
      success: null,
      error: null,
    })
  );
  postWithoutHeader(
    `${AdminLink(lang)}/login`,
    val,
    (response) => {
      // setItem(
      //   'yplrm',
      //   response.data.access_token.slice(7, 14) +
      //     'eFg' +
      //     response.data.access_token.slice(14)
      // );
      // setItem('user', JSON.stringify(response.data.user));
      dispatch(
        reducer({
          loading: false,
          success: "login-success",
          user: response.data.user,
          token: {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          },
        })
      );
      dispatch(
        notification({
          data: {
            message: `Successfully Logged In`,
            type: "success",
            timer: 3000,
          },
        })
      );
      history.push("/app");
    },
    (error) => {
      let errMsg = "There was error login in !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
    }
  );
};

const callSingUp = (
  lang,
  val,
  dispatch,
  reducer,
  forwardFunction,
  notification
) => {
  let formData = new FormData();
  Object.entries(val).map((i) => {
    formData.append(i[0], i[1]);
    return i;
  });

  dispatch(
    reducer({
      token: null,
      loading: true,
      success: null,
      error: null,
    })
  );

  postWithoutHeader(
    `${AdminLink(lang)}/signup`,
    formData,
    (response) => {
      forwardFunction();
      dispatch(
        reducer({
          loading: false,
          success:
            "Account successfully created please verify your account to continue",
          signUpSuccess: true,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "There was error signing up !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
    }
  );
};

export const callForgotPassword = (
  lang,
  val,
  dispatch,
  reducer,
  history,
  notification
) => {
  dispatch(
    reducer({
      token: null,
      loading: true,
      success: null,
      error: null,
    })
  );

  postWithoutHeader(
    `${AdminLink(lang)}/forgot-password`,
    val,
    (response) => {
      history.push("/login");
      dispatch(
        reducer({
          loading: false,
          forgotPasswordMessage:
            "An email with password reset instructions has been sent to your email address, if it exists on our system.",
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "There was error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // dispatch(
      //   notification({
      //     data: {
      //       message: payloadMsg,
      //       type: 'error',
      //       timer: 3000,
      //     },
      //   })
      // );
    }
  );
};

export const callChangeForgotPassword = (
  lang,
  val,
  dispatch,
  reducer,
  history,
  notification
) => {
  dispatch(
    reducer({
      token: null,
      loading: true,
      success: null,
      error: null,
    })
  );
  postWithoutHeader(
    `${AdminLink(lang)}/change-forgot-password`,
    val,
    (response) => {
      history.push("/login");
      dispatch(
        reducer({
          loading: false,
          changeForgotPasswordSuccess: response.message
            ? response.message
            : "Password changed successfully",
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "There was error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          changeForgotPasswordError: "Error while changing your password",
          loading: false,
          name: null,
        })
      );
      // dispatch(
      //   notification({
      //     data: {
      //       message: payloadMsg,
      //       type: 'error',
      //       timer: 3000,
      //     },
      //   })
      // );
    }
  );
};

export const callResendEmailVerification = (
  lang,
  val,
  dispatch,
  reducer,
  history,
  notification
) => {
  dispatch(
    reducer({
      token: null,
      loading: true,
      success: null,
      error: null,
    })
  );

  postWithoutHeader(
    `${AdminLink(lang)}/resend-verification`,
    val,
    (response) => {
      history.push("/login");
      dispatch(
        reducer({
          loading: false,
          resendVerificationSuccessMessage:
            "An email confirmation has been sent to your email address.",
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "There was error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // dispatch(
      //   notification({
      //     data: {
      //       message: payloadMsg,
      //       type: 'error',
      //       timer: 3000,
      //     },
      //   })
      // );
    }
  );
};

export const callFetchProfile = (lang, dispatch, reducer, notification) => {
  dispatch(
    reducer({
      loading: { singleLoading: true },
    })
  );
  getWithHeader(
    `${AdminLink(lang)}/me`,
    "",
    (response) => {
      dispatch(
        reducer({
          loading: { singleLoading: false },
          userProfile: response.data.user,
        })
      );
    },
    (error) => {
      let errMsg = "Error !! Please Try Again";
      let payloadMsg = error?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: { singleLoading: false },
        })
      );
      dispatch(
        notification({
          data: {
            message: "Error !! Please Try Again",
            type: "error",
            timer: 3000,
          },
        })
      );
    }
  );
};

export const callEditProfile = (lang, val, dispatch, reducer, notification) => {
  dispatch(
    reducer({
      editProfileLoading: true,
    })
  );
  patchWithHeader(
    `${AdminLink(lang)}`,
    val,
    (response) => {
      dispatch(
        reducer({
          editProfileLoading: false,
          userProfile: response.data,
        })
      );
      setItem("user", JSON.stringify(response.data));
      dispatch(
        notification({
          data: {
            message: "Successfully Updated",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "Error !! Please Try Again";
      let payloadMsg = error?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          editProfileLoading: false,
        })
      );
      if (Object.entries(val).length > 0) {
        dispatch(
          notification({
            data: {
              message: payloadMsg,
              type: "error",
              timer: 3000,
            },
          })
        );
      }
    }
  );
};

export const callEditProfileImage = (
  lang,
  val,
  changedVal,
  dispatch,
  reducer,
  notification
) => {
  let formData = new FormData();
  Object.entries(val).map((i) => {
    formData.append(i[0], i[1]);
    return i;
  });
  dispatch(
    reducer({
      editProfileLoading: true,
      uploadImage: null,
    })
  );
  patchWithHeader(
    `${AdminLink(lang)}/update-image`,
    formData,
    (response) => {
      if (Object.entries(changedVal).length > 0) {
        callEditProfile(
          lang,
          { ...changedVal },
          dispatch,
          reducer,
          notification
        );
      }
      dispatch(
        reducer({
          editProfileLoading: false,
        })
      );
      if (!Object.entries(changedVal).length > 0) {
        dispatch(
          notification({
            data: {
              message: "Successfully Updated",
              type: "success",
              timer: 3000,
            },
          })
        );
      }
    },
    (error) => {
      let errMsg = "Error !! Please Try Again";
      let payloadMsg = error?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          editProfileLoading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
    }
  );
};

export const callChangePassword = (
  lang,
  val,
  dispatch,
  reducer,
  setInitialPassword,
  notification
) => {
  dispatch(
    reducer({
      passwordLoading: true,
    })
  );
  patchWithHeader(
    `${AdminLink(lang)}/change-password`,
    val,
    (response) => {
      dispatch(
        reducer({
          passwordLoading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Updated",
            type: "success",
            timer: 3000,
          },
        })
      );
      setInitialPassword();
    },
    (error) => {
      let errMsg = "Error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;

      dispatch(
        reducer({
          error: payloadMsg,
          passwordLoading: false,
        })
      );

      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
    }
  );
};

const callLogout = (dispatch, reducer, notification) => {
  dispatch(
    reducer({
      user: null,
      token: null,
    })
  );
  dispatch(
    notification({
      data: {
        message: "Successfully LoggedOut",
        type: "success",
        timer: 3000,
      },
    })
  );
};

export { callLogin, callSingUp, callLogout };

import PropTypes from "prop-types";
import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import PaginationComponent from "components/CustomComponents/Pagination";
import { CustomDialog } from "components/CustomComponents/CustomModal/Dialog";
import classNames from "classnames";
import { StatusPopover } from "components/CustomComponents/CustomPopOver/StatusPopover";
import { StatusColorDiv } from "components/CustomComponents/CustomPopOver/StatusPopover";
import { StatusOption } from "components/CustomComponents/CustomPopOver/StatusPopover";
import { muiTableLabels } from "utils/muiTableLabels";
// import { DangerouslySetInnerHTML } from "components/bits/DangerouslySetInnerHTML";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  imgContainerFeedback: {
    width: "100%",
    height: "300px",
    margin: "16px 0",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      background: "#f3f3f390",
    },
  },
  feedbackType: {
    padding: "4px 8px",
    background: "#e5e5e5",
    borderRadius: "4px",
  },
  feedbackStatus: {
    padding: "4px 8px",
    borderRadius: "4px",
    border: "1px dotted",
    borderColor: "#e5e5e5",
  },
  span_bug: {
    color: "#ff3535",
  },
  span_feedback: {
    color: "#6abf08",
  },
  span_pending: {
    color: "#ff9800",
    borderColor: "#ff9800",
  },
  span_progress: {
    color: "#4b4bff",
    borderColor: "#4b4bff",
  },
  span_rejected: {
    color: "#ff3535",
    borderColor: "#ff3535",
  },
  span_fixed: {
    color: "#6abf08",
    borderColor: "#6abf08",
  },
}));

const findColorByValue = {
  pending: "#ff9800",
  progress: "#4b4bff",
  fixed: "#6abf08",
  rejected: "#ff3535",
};
const FeedbackTable = ({
  title,
  data,
  page,
  count,
  setPage,
  perPage,
  loading,
  setPerPage,
  changeStatus,
  // deleteSingleData,
  // searchTerm,
  // setSearchTerm,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [isViewFeedbackDialogOpen, setIsViewFeedbackDialogOpen] =
    useState(false);

  const [feedbackDetail, setFeedbackDetail] = useState("");

  const handleViewFeedbackClick = (i) => () => {
    setFeedbackDetail(data[i]);
    setIsViewFeedbackDialogOpen(true);
  };

  const columns = [
    {
      name: <FormattedMessage id="topic" defaultMessage="Topic" />,
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ colSpan: 5 }),
        setCellHeaderProps: () => ({ colSpan: 5 }),
      },
    },
    {
      name: intl.formatMessage({
        id: "common:statusAction",
        defaultMessage: `Status           Action`,
      }),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          return (
            <Box display="flex">
              <div className="status">
                <StatusPopover
                  trigger={
                    <StatusColorDiv
                      width="16px"
                      height="16px"
                      color={
                        findColorByValue[value] || findColorByValue["pending"]
                      }
                    />
                  }
                  content={
                    <StatusOption
                      title={intl.formatMessage({
                        id: "common:deliveryStatus",
                        defaultMessage: "Delivery Status",
                      })}
                      options={[
                        {
                          color: findColorByValue["pending"],
                          label: intl.formatMessage({
                            id: "common:setAsPending",
                            defaultMessage: "Set as Pending",
                          }),
                          onClick: () =>
                            changeStatus(data[index].id, index, "pending"),
                        },
                        {
                          color: findColorByValue["progress"],
                          label: intl.formatMessage({
                            id: "common:setAsOnProgress",
                            defaultMessage: "Set as on progress",
                          }),
                          onClick: () =>
                            changeStatus(data[index].id, index, "progress"),
                        },
                        {
                          color: findColorByValue["fixed"],
                          label: intl.formatMessage({
                            id: "common:setAsOnFixed",
                            defaultMessage: "Set as Fixed",
                          }),
                          onClick: () =>
                            changeStatus(data[index].id, index, "fixed"),
                        },
                        {
                          color: findColorByValue["rejected"],
                          label: intl.formatMessage({
                            id: "common:setAsRejected",
                            defaultMessage: "Set as Rejected",
                          }),
                          onClick: () =>
                            changeStatus(data[index].id, index, "rejected"),
                        },
                      ]}
                    />
                  }
                />
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleViewFeedbackClick(index)}
                >
                  <FormattedMessage id="view" defaultMessage="View" />
                </Button>
                {/* &emsp;
                <Button
                  variant="contained"
                  onClick={() => {
                    const index = tableMeta.rowIndex;
                    deleteSingleData(data[index].id, index);
                  }}
                >
                  <FormattedMessage id="delete" defaultMessage="Delete" />
                </Button> */}
              </div>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    count,
    page: page,
    print: false,
    filter: false,
    search: false,
    download: false,
    serverSide: true,
    viewColumns: false,
    rowsPerPage: perPage,
    selectableRows: false,
    responsive: "stacked",
    filterType: "dropdown",
    rowsPerPageOptions: [10, 20, 50, 100],
    onChangePage: (currentPage) => setPage(currentPage),
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    customFooter: () => (
      <PaginationComponent
        page={page}
        setPage={setPage}
        totalCount={Math.ceil(count / 10)}
      />
    ),
    // customToolbar: () => (
    //   <CustomSearchTextField
    //     value={searchTerm}
    //     onChange={(e) => setSearchTerm(e.target.value)}
    //   />
    // ),
    textLabels: {
      ...muiTableLabels(intl),
      body: {
        noMatch: loading ? (
          <CircularProgress size={30} />
        ) : (
          intl.formatMessage({
            id: "muiTableLabels.body.noMatch",
            defaultMessage: "Sorry, no matching records found",
          })
        ),
      },
    },
  };

  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id:
            title === "improvements" ? "improvement_feedback" : "reported_bugs",
          defaultMessage:
            title === "improvements" ? "Improvement Feedback" : "Reported Bugs",
        })}
        columns={columns}
        options={options}
        data={data?.map((i) => [i?.subject, i?.status]) || []}
      />
      <CustomDialog
        open={isViewFeedbackDialogOpen}
        onClose={() => setIsViewFeedbackDialogOpen(false)}
        // title={feedbackDetail?.feedback_titles?.[0]?.title}
        ContentComponent={
          <Box padding="0 16px 16px">
            <Typography variant="h6" gutterBottom>
              {feedbackDetail?.subject}
              &emsp;
              <Typography
                component="span"
                className={classNames(
                  classes.feedbackType,
                  classes[`span_${feedbackDetail?.type}`]
                )}
              >
                {feedbackDetail?.type}
              </Typography>
              &emsp;
              <Typography
                component="span"
                className={classNames(
                  classes.feedbackStatus,
                  classes[`span_${feedbackDetail?.status}`]
                )}
              >
                {feedbackDetail?.status}
              </Typography>
            </Typography>
            <Box>
              <Typography gutterBottom>{feedbackDetail?.message}</Typography>
              {/* <DangerouslySetInnerHTML content={i?.description} /> */}
              {feedbackDetail?.images?.map((i, j) => (
                <Box key={j} className={classes.imgContainerFeedback}>
                  <img src={i} alt={`section-${j}`} />
                </Box>
              ))}
            </Box>
          </Box>
        }
      />
    </div>
  );
};

PropTypes.FeedbackTable = {
  title: PropTypes.string.isRequired,
};

export default FeedbackTable;

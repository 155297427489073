import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import {
  EditorMUI,
  Field,
  MUIForms,
  SelectMUI,
  TextFieldMUI,
} from "components/Forms/MUIForms";
import { FormattedMessage, useIntl } from "react-intl";
import { required } from "utils/Validation";
import {
  callAddEventSupport,
  callEditEventSupport,
  callFetchEventSupportGroupList,
} from "redux/event-support/apiCall";
import { chooseLang } from "utils/myFunc";
import { useDispatch, useSelector } from "react-redux";
import { eventSupportAction } from "redux/event-support/action";
import { notificationAction } from "redux/notification/action";
import { getItem, removeItem } from "utils/storageFunc";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "50%",
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  button: {
    width: 400,
    marginTop: 20,
  },
}));

const AddEventSupport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("Add");
  const [initialValues, setInitialValues] = useState("");

  const loading = useSelector(({ eventSupport }) => eventSupport.loading);
  const eventSupportGroupList = useSelector(
    ({ eventSupport }) => eventSupport.eventSupportGroupList
  );

  useEffect(() => {
    callFetchEventSupportGroupList(
      chooseLang(lang),
      {
        offset: 0,
        limit: 30,
      },
      dispatch,
      eventSupportAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setType("Edit");
    }
  }, [location.pathname]);

  useEffect(() => {
    const singleEventSupport = getItem("singleEventSupport");
    if (singleEventSupport) {
      const parseValue = { ...JSON.parse(singleEventSupport) };
      const englishValue = parseValue?.event_support_translations?.filter(
        (i) => i.language === "en"
      )?.[0];
      // const japaneseValue = parseValue?.event_support_translations?.filter(
      //   (i) => i.language === 'jp'
      // )?.[0];
      parseValue.name_en = englishValue?.name;
      parseValue.description_en = englishValue?.description;
      parseValue.name_jp = englishValue?.name;
      parseValue.description_jp = englishValue?.description;

      delete parseValue?.event_support_translations;

      setInitialValues(parseValue);
    }
    return () => {
      removeItem("singleEventSupport");
    };
  }, []);

  const handleSubmit = (val) => {
    if (type === "Edit") {
      callEditEventSupport(
        chooseLang(lang),
        initialValues?.id,
        val,
        dispatch,
        eventSupportAction,
        () => {
          history.push("/app/event-support");
        },
        notificationAction
      );
    } else {
      callAddEventSupport(
        chooseLang(lang),
        val,
        dispatch,
        eventSupportAction,
        () => {
          history.push("/app/event-support");
        },
        notificationAction
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">
        <FormattedMessage
          id={`${type.toLocaleLowerCase()}_event_support`}
          defaultMessage={`${type} Event Support`}
        />
      </Typography>
      <br />
      <MUIForms
        onSubmit={handleSubmit}
        className={classes.formDiv}
        initialValues={initialValues}
      >
        <FormControl fullWidth className={classes.formControl}>
          <Field
            name="name_en"
            label={intl.formatMessage({
              id: "name_in_english",
              defaultMessage: "Name In English",
            })}
            variant="outlined"
            size="medium"
            validate={[required]}
            component={TextFieldMUI}
            fullWidth
          />
        </FormControl>
        <br />
        <br />
        <InputLabel>
          <FormattedMessage
            id={`description_in_english`}
            defaultMessage="Description In English"
          />
        </InputLabel>
        <br />
        <Field name="description_en" component={EditorMUI} language={lang} />
        <br />
        <br />
        <FormControl fullWidth className={classes.formControl}>
          <Field
            name="name_jp"
            label={intl.formatMessage({
              id: "name_in_japanese",
              defaultMessage: "Name In Japanese",
            })}
            variant="outlined"
            size="medium"
            validate={[required]}
            component={TextFieldMUI}
            fullWidth
          />
        </FormControl>
        <br />
        <br />
        <InputLabel>
          <FormattedMessage
            id={`description_in_japanese`}
            defaultMessage="Description In Japanese"
          />
        </InputLabel>
        <br />
        <Field name="description_jp" component={EditorMUI} language={lang} />
        <br />
        <br />
        <FormControl
          className={classes.formControl}
          fullWidth
          variant="outlined"
        >
          <Field
            name="event_support_group_id"
            label={intl.formatMessage({
              id: "event_support_group",
              defaultMessage: "Event Support Group",
            })}
            variant="outlined"
            size="medium"
            validate={[required]}
            component={SelectMUI}
          >
            {eventSupportGroupList?.length > 0 &&
              eventSupportGroupList.map((data) => (
                <MenuItem key={data?.id} value={data?.id}>
                  {
                    data?.event_support_group_translations?.filter(
                      (i) => i.language === (lang !== "en" ? "jp" : "en")
                    )?.[0]?.name
                  }
                </MenuItem>
              ))}
          </Field>
        </FormControl>
        <br />
        <Box textAlign="center">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.button}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            {intl.formatMessage({ id: "submit", defaultMessage: "Submit" })}
          </Button>
        </Box>
      </MUIForms>
    </Paper>
  );
};

export default AddEventSupport;

// export const capitalize = (val) => val.slice(0, 1).toUpperCase() + val.slice(1);

export const capitalize = ({ first, ...rest }) => first?.toUpperCase() + rest;

export const changedValueFunction = (oldVal, newVal) => {
  let oldValues = { ...oldVal };
  let newValues = { ...newVal };
  let changedValues = {};
  let oldKeys = Object.keys(oldValues);

  oldKeys.forEach((element) => {
    if (oldValues[element] !== newValues[element]) {
      changedValues[element] = newValues[element];
    }
  });

  return changedValues;
};

export const chooseLang = (lang) => {
  if (lang === "ja") {
    return "jp";
  } else {
    return lang;
  }
};

export const deleteImageFunction = (oldImages, newImages) => {
  let fileList = [];
  let oldImageDetailList = [];
  let stringList = [];
  let deleteList = [];

  newImages.forEach((newImage) => {
    if (typeof newImage.file === "string") {
      stringList.push(newImage.file);
      oldImageDetailList.push(newImage);
    } else {
      fileList.push(newImage);
    }
  });

  oldImages.forEach((oldImage) => {
    if (!stringList.includes(oldImage.file)) {
      deleteList.push(oldImage.file);
    }
  });
  return {
    fileList: fileList,
    oldImagesList: oldImageDetailList,
    deleteList: deleteList,
  };
};

import { axios, authAxios } from './Axios';

export function getWithoutHeader(link, data, onSuccess, onFail) {
  axios
    .get(link, data)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}

export function postWithoutHeader(link, data, onSuccess, onFail) {
  axios
    .post(link, data)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}

export function patchWithoutHeader(link, data, onSuccess, onFail) {
  axios
    .patch(link, data)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}

export function deleteWithoutHeader(link, data, onSuccess, onFail) {
  axios
    .delete(link, data)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}
/////////////////////////header
// export function getWithHeader(link, data, token, onSuccess, onFail) {
//   authAxios
//     .get(link, data, { headers: { Authorization: token } })
//     .then(res => res.data)
//     .then(res => {
//       console.log(res, "response");
//       onSuccess(res);
//     })
//     .catch(err => {
//       console.log(JSON.stringify(err, null, 1), "error");
//       onFail(err);
//     });
// }
export function getWithHeader(link, data, onSuccess, onFail) {
  authAxios
    .get(link, data)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}
export function postWithHeader(link, data, onSuccess, onFail, config) {
  authAxios
    .post(link, data, config)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}

export function patchWithHeader(link, data, onSuccess, onFail) {
  authAxios
    .patch(link, data)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}

export function deleteWithHeader(link, data, onSuccess, onFail) {
  authAxios
    .delete(link, data)
    .then((res) => res.data)
    .then((res) => {
      console.log(res, 'response');
      onSuccess(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err, null, 1), 'error');
      onFail(err);
    });
}

import React, { useState, useEffect } from "react";
import { Typography, Paper, makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { callFetchSingleUser } from "redux/user/apiCall";
import { chooseLang } from "utils/myFunc";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "redux/user/action";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px",
    [theme.breakpoints.up("lg")]: {
      padding: "5% 20%",
    },
  },
  profileButton: {
    marginTop: theme.spacing(2),
    width: 300,
  },
  passwordButton: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    cursor: "pointer",
  },
  active: {
    color: theme.palette.primary.main,
    paddingBottom: "10px",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  profileDiv: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  profileImage: {
    width: "140px",
    height: "140px",
    display: "inline-block",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  profileDetail: {
    padding: "15px 0px",
    display: "flex",
    borderBottom: "2px solid #8080801c",
    "& p ": {
      fontSize: "22px",
    },
  },
  leftTitle: {
    flex: "0 0 40%",
    fontWeight: "bold",
  },
}));

const CompanyDetail = ({ data }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage
            id="company_name"
            defaultMessage="Company Name Katakana"
          />
        </Typography>
        <Typography> {data?.company_name} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage
            id="company_name_katakana"
            defaultMessage="Company Name Katakana"
          />
        </Typography>
        <Typography> {data?.company_name_katakana} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage
            id="company_registration"
            defaultMessage="Company Established Date"
          />
        </Typography>
        <Typography> {data?.company_registration} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage
            id="company_address"
            defaultMessage="Company Address"
          />
        </Typography>
        <Typography> {data?.company_address} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage
            id="number_of_employee"
            defaultMessage="Number of employee"
          />
        </Typography>
        <Typography> {data?.employee_count} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage id="industry" defaultMessage="Industry" />
        </Typography>
        <Typography>
          {" "}
          {data?.industry?.industry_translations?.[0]?.name}
        </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage id="website" defaultMessage="Website" />
        </Typography>
        <Typography>
          <a
            href={
              data?.website && data.website.includes("http")
                ? data?.website
                : `//${data?.website}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.website}
          </a>
        </Typography>
      </div>
    </div>
  );
};

const ContactPersonDetail = ({ data }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage
            id="contact_person_name"
            defaultMessage="Contact Person Name"
          />
        </Typography>
        <Typography> {data?.contact_name} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage
            id="contact_person_department"
            defaultMessage="Contact Person Department"
          />
        </Typography>
        <Typography> {data?.contact_department} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage id="email" defaultMessage="Email" />
        </Typography>
        <Typography> {data?.email} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage id="phone_number" defaultMessage="Phone Number" />
        </Typography>
        <Typography> {data?.phone} </Typography>
      </div>
      <div className={classes.profileDetail}>
        <Typography className={classes.leftTitle}>
          <FormattedMessage id="timezone" defaultMessage="Timezone" />
        </Typography>
        <Typography> {data?.timezone} </Typography>
      </div>
    </div>
  );
};

const UserDetail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [active, setActive] = useState(0);
  const { id } = useParams();
  const lang = useSelector(({ settings }) => settings.lang);
  const userProfile = useSelector(({ user }) => user.singleUser);
  useEffect(() => {
    callFetchSingleUser(chooseLang(lang), id, dispatch, userAction);
    // eslint-disable-next-line
  }, [id]);

  return (
    <div>
      <Typography>
        <FormattedMessage id="user_detail" defaultMessage="User Detail" />
      </Typography>
      <br />
      <Paper className={classes.root}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="h4"
            className={clsx(classes.title, active === 0 ? classes.active : "")}
            onClick={() => setActive(0)}
          >
            <FormattedMessage
              id="company_detail"
              defaultMessage="Company Detail"
            />
          </Typography>
          <Typography
            variant="h4"
            className={clsx(classes.title, active === 1 ? classes.active : "")}
            onClick={() => setActive(1)}
          >
            <FormattedMessage
              id="contact_person_detail"
              defaultMessage="Contact Person Detail"
            />
          </Typography>
        </Box>
        {active === 0 ? (
          userProfile?.logo ? (
            <div className={classes.profileDiv}>
              <div className={classes.profileImage}>
                <img src={userProfile?.logo} alt="default-img" />
              </div>
            </div>
          ) : (
            <div className={classes.profileDiv}>
              <div className={classes.profileImage}>
                <img src="/images/default.png" alt="default-img" />
              </div>
            </div>
          )
        ) : userProfile?.contact_person_image ? (
          <div className={classes.profileDiv}>
            <div className={classes.profileImage}>
              <img src={userProfile?.contact_person_image} alt="default-img" />
            </div>
          </div>
        ) : (
          <div className={classes.profileDiv}>
            <div className={classes.profileImage}>
              <img src="/images/default.png" alt="default-img" />
            </div>
          </div>
        )}

        {active === 0 ? (
          <CompanyDetail data={userProfile} />
        ) : (
          <ContactPersonDetail data={userProfile} />
        )}
      </Paper>
    </div>
  );
};
export default UserDetail;

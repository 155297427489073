import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";

import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import CustomSearchTextField from "components/CustomComponents/CustomSearchTextFIeld";
import PaginationComponent from "components/CustomComponents/Pagination";
import { muiTableLabels } from "utils/muiTableLabels";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  popper: {
    zIndex: 1000,
  },
  popperPaper: {
    padding: theme.spacing(2),
  },
  formDiv: {
    width: "325px",
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelIconButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  searchTextField: {
    width: "50%",
    borderRadius: "10px",
  },
  image: {
    width: "60px",
    height: "60px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  viewButton: {
    width: 130,
    borderRadius: 0,
  },
  userAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const UserTable = ({
  data,
  handleSubmit,
  handleSubmitLanguage,
  deleteSingleData,
  title,
  setType,
  type,
  page,
  setPage,
  perPage,
  setPerPage,
  userCount,
  searchTerm,
  setSearchTerm,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const columns = [
    {
      name: (
        <FormattedMessage id="company_logo" defaultMessage="Company Logo" />
      ),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Avatar
                alt=""
                src={value}
                variant="square"
                className={classes.userAvatar}
              />
              {/* <div className={classes.image}>
              <img src={value} alt="company_logo" />
            </div> */}
            </>
          );
        },
      },
    },
    {
      name: (
        <FormattedMessage id="company_name" defaultMessage="Company Name" />
      ),
      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage
          id="company_name_katakana"
          defaultMessage="Company Name In Katakana"
        />
      ),

      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage
          id="company_registration"
          defaultMessage="Company Registration"
        />
      ),

      options: {
        filter: true,
      },
    },
    {
      name: <FormattedMessage id="industry" defaultMessage="Industry" />,

      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage
          id="number_of_employee"
          defaultMessage="Number of employee"
        />
      ),

      options: {
        filter: true,
      },
    },
    {
      name: <FormattedMessage id="website" defaultMessage="Website" />,

      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href={value && value.includes("http") ? value : `//${value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                color="primary"
                variant="outlined"
                className={classes.viewButton}
                onClick={() => history.push(`/app/user/${value}`)}
              >
                <FormattedMessage
                  id="view_detail"
                  defaultMessage="View Detail"
                />
              </Button>
            </>
          );
        },
      },
    },
  ];
  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const tempData = data.map((singleData) => [
        singleData?.logo,
        singleData?.company_name,
        singleData?.company_name_katakana,
        singleData?.company_registration,
        singleData?.industry?.industry_translations?.[0]?.name,
        singleData?.employee_count,
        singleData?.website,
        singleData?.id,
      ]);
      setColumnData(tempData);
    }
  }, [data]);

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    page: page,
    onChangePage: (currentPage) => setPage(currentPage),
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsPerPage: perPage,
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    count: userCount,
    print: false,
    download: false,
    filter: false,
    serverSide: true,
    search: false,
    viewColumns: false,
    selectableRows: false,
    customFooter: () => {
      return (
        <PaginationComponent
          totalCount={Math.ceil(userCount / 10)}
          page={page}
          setPage={setPage}
        />
      );
    },
    customToolbar: () => {
      return (
        <>
          <CustomSearchTextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          &nbsp; &nbsp; &nbsp;
          {/* <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleClick('bottom-end', e);
              setType('add');
              setDrawerType('property');
            }}
          >
            Add {title}
          </Button> */}
        </>
      );
    },
    textLabels: {
      ...muiTableLabels(intl),
      body: {
        noMatch: loading ? (
          <CircularProgress size={30} />
        ) : (
          intl.formatMessage({
            id: "muiTableLabels.body.noMatch",
            defaultMessage: "Sorry, no matching records found",
          })
        ),
      },
    },
  };
  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "user_list",
          defaultMessage: "User List",
        })}
        data={columnData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

PropTypes.UserTable = {
  title: PropTypes.string.isRequired,
};

export default UserTable;

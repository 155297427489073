import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Avatar, Typography } from "@material-ui/core";
import MainMenu from "components/fragments/Sidebar/MainMenu";
import mountain from "assets/images/mountain.jpg";
import { FormattedMessage } from "react-intl";

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  backgroundImage: {
    backgroundImage: `url(${mountain})`,
    backgroundSize: "cover",
    position: "relative",
    width: "100%",
    zIndex: 0,
    transition: "all .4s ease 0s",
    "&::before": {
      content: '" "',
      height: "100%",
      display: "block",
      left: "0px",
      position: "absolute",
      top: "0px",
      width: "100%",
      zIndex: -1,
      background:
        "linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 100%)",
    },
  },

  profileImageDiv: {
    display: "flex",
    height: "220px",
    // justifyContent: 'space-around',
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(3),
  },

  avatarImage: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  avatarDetail: {
    // marginRight: theme.spacing(10),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  sideBarMenu: {
    marginTop: "-100px",
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "none",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const CustomSidebar = ({ dataMenu }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}></div>
        <div className={classes.backgroundImage}>
          <div className={classes.profileImageDiv}>
            <Avatar
              alt="Profile_Image"
              src="../../assets/images/profileImage.jpg"
              className={classes.avatarImage}
            />
            <div className={classes.avatarDetail}>
              <Typography>
                <FormattedMessage id="admin" defaultMessage="Admin" />
              </Typography>
              {/* <Typography variant="caption">ONLINE</Typography> */}
            </div>
          </div>
        </div>
        <MainMenu dataMenu={dataMenu} />
      </Drawer>
    </div>
  );
};

export default CustomSidebar;

import React from "react";
import {
  alpha,
  Select,
  MenuItem,
  InputLabel,
  makeStyles,
  FormControl,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    margin: theme.spacing(0, 1),
  },
}));

const CustomSelectField = (props) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="select-label">{props.label}</InputLabel>
      <Select
        id="select"
        margin="dense"
        value={props.value}
        label={props.label}
        labelId="select-label"
        onChange={props.onChange}
      >
        {props.options.map((i) => (
          <MenuItem key={i?.value} value={i?.value}>
            {i?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectField;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  callFetchIndustryList,
  callAddIndustry,
  callEditIndustry,
  callDeleteIndustry,
} from "redux/industry/apiCall";
import { industryAction } from "redux/industry/action";
import IndustryTable from "./IndustryTable";
import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";

const IndustryList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const industryList = useSelector(({ industry }) => industry.industryList);
  const industryCount = useSelector(({ industry }) => industry.industryCount);

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchIndustryList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      industryAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleSubmit = (val) => {
    const { idIdx, ...rest } = val;
    if (type === "edit") {
      callEditIndustry(
        chooseLang(lang),
        idIdx.id,
        idIdx.idx,
        rest,
        industryList,
        dispatch,
        industryAction,
        () => setOpen(false),
        notificationAction
      );
    } else {
      callAddIndustry(
        chooseLang(lang),
        rest,
        industryList,
        dispatch,
        industryAction,
        () => setOpen(false),
        notificationAction
      );
    }
  };

  return (
    <>
      <IndustryTable
        data={industryList}
        handleSubmit={handleSubmit}
        type={type}
        setType={setType}
        title={"Industry"}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        industryCount={industryCount}
        setSearchTerm={setSearchTerm}
        deleteSingleData={(id, idx) => {
          callDeleteIndustry(
            chooseLang(lang),
            id,
            idx,
            industryList,
            dispatch,
            industryAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default IndustryList;

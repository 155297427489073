import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store, persistor } from "redux/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import App from "./containers/App";
// import './index.css';

import { translationMessages } from "./i18n";

const MOUNT_NODE = document.getElementById("root");

const render = (messages) => {
  ReactDOM.render(
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App messages={messages} />
          </Router>
        </PersistGate>
      </Provider>
    </>,
    MOUNT_NODE
  );
};

if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import("intl"));
  })
    .then(() =>
      Promise.all([
        import("intl/locale-data/jsonp/en.js"),
        import("intl/locale-data/jsonp/ja.js"),
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

if (process.env.REACT_APP_ENV === "production") {
  require("offline-plugin/runtime").install();
}

const initialState = {
  loading: null,
  error: null,
  success: null,
  feedbackList: [],
  feedbackCount: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FEEDBACK_ACTION":
      return { ...state, ...payload };
    default:
      return state;
  }
};

import faq from "./faq/reducer";
import job from "./job/reducer";
import user from "./user/reducer";
import event from "./event/reducer";
import image from "./image/reducer";
import login from "./login/reducer";
import notice from "./notice/reducer";
import meeting from "./meeting/reducer";
import service from "./service/reducer";
import webinar from "./webinar/reducer";
import payment from "./payment/reducer";
import landing from "./landing/reducer";
import musicGroup from "./music/reducer";
import shopping from "./shopping/reducer";
import feedback from "./feedback/reducer";
import settings from "./settings/reducer";
import industry from "./industry/reducer";
import occupation from "./occupation/reducer";
import partnerUser from "./partnerUser/reducer";
import notification from "./notification/reducer";
import eventSupport from "./event-support/reducer";

export default {
  job,
  faq,
  user,
  login,
  event,
  image,
  notice,
  service,
  webinar,
  payment,
  landing,
  meeting,
  shopping,
  settings,
  industry,
  feedback,
  occupation,
  musicGroup,
  partnerUser,
  eventSupport,
  notification,
};

import {
  postWithHeader,
  deleteWithHeader,
  patchWithHeader,
  getWithHeader,
} from "api/myApi/ApiFunc";
import { WebinarLink } from "api/myApi/link";

export const callFetchWebinarCategoryList = (
  lang,
  params,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithHeader(
    `${WebinarLink(lang)}-category/admin`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          webinarCategoryList: response.data.rows,
          webinarCategoryCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "Error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAddWebinarCategory = (
  lang,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${WebinarLink(lang)}-category`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList.push({
        ...response?.data,
        id: response.data.id,
        webinar_category_translations: [
          { name: response.data.name_en, language: "en" },
          { name: response.data.name_jp, language: "jp" },
        ],
      });
      dispatch(
        reducer({
          loading: false,
          webinarCategoryList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Added",
            type: "success",
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );

      dispatch(
        reducer({
          error: Object.values(payloadMsg)[0],
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};
export const callEditWebinarCategory = (
  lang,
  id,
  idx,
  val,
  dataList,
  dispatch,
  reducer,
  closeLeftDrawer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${WebinarLink(lang)}-category/${id}`,
    val,
    (response) => {
      const tempList = [...dataList];
      tempList[idx] = {
        ...response.data,
        id: id,
        webinar_category_translations: [
          { name: response.data.name_en, language: "en" },
          { name: response.data.name_jp, language: "jp" },
        ],
      };
      dispatch(
        reducer({
          loading: false,
          webinarCategoryList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Edited",
            type: "success",
            timer: 3000,
          },
        })
      );
      closeLeftDrawer();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteWebinarCategory = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  deleteWithHeader(
    `${WebinarLink(lang)}-category/${id}`,
    "",
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          webinarCategoryList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Deleted",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

import React, { useState, useEffect, useContext } from "react";
import "date-fns";
import set from "lodash/set";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import {
  TextField,
  Switch,
  Checkbox,
  Select,
  FormHelperText,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import MuiAutoComplete from "./MuiAutoComplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build-image-upload";
import classNames from "classnames";
import { EventSupportLink } from "api/myApi/link";
import { useSelector } from "react-redux";
import { chooseLang } from "utils/myFunc";
import UploadImage from "components/CustomComponents/UploadImage.js/UploadImage";
import PicturesChooser from "components/CustomComponents/PictureChooser/PictureChooser";

const FormContext = React.createContext(null);

export const MUIForms = ({ onSubmit, initialValues, children, ...rest }) => {
  const [values, setValues] = useState(() => initialValues);
  const [touched, setTouched] = useState({});
  const [error, setError] = useState({});
  const [nameList, setNameList] = useState([]);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setValues(initialValues);
      setError({});
    }
    // else {
    //   setValues({});
    // }
  }, [initialValues]);

  // useEffect(() => {
  //   const data = localStorage.getItem('formData');
  //   if (data) {
  //     setValues(JSON.parse(data));
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('formData', JSON.stringify(values));
  // });

  const handleChange = (name, event) => {
    const target = event?.target;
    const value = target
      ? target.type === "checkbox"
        ? target.checked
        : target.value
      : event;

    const valueObject = { ...values };
    set(valueObject, name, value);
    setValues((prevValue) => ({ ...prevValue, ...valueObject }));

    const touchedObject = { ...touched };
    set(touchedObject, name, true);
    setTouched((prevTouched) => ({ ...prevTouched, ...touchedObject }));
  };

  const handleBlur = (name) => {
    const touchedObject = { ...touched };
    set(touchedObject, name, true);
    setTouched((prevTouched) => ({ ...prevTouched, ...touchedObject }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorStatusLists = [];
    nameList.forEach((element) => {
      if (get(error, element)) {
        const touchedObject = { ...touched };
        set(touchedObject, element, true);
        setTouched((prevTouched) => ({ ...prevTouched, ...touchedObject }));
        errorStatusLists.push(true);
      } else {
        errorStatusLists.push(false);
      }
    });
    // console.log(errorStatusLists);
    if (!errorStatusLists.includes(true)) {
      onSubmit(values);
    }
  };

  return (
    <FormContext.Provider
      value={{
        values,
        touched,
        error,
        nameList,
        setError,
        setTouched,
        setNameList,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
      }}
    >
      <form onSubmit={handleSubmit} {...rest}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export const Field = ({
  name,
  component,
  renderValue,
  validate = [],
  defaultValue,
  ...rest
}) => {
  const {
    setNameList,
    values,
    setValues,
    error,
    setError,
    touched,
    handleBlur,
    handleChange,
  } = useContext(FormContext);

  useEffect(() => {
    setNameList((tempName) => [...tempName, name]);
    return () => {
      setNameList((tempName) => tempName.filter((i) => i !== name));
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (defaultValue) {
      const valueObject = { ...values };
      set(valueObject, name, defaultValue);
      setValues((prevObject) => ({ ...prevObject, ...valueObject }));
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  const fieldError = [];

  validate.forEach((valid) => {
    const tempError = valid(get(values, name));
    // console.log(tempError, 'temperror');
    if (tempError) {
      fieldError.push(tempError);
    }
  });
  useEffect(() => {
    const errorObject = { ...error };
    set(errorObject, name, fieldError[0]);
    setError((prevObject) => ({ ...prevObject, ...errorObject }));
    // eslint-disable-next-line
  }, [get(values, name)]);

  useEffect(() => {
    if (renderValue) {
      renderValue(get(values, name));
    }
    // eslint-disable-next-line
  }, [get(values, name)]);

  return (
    <>
      {component({
        meta: {
          touched: get(touched, name),
          error: get(error, name),
        },
        input: {
          name: name,
          value: get(values, name) || "",
          onBlur: (event) => handleBlur(name, event),
          onChange: (event) => {
            handleChange(name, event);
          },
        },
        ...rest,
      })}
    </>
  );
};

export const FieldArray = ({ name, children }) => {
  const { values, setValues } = useContext(FormContext);
  const push = () => {
    const tempValues = get(values, name) || [{}];
    setValues((prevValues) => ({
      ...prevValues,
      [name]: [...tempValues, {}],
    }));
  };
  const remove = (index) => {
    const tempValues = values[name];
    tempValues.splice(index, 1);
    setValues((prevValues) => ({ ...prevValues, [name]: tempValues }));
  };

  return <>{children({ fields: values[name] || [{}], push, remove })}</>;
};

export const TextFieldMUI = ({ meta: { touched, error }, input, ...rest }) => {
  return (
    <TextField
      {...input}
      {...rest}
      error={touched && Boolean(error)}
      helperText={touched && error && error}
    />
  );
};

TextFieldMUI.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldMUI.defaultProps = {
  meta: null,
};

const imageMuiStyles = makeStyles((theme) => ({
  textAlignStart: {
    textAlign: "start",
  },
  textError: {
    color: theme.palette.error.dark,
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
}));
export const ImageFileMUI = ({
  meta: { touched, error },
  input,
  classes,
  multiple,
  showStar = false,
  star = 0,
  setStar = () => {},
  ...rest
}) => {
  const { value, onChange, name, disabled } = input;

  const innerClasses = imageMuiStyles();

  return (
    <div className={classNames(innerClasses.textAlignStart)}>
      {
        <>
          {!multiple && value?.length === 1 ? (
            " "
          ) : (
            <UploadImage
              multiple={false}
              imageList={(blob, file) =>
                onChange([...value, { blob: blob, file: file }])
              }
              // imageFileList={(val) => setFileImages([...fileImages, ...val])}
              value={value.blob}
              name={name}
            />
          )}
          {value?.length > 0 && (
            <PicturesChooser
              value={value}
              imageList={(val) => onChange([...val])}
              // imageFileList={(val) => onChange([...val])}
              // valueFiles={value}
              edit
              showStar={showStar}
              length={value.length}
              setStarValue={(val) => setStar(val)}
              starValue={star}
              disabled={disabled}
            />
          )}
        </>
      }

      {touched && error && (
        <span className={classNames(innerClasses.textError, innerClasses.mt1)}>
          {error}
        </span>
      )}
    </div>
  );
};

TextFieldMUI.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldMUI.defaultProps = {
  meta: null,
};

/* Select */
export const SelectMUI = ({
  meta: { touched, error },
  input,
  label,
  children,
  ...rest
}) => {
  return (
    <>
      {label && (
        <InputLabel id="attribute_group" error={touched && Boolean(error)}>
          {label}
        </InputLabel>
      )}
      <Select
        {...input}
        {...rest}
        label={label}
        error={touched && Boolean(error)}
      >
        {children}
      </Select>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

SelectMUI.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
/* End */

/* Checkbox */
export const CheckboxMUI = ({ input, ...rest }) => {
  const { value } = input;
  return <Checkbox checked={Boolean(value)} {...input} {...rest} />;
};

CheckboxMUI.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* Switch */
export const SwitchMUI = ({ input, ...rest }) => {
  const { value } = input;
  return <Switch checked={Boolean(value)} {...input} {...rest} />;
};

// SwitchMUI.propTypes = {
//   input: PropTypes.object.isRequired,
// };
/* End */

// export const EditorMUI = ({ input, ...rest }) => {
//   const { value, onChange } = input;

//   return (
//     <CKEditor
//       editor={ClassicEditor}
//       {...input}
//       {...rest}
//       data={value}
//       onChange={(event, editor) => {
//         const data = editor.getData();
//         onChange(data);
//       }}
//     />
//   );
// };

export const MuiAsyncSelect = ({ input, ...rest }) => {
  const { loadOptions, value } = rest;
  const { onChange } = input;

  return (
    <MuiAutoComplete
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      {...input}
      {...rest}
    />
  );
};

export const MuiDatePicker = ({ meta: { touched, error }, input, ...rest }) => {
  const { value } = input;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...input}
        {...rest}
        value={value ? value : null}
        // error={touched && Boolean(error)}
        // helperText={touched && error && error}
      />
    </MuiPickersUtilsProvider>
  );
};

MuiDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

MuiDatePicker.defaultProps = {
  meta: null,
};

export const MuiTimePicker = ({ meta: { touched, error }, input, ...rest }) => {
  const { value } = input;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        {...input}
        {...rest}
        value={value ? value : null}
        error={touched && Boolean(error)}
        helperText={touched && error && error}
      />
    </MuiPickersUtilsProvider>
  );
};

MuiTimePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

MuiTimePicker.defaultProps = {
  meta: null,
};

export const EditorMUI = ({ input, ...rest }) => {
  const { value, onChange } = input;
  const token = useSelector(({ login }) => login?.token?.access_token);
  const lang = useSelector(({ settings }) => settings?.lang);

  return (
    <CKEditor
      editor={ClassicEditor}
      {...input}
      {...rest}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
      onInit={(editor) => {}}
      config={{
        language: "en",
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo",
          ],
        },
        image: {
          resizeOptions: [
            {
              name: "imageResize:original",
              label: "Original",
              value: null,
            },
            {
              name: "imageResize:50",
              label: "50%",
              value: "50",
            },
            {
              name: "imageResize:75",
              label: "75%",
              value: "75",
            },
          ],
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "imageResize",
            "|",
            "imageTextAlternative",
          ],
          styles: ["alignLeft", "alignCenter", "alignRight"],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        simpleUpload: {
          uploadUrl: `${EventSupportLink(chooseLang(lang))}/upload-image`,

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            // 'X-CSRF-TOKEN': 'CSFR-Token',
            Authorization: token,
          },
        },
      }}
    />
  );
};

const initialState = {
  loading: null,
  error: null,
  success: null,
  partnerUserReqList: [],
  partnerUserReqCount: 0,
  partnerUserHisList: [],
  partnerUserHisCount: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "PARTNER_USER_ACTION":
      return { ...state, ...payload };
    default:
      return state;
  }
};

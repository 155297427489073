import { useRouteMatch } from "react-router";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ImprovementFeedbackTable from "./ImprovementFeedbackTable";

import { chooseLang } from "utils/myFunc";
import useDebounce from "utils/useDebounce";
import { feedbackAction } from "redux/feedback/action";
import {
  callDeleteFeedback,
  callFetchFeedback,
  callPatchFeedback,
} from "redux/feedback/apiCall";
import { notificationAction } from "redux/notification/action";

const ImprovementFeedbackList = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const loading = useSelector(({ feedback }) => feedback.loading);
  const list = useSelector(({ feedback }) => feedback.feedbackList);
  const count = useSelector(({ feedback }) => feedback.feedbackCount);

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  const debounce = useDebounce(searchTerm, 300);

  useEffect(() => {
    callFetchFeedback(
      chooseLang(lang),
      {
        limit: perPage,
        offset: page * perPage,
        search_query: debounce,
        type: match.params.id === "improvements" ? "feedback" : "bug",
      },
      dispatch,
      feedbackAction
    );
    // eslint-disable-next-line
  }, [page, perPage, debounce, match.params.id]);

  const handleChangeStatus = (id, idx, status) => {
    callPatchFeedback(
      chooseLang(lang),
      id,
      idx,
      status,
      list,
      dispatch,
      feedbackAction,
      notificationAction
    );
  };
  const handleDeleteData = (id, idx) => {
    callDeleteFeedback(
      chooseLang(lang),
      id,
      idx,
      list,
      dispatch,
      feedbackAction,
      notificationAction
    );
  };

  return (
    <ImprovementFeedbackTable
      data={list}
      type={type}
      page={page}
      count={count}
      loading={loading}
      setType={setType}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      searchTerm={searchTerm}
      title={match.params.id}
      setSearchTerm={setSearchTerm}
      changeStatus={handleChangeStatus}
      deleteSingleData={handleDeleteData}
    />
  );
};

export default ImprovementFeedbackList;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";
import CommonTable from "components/Tables/CommonTable";
import {
  callAddServiceCategory,
  callDeleteServiceCategory,
  callEditServiceCategory,
  callFetchServiceCategory,
} from "redux/service/apiCall";
import { serviceAction } from "redux/service/action";

const ServiceCategoryList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const serviceCategoryList = useSelector(
    ({ service }) => service.serviceCategoryList
  );
  const loading = useSelector(({ service }) => service.loading);

  const serviceCategoryCount = useSelector(
    ({ service }) => service.serviceCategoryCount
  );

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchServiceCategory(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      serviceAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleSubmit = (val) => {
    const { idIdx, ...rest } = val;
    if (type === "edit") {
      callEditServiceCategory(
        chooseLang(lang),
        idIdx.id,
        idIdx.idx,
        rest,
        serviceCategoryList,
        dispatch,
        serviceAction,
        () => setOpen(false),
        notificationAction
      );
    } else {
      callAddServiceCategory(
        chooseLang(lang),
        rest,
        serviceCategoryList,
        dispatch,
        serviceAction,
        () => setOpen(false),
        notificationAction
      );
    }
  };

  return (
    <>
      <CommonTable
        data={serviceCategoryList}
        handleSubmit={handleSubmit}
        type={type}
        setType={setType}
        title={"Service Category"}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        industryCount={serviceCategoryCount}
        setSearchTerm={setSearchTerm}
        loading={loading}
        deleteSingleData={(id, idx) => {
          callDeleteServiceCategory(
            chooseLang(lang),
            id,
            idx,
            serviceCategoryList,
            dispatch,
            serviceAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default ServiceCategoryList;

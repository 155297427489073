import Cookies from "js-cookie";
export const setItem = (name, val) => localStorage.setItem(name, val);

export const getItem = (name) => localStorage.getItem(name);

export const removeItem = (name) => localStorage.removeItem(name);

export const setCookieItem = (name, val) => Cookies.set(name, val);

export const getCookieItem = (name) => Cookies.get(name);

export const removeCookieItem = (name) => Cookies.remove(name);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useRouteMatch } from "react-router";
import { Controller, useForm, useFieldArray } from "react-hook-form";

import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { chooseLang } from "utils/myFunc";
import { faqAction } from "redux/faq/action";
import { CkEditor } from "components/Forms/CkEditor";
import { UploadFile } from "components/Forms/UploadFile";
import { notificationAction } from "redux/notification/action";
import {
  callAddFaq,
  callEditFaq,
  callFetchSingleFaq,
  callDeleteFaqDetail,
} from "redux/faq/apiCall";

const faq_detail = {
  description_en: "",
  image_en: "",
  description_jp: "",
  image_jp: "",
};
const defaultValues = {
  title_en: "",
  title_jp: "",
  faq_detail: [faq_detail],
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "60vh",
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
  },
  formDiv: {
    maxWidth: "700px",
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sectionTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& span": {
      cursor: "pointer",
    },
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  divider: {
    height: 2,
    marginTop: "16px",
    marginBottom: "16px",
  },
}));

const getDeletedItemsFromArrays = (arr1, arr2) => {
  let temp = [...arr1],
    arr1Len = temp?.length / 2, // en and jp are added as separate items
    arr2Len = arr2?.length; // en and jp are inside the same item
  if (arr1Len > arr2Len) {
    return temp.splice(arr2Len * 2, arr1Len * 2 - arr2Len * 2);
  } else return [];
};
const FAQForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const lang = useSelector(({ settings }) => settings.lang);

  const [showForm, setShowForm] = useState(false);
  const [initialValue, setInitialValue] = useState(defaultValues);

  useEffect(() => {
    if (match.params.id) {
      callFetchSingleFaq(
        chooseLang(lang),
        {
          id: match.params.id,
        },
        dispatch,
        faqAction,
        notificationAction,
        (val) => {
          let temp = {
            title_en:
              val?.faq_titles?.[0]?.language === "en"
                ? val?.faq_titles?.[0]?.title
                : val?.faq_titles?.[1]?.title,
            title_jp:
              val?.faq_titles?.[0]?.language === "en"
                ? val?.faq_titles?.[1]?.title
                : val?.faq_titles?.[0]?.title,
            faq_detail: [],
          };
          for (let i = 0; i < val?.faq_details?.length; i += 2) {
            let img_en =
              val?.faq_details?.[i]?.image || val?.faq_details?.[i + 1]?.image;
            let img_jp =
              val?.faq_details?.[i + 1]?.image || val?.faq_details?.[i]?.image;
            temp.faq_detail.push({
              description_en: val?.faq_details?.[i]?.description, //0
              description_jp: val?.faq_details?.[i + 1]?.description,
              image_en: img_en ? [{ preview: img_en }] : "",
              image_jp: img_jp ? [{ preview: img_jp }] : "",
              // faq_detail_id : val?.faq_details?.[i]?.faq_id
            });
          }
          setInitialValue(temp);
          setShowForm(true);
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <Typography variant="h5" gutterBottom>
        <b>
          {match.params.id ? (
            <FormattedMessage id="modifyFAQs" defaultMessage="Modify FAQs" />
          ) : (
            <FormattedMessage
              id="createNewFAQs"
              defaultMessage="Create New FAQs"
            />
          )}
        </b>
      </Typography>
      <br />

      {match.params.id && !showForm ? (
        <Box textAlign="center" margin="100px 0">
          <CircularProgress size={100} thickness={2} />
        </Box>
      ) : (
        <FaqFormComp
          initialValues={initialValue}
          type={match.params.id ? "edit" : "add"}
        />
      )}
    </div>
  );
};

export default FAQForm;

const FaqFormComp = ({ initialValues, type }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
  });

  const loading = useSelector(({ faq }) => faq.loading);
  const faqList = useSelector(({ faq }) => faq.faqList);
  const lang = useSelector(({ settings }) => settings.lang);
  const singleFaq = useSelector(({ faq }) => faq.faqDetail);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "faq_detail",
  });

  const callAddNewSection = () => {
    append(faq_detail);
  };

  const callDelSection = (j) => () => {
    remove(j);
  };
  const onSubmit = (val) => {
    if (type === "edit") {
      callEditFaqOnSubmit(val);
    } else {
      callAddFaqOnSubmit(val);
    }
  };

  const callAddFaqOnSubmit = (val) => {
    let images = [],
      faqDetail = val.faq_detail.map((i, j) => {
        let temp = { ...i, order: j + 1 };
        if (i?.image_en?.length > 0 || i?.image_jp?.length > 0) {
          images.push(
            i?.image_en?.[0] || i?.image_jp?.[0], //first image with eng content
            i?.image_jp?.[0] || i?.image_en?.[0] // second image with jp content
          ); //we need to push two image en and jp but image field is optional
          temp.has_image = true;
        }
        temp.image_en && delete temp.image_en;
        temp.image_jp && delete temp.image_jp;
        return temp;
      }),
      formData = new FormData();

    formData.append("title_en", val.title_en);
    formData.append("title_jp", val.title_jp);
    formData.append("faq_detail", JSON.stringify(faqDetail));
    for (let i = 0; i <= images.length; i++)
      formData.append("images", images[i]);

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    callAddFaq(
      chooseLang(lang),
      formData,
      faqList,
      dispatch,
      faqAction,
      () => history.push("/app/faq"),
      notificationAction
    );
  };

  const callEditFaqOnSubmit = (val) => {
    let temp = {},
      formData = new FormData(),
      images = [],
      j = 0,
      deletedData = getDeletedItemsFromArrays(
        singleFaq?.faq_details,
        val.faq_detail
      );

    temp.title_en =
      singleFaq?.faq_titles?.[0]?.title === val?.title_en
        ? undefined
        : val?.title_en;
    temp.title_jp =
      singleFaq?.faq_titles?.[1]?.title === val?.title_jp
        ? undefined
        : val?.title_jp;

    temp.faq_detail = [];

    for (let i = 0; i < val.faq_detail.length; i++) {
      let item = val.faq_detail?.[i],
        enDesc = item?.description_en,
        jpDesc = item?.description_jp,
        enImg = item?.image_en,
        jpImg = item?.image_jp;
      if (singleFaq?.faq_details[j]?.id)
        temp.faq_detail.push({
          order: i + 1,
          faq_detail_id: singleFaq?.faq_details[j]?.id,
          has_image: enImg?.[0]?.path ? true : false,
          description_en: enDesc,
        });
      if (singleFaq?.faq_details[j + 1]?.id)
        temp.faq_detail.push({
          order: i + 1,
          faq_detail_id: singleFaq?.faq_details[j + 1]?.id,
          has_image: jpImg?.[0]?.path ? true : false,
          description_jp: jpDesc,
        });
      if (!singleFaq?.faq_details[j]?.id && enDesc)
        temp.faq_detail.push({
          order: i + 1,
          has_image: jpImg?.[0]?.path ? true : false,
          description_en: enDesc,
          description_jp: jpDesc,
        });
      j = j + 2;
      if (enImg?.length > 0 && enImg?.[0]?.path) images.push(enImg?.[0]);
      if (jpImg?.length > 0 && jpImg?.[0]?.path) images.push(jpImg?.[0]);
    }

    temp.title_en && formData.append("title_en", temp.title_en);
    temp.title_jp && formData.append("title_jp", temp.title_jp);
    formData.append("faq_detail", JSON.stringify(temp?.faq_detail));
    for (let i = 0; i < images.length; i++)
      formData.append("images", images[i]);

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    // return;
    callEditFaq(
      chooseLang(lang),
      match.params.id,
      formData,
      faqList,
      dispatch,
      faqAction,
      () => history.push("/app/faq"),
      notificationAction
    );

    deletedData?.length > 0 &&
      callDeleteFaqDetail(
        chooseLang(lang),
        {
          faq_detail_ids: deletedData?.map((i) => i.id),
          faq_id: singleFaq?.id,
        },
        dispatch,
        faqAction,
        notificationAction
      );
  };
  return (
    <form className={classes.formDiv} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5">
        <FormattedMessage id="title" defaultMessage="Title" />
      </Typography>
      <br />
      <Typography>
        <FormattedMessage
          id="titleInEnglish"
          defaultMessage="Title in English"
        />
      </Typography>
      <Controller
        name="title_en"
        defaultValue=""
        control={control}
        rules={{
          required: intl.formatMessage({
            id: "titleInEnglishRequired",
            defaultMessage: "Title in English is required.",
          }),
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            className={classes.formControl}
            error={Boolean(errors?.title_en)}
            helperText={errors?.title_en?.message}
            label={intl.formatMessage({
              id: "titleInEnglish",
              defaultMessage: "Title in English",
            })}
          />
        )}
      />
      <Typography>
        <FormattedMessage
          id="titleInJapanese"
          defaultMessage="Title in Japanese"
        />
      </Typography>
      <Controller
        name="title_jp"
        defaultValue=""
        control={control}
        rules={{
          required: intl.formatMessage({
            id: "titleInJapaneseRequired",
            defaultMessage: "Title in Japanese is required.",
          }),
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            className={classes.formControl}
            error={Boolean(errors?.title_jp)}
            helperText={errors?.title_jp?.message}
            label={intl.formatMessage({
              id: "titleInJapanese",
              defaultMessage: "Title in Japanese",
            })}
          />
        )}
      />
      <Divider className={classes.divider} />
      <Typography variant="h5">
        <FormattedMessage id="description" defaultMessage="Description" />
      </Typography>{" "}
      <br />
      {fields.map((field, index) => (
        <Box key={field.id}>
          <Typography gutterBottom className={classes.sectionTitle}>
            <FormattedMessage
              id={`section${index + 1}`}
              defaultMessage={`Section-${index + 1}`}
            />
            {fields?.length > 1 && (
              <Typography
                component="span"
                color="primary"
                variant="h6"
                onClick={callDelSection(index)}
                title={intl.formatMessage({
                  id: "delete",
                  defaultMessage: "Delete",
                })}
              >
                X
              </Typography>
            )}
          </Typography>
          <Typography>
            <FormattedMessage
              id="contentInEnglish"
              defaultMessage="Content in English"
            />
          </Typography>
          <Controller
            name={`faq_detail[${index}].description_en`}
            defaultValue={field?.description_en || ""}
            control={control}
            rules={{
              required: intl.formatMessage({
                id: "contentInEnglishRequired",
                defaultMessage: "Content in English is required.",
              }),
            }}
            render={({ field: { onChange, value, ...rest } }) => (
              <CkEditor
                value={value}
                height="10rem"
                onChange={onChange}
                error={errors?.faq_detail?.[index]?.description_en?.message}
                config={{
                  placeholder: "Enter your message",
                }}
                {...rest}
              />
            )}
          />
          <br />
          <Typography>
            <FormattedMessage
              id="sectionImageEn"
              defaultMessage="Section Image English"
            />
          </Typography>
          <Controller
            control={control}
            defaultValue={field?.image_en || ""}
            name={`faq_detail[${index}].image_en`}
            render={({ field: { onChange, value, ...rest } }) => (
              <UploadFile
                value={value}
                onChange={onChange}
                error={errors?.faq_detail?.[index]?.image_en?.message}
                {...rest}
              />
            )}
          />
          <br />
          <Typography>
            <FormattedMessage
              id="contentInJapanese"
              defaultMessage="Content in Japanese"
            />
          </Typography>
          <Controller
            name={`faq_detail[${index}].description_jp`}
            defaultValue={field?.description_jp || ""}
            control={control}
            rules={{
              required: intl.formatMessage({
                id: "contentInJapaneseRequired",
                defaultMessage: "Content in Japanese is required.",
              }),
            }}
            render={({ field: { onChange, value, ...rest } }) => (
              <CkEditor
                value={value}
                height="10rem"
                onChange={onChange}
                error={errors?.faq_detail?.[index]?.description_jp?.message}
                config={{
                  placeholder: "Enter your message",
                }}
                {...rest}
              />
            )}
          />
          <br />
          <Typography>
            <FormattedMessage
              id="sectionImageJp"
              defaultMessage="Section Image Japanese"
            />
          </Typography>
          <Controller
            control={control}
            defaultValue={field?.image_jp || ""}
            name={`faq_detail[${index}].image_jp`}
            render={({ field: { onChange, value, ...rest } }) => (
              <UploadFile
                value={value}
                onChange={onChange}
                error={errors?.faq_detail?.[index]?.image_jp?.message}
                {...rest}
              />
            )}
          />
          <Divider className={classes.divider} />
        </Box>
      ))}
      <br />
      <Button
        startIcon={<AddIcon />}
        color="primary"
        onClick={callAddNewSection}
      >
        <FormattedMessage id="addSection" defaultMessage="Add new section" />
      </Button>
      <br />
      <br />
      <Button
        fullWidth
        size="large"
        type="submit"
        color="primary"
        variant="contained"
        disabled={loading}
      >
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {intl.formatMessage({ id: "submit", defaultMessage: "Submit" })}
      </Button>
    </form>
  );
};

import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";

import {
  callAddJobCategory,
  callDeleteJobCategory,
  callEditJobCategory,
  callFetchJobCategory,
} from "redux/job/apiCall";
import { jobAction } from "redux/job/action";
import CommonTable from "components/Tables/CommonTable";

const JobCategoryList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const jobCategoryList = useSelector(({ job }) => job.jobCategoryList);
  const loading = useSelector(({ job }) => job.loading);

  const jobCategoryCount = useSelector(({ job }) => job.jobCategoryCount);

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchJobCategory(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      jobAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleSubmit = (val) => {
    const { idIdx, ...rest } = val;
    if (type === "edit") {
      callEditJobCategory(
        chooseLang(lang),
        idIdx.id,
        idIdx.idx,
        rest,
        jobCategoryList,
        dispatch,
        jobAction,
        () => setOpen(false),
        notificationAction
      );
    } else {
      callAddJobCategory(
        chooseLang(lang),
        rest,
        jobCategoryList,
        dispatch,
        jobAction,
        () => setOpen(false),
        notificationAction
      );
    }
  };

  return (
    <>
      <CommonTable
        data={jobCategoryList}
        handleSubmit={handleSubmit}
        type={type}
        setType={setType}
        title={"Job Category"}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        industryCount={jobCategoryCount}
        setSearchTerm={setSearchTerm}
        loading={loading}
        deleteSingleData={(id, idx) => {
          callDeleteJobCategory(
            chooseLang(lang),
            id,
            idx,
            jobCategoryList,
            dispatch,
            jobAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default JobCategoryList;

const initialState = {
  loading: null,
  error: null,
  success: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'OCCUPATION_ACTION':
      return { ...state, ...payload };
    default:
      return state;
  }
};

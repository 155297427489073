import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";
import { callDeleteMusicGroup } from "redux/music/apiCall";
import NoticeTable from "./NoticeTable";
import {
  callAddNotice,
  callEditNotice,
  callFetchNoticeList,
} from "redux/notice/apiCall";
import { noticeAction } from "redux/notice/action";

const NoticeList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const noticeList = useSelector(({ notice }) => notice.noticeList);
  const noticeCount = useSelector(({ notice }) => notice.noticeCount);

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchNoticeList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      noticeAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleSubmit = (val) => {
    const { idIdx, ...rest } = val;
    if (type === "edit") {
      callEditNotice(
        chooseLang(lang),
        idIdx.id,
        idIdx.idx,
        rest,
        noticeList,
        dispatch,
        noticeAction,
        () => setOpen(false),
        notificationAction
      );
    } else {
      callAddNotice(
        chooseLang(lang),
        rest,
        noticeList,
        dispatch,
        noticeAction,
        () => setOpen(false),
        notificationAction
      );
    }
  };

  return (
    <>
      <NoticeTable
        data={noticeList}
        handleSubmit={handleSubmit}
        type={type}
        setType={setType}
        title={"Notice"}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        industryCount={noticeCount}
        setSearchTerm={setSearchTerm}
        deleteSingleData={(id, idx) => {
          callDeleteMusicGroup(
            chooseLang(lang),
            id,
            idx,
            noticeList,
            dispatch,
            noticeAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default NoticeList;

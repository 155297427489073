import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  Home,
  NotFound,
  EventCategory,
  LandingCategory,
  ShoppingCategory,
} from "../pageListAsync";
import FAQList from "containers/FAQ/FAQList";
import FAQForm from "containers/FAQ/FAQForm";
import Dashboard from "../Templates/Dashboard";
import AddImage from "containers/image/AddImage";
import UserList from "containers/Users/UserList";
import ImageList from "containers/image/ImageList";
import UserDetail from "containers/Users/UserDetail";
import NoticeList from "containers/notice/NoticeList";
import MeetingList from "containers/Meeting/MeetingList";
import IndustryList from "containers/Industry/IndustryList";
import UserReports from "containers/UserFeedback/UserReports";
import OccupationList from "containers/Occupation/OccupationList";
import PartnerUserReq from "containers/PartnerUser/PartnerUserReq";
import ChangePassword from "containers/ChangePassword/ChangePassword";
import AddEventSupport from "containers/EventSupport/AddEventSupport";
import JobCategoryList from "containers/job-category/JobCategoryList";
import PaymentWithdrawReq from "containers/Payment/PaymentWithdrawReq";
import PaymentWithdrawHis from "containers/Payment/PaymentWithdrawHis";
import EventSupportList from "containers/EventSupport/EventSupportList";
import WebinarCategoryList from "containers/WebinarCategory/WebinarCategoryList";
import ServiceCategoryList from "containers/service-category/ServiceCategoryList";
import ImprovementFeedbackList from "containers/UserFeedback/ImprovementFeedback";
import EventSupportGroupList from "containers/EventSupport/EventSupportGroup/EventSupportGroupList";

const Application = () => {
  return (
    <Dashboard>
      <Switch>
        {/* Home */}
        <Route exact path="/app" component={Home} />
        <Route exact path="/app/user" component={UserList} />
        <Route exact path="/app/user/:id" component={UserDetail} />

        <Route exact path="/app/industry" component={IndustryList} />
        <Route exact path="/app/event-category" component={EventCategory} />
        <Route exact path="/app/landing-category" component={LandingCategory} />
        <Route
          exact
          path="/app/shopping-category"
          component={ShoppingCategory}
        />
        <Route
          exact
          path="/app/webinar-category"
          component={WebinarCategoryList}
        />

        <Route exact path="/app/meeting" component={MeetingList} />
        <Route exact path="/app/occupation" component={OccupationList} />
        <Route exact path="/app/event-support" component={EventSupportList} />
        <Route
          exact
          path={["/app/event-support/add", "/app/event-support/edit"]}
          component={AddEventSupport}
        />

        <Route
          exact
          path="/app/event-support/group"
          component={EventSupportGroupList}
        />
        <Route exact path="/app/notice" component={NoticeList} />
        <Route exact path="/app/job-category" component={JobCategoryList} />
        <Route
          exact
          path="/app/service-category"
          component={ServiceCategoryList}
        />

        <Route exact path="/app/banner-images" component={ImageList} />
        <Route
          exact
          path={["/app/banner-images/add", "/app/banner-images/edit"]}
          component={AddImage}
        />

        <Route exact path="/app/change-password" component={ChangePassword} />

        <Route
          exact
          path="/app/payments-withdraw-history"
          component={PaymentWithdrawHis}
        />
        <Route
          exact
          path="/app/payments-withdraw-requests"
          component={PaymentWithdrawReq}
        />
        <Route
          exact
          path="/app/user-feedback/improvements"
          component={ImprovementFeedbackList}
        />
        <Route
          exact
          path="/app/user-feedback/bugs"
          component={ImprovementFeedbackList}
        />
        <Route exact path="/app/user-feedback/posts" component={UserReports} />

        <Route exact path="/app/faq" component={FAQList} />
        <Route
          exact
          path={["/app/faq-form", "/app/faq-form/:id"]}
          component={FAQForm}
        />

        <Route exact path="/app/partner-users" component={PartnerUserReq} />

        {/* <Route path="/app/dashboard" component={DashboardPage} />
        <Route path="/app/form" component={Form} />
        <Route path="/app/table" component={Table} />
        <Route path="/app/page-list" component={Parent} />
        <Route path="/app/pages/not-found" component={NotFound} />
        <Route path="/app/pages/error" component={Error} />
        <Route path="/app/property" component={PropertyRoute} />
        <Route path="/app/location" component={LocationRoute} /> */}

        <Route component={NotFound} />
      </Switch>
    </Dashboard>
  );
};

export default Application;

import Loadable from "react-loadable";
import Loading from "../components/fragments/Loading";

export const DashboardPage = Loadable({
  loader: () => import("./Pages/Dashboard"),
  loading: Loading,
});

export const Table = Loadable({
  loader: () => import("./Pages/Table/BasicTable"),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import("./Pages/Users/Login"),
  loading: Loading,
});

export const ComingSoon = Loadable({
  loader: () => import("./Pages/ComingSoon"),
  loading: Loading,
});
export const BlankPage = Loadable({
  loader: () => import("./Pages/BlankPage"),
  loading: Loading,
});
export const NotFound = Loadable({
  loader: () => import("./NotFound/NotFound"),
  loading: Loading,
});
export const Error = Loadable({
  loader: () => import("./Pages/Error"),
  loading: Loading,
});
export const Maintenance = Loadable({
  loader: () => import("./Pages/Maintenance"),
  loading: Loading,
});
export const Parent = Loadable({
  loader: () => import("./Parent"),
  loading: Loading,
});
export const Form = Loadable({
  loader: () => import("./Pages/Forms/DemoForm"),
  loading: Loading,
});
export const NotFoundDedicated = Loadable({
  loader: () => import("./Pages/Standalone/NotFoundDedicated"),
  loading: Loading,
});

export const Home = Loadable({
  loader: () => import("./Home"),
  loading: Loading,
});

export const EventCategory = Loadable({
  loader: () => import("./EventCategory/EventCategoryList"),
  loading: Loading,
});

export const LandingCategory = Loadable({
  loader: () => import("./LandingCategory/LandingCategoryList"),
  loading: Loading,
});

export const ShoppingCategory = Loadable({
  loader: () => import("./ShoppingCategory/ShoppingCategoryList"),
  loading: Loading,
});

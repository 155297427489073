export const darkPalette = {
  palette: {
    primary: {
      light: '#e8eaf6',
      main: '#5c6bc0',
      dark: '#3f51b5',
      contrastText: '#fff',
    },
    secondary: {
      light: '#82b1ff',
      main: '#448aff',
      dark: '#0d47a1',
      contrastText: '#fff',
    },
  },
};

export const lightPalette = {
  palette: {
    primary: {
      light: '#e8eaf6',
      main: '#3f51b5',
      dark: '#283593',
      contrastText: '#fff',
    },
    secondary: {
      light: '#8c9eff',
      main: '#304ffe',
      dark: '#1a237e',
      contrastText: '#fff',
    },
  },
};

//dark theme color
// redTheme: {
//   palette: {
//     primary: {
//       light: '#fce4ec',
//       main: '#e91e63',
//       dark: '#ad1457',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff80ab',
//       main: '#ff4081',
//       dark: '#880e4f',
//       contrastText: '#fff',
//     },
//   },
// },
// greenTheme: {
//   palette: {
//     primary: {
//       light: '#e0f2f1',
//       main: '#009688',
//       dark: '#00695c',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#a7ffeb',
//       main: '#00bfa5',
//       dark: '#004d40',
//       contrastText: '#fff',
//     },
//   },
// },
// blueTheme: {
//   palette: {
//     primary: {
//       light: '#e8eaf6',
//       main: '#5c6bc0',
//       dark: '#3f51b5',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#82b1ff',
//       main: '#448aff',
//       dark: '#0d47a1',
//       contrastText: '#fff',
//     },
//   },
// },
// purpleTheme: {
//   palette: {
//     primary: {
//       light: '#f3e5f5',
//       main: '#ab47bc',
//       dark: '#6a1b9a',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ea80fc',
//       main: '#e040fb',
//       dark: '#4a148c',
//       contrastText: '#fff',
//     },
//   },
// },
// orangeTheme: {
//   palette: {
//     primary: {
//       light: '#fff3e0',
//       main: '#ef6c00',
//       dark: '#e65100',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ffd180',
//       main: '#ff9100',
//       dark: '#e65100',
//       contrastText: '#fff',
//     },
//   },
// },
// greyTheme: {
//   palette: {
//     primary: {
//       light: '#eceff1',
//       main: '#607d8b',
//       dark: '#37474f',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#e0e0e0',
//       main: '#757575',
//       dark: '#263238',
//       contrastText: '#fff',
//     },
//   },
// },
// lightGreenTheme: {
//   palette: {
//     primary: {
//       light: '#dcedc8',
//       main: '#9ccc65',
//       dark: '#558b2f',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#cddc39',
//       main: '#4caf50',
//       dark: '#2e7d32',
//       contrastText: '#fff',
//     },
//   },
// },
// lightBlueTheme: {
//   palette: {
//     primary: {
//       light: '#b3e5fc',
//       main: '#03a9f4',
//       dark: '#0277bd',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#e0f7fa',
//       main: '#00bcd4',
//       dark: '#00838f',
//       contrastText: '#fff',
//     },
//   },
// },
// brownTheme: {
//   palette: {
//     primary: {
//       light: '#d7ccc8',
//       main: '#a1887f',
//       dark: '#4e342e',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ccb0b0',
//       main: '#a06868',
//       dark: '#3e2723',
//       contrastText: '#fff',
//     },
//   },
// },

//light theme color

// redTheme: {
//   palette: {
//     primary: {
//       light: '#fce4ec',
//       main: '#e91e63',
//       dark: '#ad1457',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff80ab',
//       main: '#ff4081',
//       dark: '#880e4f',
//       contrastText: '#fff',
//     },
//   },
// },
// greenTheme: {
//   palette: {
//     primary: {
//       light: '#e0f2f1',
//       main: '#009688',
//       dark: '#00695c',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#64ffda',
//       main: '#008573',
//       dark: '#004d40',
//       contrastText: '#fff',
//     },
//   },
// },
// blueTheme: {
//   palette: {
//     primary: {
//       light: '#e8eaf6',
//       main: '#3f51b5',
//       dark: '#283593',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#8c9eff',
//       main: '#304ffe',
//       dark: '#1a237e',
//       contrastText: '#fff',
//     },
//   },
// },
// purpleTheme: {
//   palette: {
//     primary: {
//       light: '#f3e5f5',
//       main: '#9c27b0',
//       dark: '#6a1b9a',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ea80fc',
//       main: '#aa00ff',
//       dark: '#4a148c',
//       contrastText: '#fff',
//     },
//   },
// },
// orangeTheme: {
//   palette: {
//     primary: {
//       light: '#fff3e0',
//       main: '#ef6c00',
//       dark: '#e65100',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff9e80',
//       main: '#ff3d00',
//       dark: '#bf360c',
//       contrastText: '#fff',
//     },
//   },
// },
// greyTheme: {
//   palette: {
//     primary: {
//       light: '#cfd8dc',
//       main: '#607d8b',
//       dark: '#37474f',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#bdbdbd',
//       main: '#424242',
//       dark: '#212121',
//       contrastText: '#fff',
//     },
//   },
// },
// lightGreenTheme: {
//   palette: {
//     primary: {
//       light: '#dcedc8',
//       main: '#9ccc65',
//       dark: '#558b2f',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#cddc39',
//       main: '#4caf50',
//       dark: '#2e7d32',
//       contrastText: '#fff',
//     },
//   },
// },
// lightBlueTheme: {
//   palette: {
//     primary: {
//       light: '#b3e5fc',
//       main: '#03a9f4',
//       dark: '#0277bd',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#80deea',
//       main: '#00bcd4',
//       dark: '#00838f',
//       contrastText: '#fff',
//     },
//   },
// },
// brownTheme: {
//   palette: {
//     primary: {
//       light: '#d7ccc8',
//       main: '#795548',
//       dark: '#4e342e',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ccb0b0',
//       main: '#794848',
//       dark: '#3e2723',
//       contrastText: '#fff',
//     },
//   },
// },

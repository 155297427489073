import React from "react";
import Toggle from "./Toggle";
import messages from "./messages";
import { appLocales } from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { settingsAction } from "redux/settings/action";

const LocaleToggle = () => {
  const dispatch = useDispatch();
  // const { locale } = useContext(AppContext);

  const locale = useSelector(({ settings }) => settings.lang);

  return (
    <Toggle
      value={locale}
      values={appLocales}
      messages={messages}
      // onToggle={(e) => setLocale(e.target.value)}
      onToggle={(e) => dispatch(settingsAction({ lang: e.target.value }))}
    />
  );
};

export default LocaleToggle;

module.exports = [
  {
    key: "dashboard",
    name: "Dashboard",
    icon: "bar_chart",
    link: "/app",
    child: [],
  },
  {
    key: "users",
    name: "Users",
    icon: "people",
    link: "/app/user",
    child: [],
  },
  {
    key: "industry",
    name: "Industry",
    icon: "domain",
    link: "/app/industry",
    child: [],
  },
  {
    key: "occupation",
    name: "Occupation",
    icon: "work_outline",
    link: "/app/occupation",
    child: [],
  },
  {
    key: "meetings",
    name: "Meetings",
    icon: "meeting_room",
    link: "/app/meeting",
    child: [],
  },
  {
    key: "job_category",
    name: "Job Category",
    icon: "work",
    link: "/app/job-category",
    child: [],
  },
  {
    key: "event_category",
    name: "Event Category",
    icon: "event",
    link: "/app/event-category",
    child: [],
  },
  {
    key: "service_category",
    name: "Service Category",
    icon: "apps",
    link: "/app/service-category",
    child: [],
  },
  {
    key: "webinar_category",
    name: "Webinar Category",
    icon: "event",
    link: "/app/webinar-category",
    child: [],
  },
  {
    key: "landing_category",
    name: "Landing Category",
    icon: "ballot",
    link: "/app/landing-category",
    child: [],
  },
  {
    key: "shopping_category",
    name: "Shopping Category",
    icon: "ballot",
    link: "/app/shopping-category",
    child: [],
  },
  {
    key: "event_support",
    name: "Event Support",
    icon: "add_to_queue",
    child: [
      {
        key: "event_support",
        name: "Event Support",
        icon: "event",
        link: "/app/event-support",
      },
      {
        key: "event_support_group",
        name: "Event Support Group",
        icon: "event",
        link: "/app/event-support/group",
      },
    ],
  },
  {
    key: "payments",
    name: "Payments",
    icon: "payments",
    child: [
      {
        key: "withdraw_requests",
        name: "Withdraw requests",
        icon: "payments",
        link: "/app/payments-withdraw-requests",
      },
      {
        key: "payments_group",
        name: "Withdraw history",
        icon: "payments",
        link: "/app/payments-withdraw-history",
      },
    ],
  },
  {
    key: "user_feedback",
    name: "User Feedback",
    icon: "feedback",
    child: [
      {
        key: "improvement_feedback",
        name: "Improvement Feedback",
        icon: "feedback",
        link: "/app/user-feedback/improvements",
      },
      {
        key: "reported_bugs",
        name: "Reported Bugs",
        icon: "feedback",
        link: "/app/user-feedback/bugs",
      },
      {
        key: "reported_posts",
        name: "Reported Posts",
        icon: "feedback",
        link: "/app/user-feedback/posts",
      },
    ],
  },
  {
    key: "partner_users",
    name: "partner_users",
    icon: "admin_panel_settings",
    link: "/app/partner-users",
    child: [],
  },
  {
    key: "faq",
    name: "FAQ",
    icon: "help",
    link: "/app/faq",
    child: [],
  },
  {
    key: "notice",
    name: "Notice",
    icon: "ballot",
    link: "/app/notice",
    child: [],
  },
  {
    key: "banner_image",
    name: "Banner Image",
    icon: "dns",
    link: "/app/banner-images",
    child: [],
  },
  {
    key: "change_password",
    name: "Change Password",
    icon: "lock_open",
    link: "/app/change-password",
    child: [],
  },
];

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MeetingTable from "./MeetingTable";
import { callFetchMeetingList } from "redux/meeting/apiCall";
import { meetingAction } from "redux/meeting/action";
import { chooseLang } from "utils/myFunc";

const MeetingList = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const meetingList = useSelector(({ meeting }) => meeting.meetingList);
  const meetingCount = useSelector(({ meeting }) => meeting.meetingCount);

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchMeetingList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      meetingAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  return (
    <MeetingTable
      data={meetingList}
      type={type}
      setType={setType}
      title={"Meeting"}
      page={page}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      searchTerm={searchTerm}
      meetingCount={meetingCount}
      setSearchTerm={setSearchTerm}
    />
  );
};

export default MeetingList;

import { useState } from "react";
import styled from "styled-components";
import { Popover } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const StatusPopover = ({
  trigger = "trigger",
  content = "content",
  anchorOrigin = { vertical: "bottom", horizontal: "center" },
  transformOrigin = { vertical: "top", horizontal: "center" },
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "status-popover" : undefined;

  return (
    <>
      <div aria-describedby={id} onClick={handleClick}>
        {trigger}
      </div>
      <Popover
        id={id}
        open={open}
        elevation={0}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <div onClick={handleClose}>{content}</div>
      </Popover>
    </>
  );
};

const StatusColorDivWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-family: "Helvetica Neue", san-serif;
  .container {
    padding: 1px;
    border: 1px solid;
    border-radius: 4px;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-color: ${(props) => props.color};
    .box {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: ${(props) => props.color};
    }
  }
`;
export const StatusColorDiv = ({
  color = "transparent",
  width = "24px",
  height = "24px",
}) => {
  return (
    <>
      <StatusColorDivWrapper height={height} width={width} color={color}>
        <div className="container">
          <div className="box" />
        </div>
        <ArrowDropDownIcon />
      </StatusColorDivWrapper>
    </>
  );
};

const StatusOptionWrapper = styled.div`
  padding: 8px;
  .container {
    border-radius: 4px;
    min-width: 172px;
    background-color: white;
    border: 1px solid #00000029;
    box-shadow: 0px 1px 9px #00000029;
  }
  .title {
    padding: 8px 16px;
    background-color: #e7e7e780;
  }
  .option {
    display: flex;
    cursor: pointer;
    padding: 8px 16px;
    align-items: center;
    &:hover {
      opacity: 0.5;
    }
    .box {
      width: 16px;
      height: 16px;
    }
  }
`;
export const StatusOption = ({ title, options }) => {
  return (
    <StatusOptionWrapper>
      <div className="container">
        {title && <div className="title">{title}</div>}
        {options?.map(({ color, label, onClick }, j) => (
          <div className="option" key={j} onClick={onClick}>
            <div style={{ backgroundColor: color }} className="box" />
            <div>&emsp;{label}</div>
          </div>
        ))}
      </div>
    </StatusOptionWrapper>
  );
};

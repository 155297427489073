import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";
import {
  callAddWebinarCategory,
  callDeleteWebinarCategory,
  callEditWebinarCategory,
  callFetchWebinarCategoryList,
} from "redux/webinar/apiCall";
import { webinarAction } from "redux/webinar/action";
import WebinarCategoryTable from "./WebinarCategoryTable";

const WebinarCategoryList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const webinarCategoryList = useSelector(
    ({ webinar }) => webinar.webinarCategoryList
  );
  const webinarCount = useSelector(
    ({ webinar }) => webinar.webinarCategoryCount
  );

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchWebinarCategoryList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      webinarAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleSubmit = (val) => {
    const { idIdx, ...rest } = val;

    if (type === "edit") {
      callEditWebinarCategory(
        chooseLang(lang),
        idIdx.id,
        idIdx.idx,
        rest,
        webinarCategoryList,
        dispatch,
        webinarAction,
        () => setOpen(false),
        notificationAction
      );
    } else {
      callAddWebinarCategory(
        chooseLang(lang),
        rest,
        webinarCategoryList,
        dispatch,
        webinarAction,
        () => setOpen(false),
        notificationAction
      );
    }
  };

  return (
    <>
      <WebinarCategoryTable
        data={webinarCategoryList}
        handleSubmit={handleSubmit}
        type={type}
        setType={setType}
        title={"Webinar Category"}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        totalItemCount={webinarCount}
        setSearchTerm={setSearchTerm}
        deleteSingleData={(id, idx) => {
          callDeleteWebinarCategory(
            chooseLang(lang),
            id,
            idx,
            webinarCategoryList,
            dispatch,
            webinarAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default WebinarCategoryList;

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@material-ui/core/Box";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import PaginationComponent from "components/CustomComponents/Pagination";
import { CustomDialog } from "components/CustomComponents/CustomModal/Dialog";
import { DangerouslySetInnerHTML } from "components/bits/DangerouslySetInnerHTML";
import CustomSearchTextField from "components/CustomComponents/CustomSearchTextFIeld";

import { feURL } from "api/myApi/Axios";
import { chooseLang } from "utils/myFunc";
import { muiTableLabels } from "utils/muiTableLabels";
import { blue } from "@material-ui/core/colors";
// import { DangerouslySetInnerHTML } from "components/bits/DangerouslySetInnerHTML";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  imgContainerFeedback: {
    width: "100%",
    height: "300px",
    margin: "16px 0",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      background: "#f3f3f390",
    },
  },
  flex: {
    gap: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    height: "2px",
    margin: "1rem 0",
  },
  feedbackType: {
    padding: "4px 8px",
    background: "#e5e5e5",
    borderRadius: "4px",
  },
  feedbackStatus: {
    padding: "4px 8px",
    borderRadius: "4px",
    border: "1px dotted",
    borderColor: "#e5e5e5",
  },
  span_bug: {
    color: "#ff3535",
  },
  span_feedback: {
    color: "#6abf08",
  },
  span_pending: {
    color: "#ff9800",
    borderColor: "#ff9800",
  },
  span_progress: {
    color: "#4b4bff",
    borderColor: "#4b4bff",
  },
  span_rejected: {
    color: "#ff3535",
    borderColor: "#ff3535",
  },
  span_fixed: {
    color: "#6abf08",
    borderColor: "#6abf08",
  },
  actionButton: {
    "& button": { minWidth: "150px" },
  },
  actionButtonClose: {
    marginLeft: "auto",
  },
  errButton: {
    color: "#fff",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  viewReportDetailsDialog: {
    maxWidth: "600px",
    minWidth: "300px",
  },
  reportDes: {
    padding: "0.5rem",
    borderRadius: "8px",
    border: "1px solid lightGrey",
  },
  reportDesTitle: {
    textAlign: "center",
  },
  reportedBy: {
    marginTop: "0.5rem",
    fontWeight: "600",
    justifyContent: "end",
  },
  reportedByName: {
    cursor: "pointer",
  },
  attachmentsOl: {
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 0,
    width: "min-content",
  },
  textBlue: {
    color: "blue",
  },
  textRed: {
    color: "red",
  },
}));

const findColorByValue = {
  pending: "#ff9800",
  progress: "#4b4bff",
  fixed: "#6abf08",
  rejected: "#ff3535",
};
const UserReportTable = ({
  // title,
  data,
  page,
  count,
  setPage,
  perPage,
  loading,
  setPerPage,
  // changeStatus,
  searchTerm,
  closeDialogs,
  setSearchTerm,
  deleteSingleData: onDeletePostConfirm,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const lang = useSelector(({ settings }) => settings.lang);

  const [isViewFeedbackDialogOpen, setIsViewFeedbackDialogOpen] =
    useState(false);
  const [feedbackDetail, setFeedbackDetail] = useState("");
  const [isDeletePostOpen, setIsDeletePostOpen] = useState(false);

  useEffect(() => {
    if (closeDialogs) {
      setIsDeletePostOpen(false);
      setIsViewFeedbackDialogOpen(false);
    }
  }, [closeDialogs]);

  const handleViewFeedbackClick = (i) => () => {
    setFeedbackDetail(data[i]);
    setIsViewFeedbackDialogOpen(true);
  };

  const columns = [
    {
      name: <FormattedMessage id="topic" defaultMessage="Topic" />,
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ colSpan: 5 }),
        setCellHeaderProps: () => ({ colSpan: 5 }),
        customBodyRender: (value) => {
          return <DangerouslySetInnerHTML content={value} />;
        },
      },
    },
    {
      name: <FormattedMessage id="type" defaultMessage="Type" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="count" defaultMessage="Count" />,
      options: {
        sort: false,
        filter: false,
      },
    },

    {
      name: <FormattedMessage id="status" defaultMessage="status" />,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return (
            <Typography
              className={
                value === "NOT_DELETED" ? classes.textBlue : classes.textRed
              }
            >
              <FormattedMessage
                id={value?.toLowerCase()}
                defaultMessage={value?.replace("_", " ")}
              />
            </Typography>
          );
          // return <DangerouslySetInnerHTML content={value} />;
        },
      },
    },
    {
      name: intl.formatMessage({
        id: "common:Action",
        defaultMessage: `Action`,
      }),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          return (
            <Box display="flex">
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleViewFeedbackClick(index)}
                >
                  <FormattedMessage id="view" defaultMessage="View" />
                </Button>
              </div>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    count,
    page: page,
    print: false,
    filter: false,
    search: false,
    download: false,
    serverSide: true,
    viewColumns: false,
    rowsPerPage: perPage,
    selectableRows: false,
    responsive: "stacked",
    filterType: "dropdown",
    rowsPerPageOptions: [10, 20, 50, 100],
    onChangePage: (currentPage) => setPage(currentPage),
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    customFooter: () => (
      <PaginationComponent
        page={page}
        setPage={setPage}
        totalCount={Math.ceil(count / 10)}
      />
    ),
    customToolbar: () => (
      <CustomSearchTextField
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    ),
    textLabels: {
      ...muiTableLabels(intl),
      body: {
        noMatch: loading ? (
          <CircularProgress size={30} />
        ) : (
          intl.formatMessage({
            id: "muiTableLabels.body.noMatch",
            defaultMessage: "Sorry, no matching records found",
          })
        ),
      },
    },
  };

  const handleViewClickPostClick = () => {
    let v = feedbackDetail?.reports?.[0]?.post_details;
    let langUrl = `${feURL}/${lang}/app`;
    console.log({ v });
    if (["post", "info"]?.includes(v.type)) {
      // if (v?.group?.group_id)
      //   langUrl += `/my-platform/${v?.group?.group_slug}/post?postId=${v?.id}`;
      // else
      langUrl += `/post?postId=${v?.id}`;
    }

    if (v.type === "sale") langUrl += `/shoppings/${v?.slug}`;
    if (v.type === "webinar") langUrl += `/webinars/${v?.slug}`;

    window.open(langUrl, "_blank");
  };

  const handleDeletePost = () => {
    setIsDeletePostOpen(true);
  };

  const handleDeletePostConfirm = () => {
    if (loading) return;
    let v = feedbackDetail?.reports?.[0];
    let data = {
      lang: chooseLang(lang),
      user_id: v?.user_detail?.id,
      post_id: v?.post_details?.id,
      group_id: v?.group_details?.id,
    };
    onDeletePostConfirm(data);
  };

  const openProfile = (i) => {
    let langUrl = `${feURL}/${lang}/`;
    let v = i?.post_details?.reported_by;
    window.open(`${langUrl}app/profile/${v?.username}`, "_blank");
  };
  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "reported_posts",
          defaultMessage: "Reported Posts",
        })}
        columns={columns}
        options={options}
        data={
          data?.map((i) => {
            const name =
              i?.reports?.[0]?.post_details?.name ||
              i?.reports?.[0]?.post_details?.description;
            const type = i?.reports?.[0]?.post_details?.type
              ? `post_type_${i?.reports?.[0]?.post_details?.type}`
              : "-";
            const t_type = intl.formatMessage({
              id: `${type}`,
              defaultMessage: type,
            });
            const is_deleted = i?.reports?.[0]?.post_details?.is_deleted; //NOT_DELETED, DELETED
            const count = i?.reports.length;

            return [name, t_type, count, is_deleted, i];
          }) || []
        }
      />
      <CustomDialog
        open={isViewFeedbackDialogOpen}
        onClose={() => setIsViewFeedbackDialogOpen(false)}
        // title={feedbackDetail?.feedback_titles?.[0]?.title}
        ContentComponent={
          <Box padding="0 8px 8px" className={classes.viewReportDetailsDialog}>
            <Typography variant="h5">
              <FormattedMessage
                id={`post_details`}
                defaultMessage={"Post details"}
              />
            </Typography>
            {feedbackDetail?.reports?.[0]?.post_details?.name ? (
              <Typography variant="h6" gutterBottom>
                {feedbackDetail?.reports?.[0]?.post_details?.name}
              </Typography>
            ) : (
              <DangerouslySetInnerHTML
                content={
                  feedbackDetail?.reports?.[0]?.post_details?.description
                }
              />
            )}
            {feedbackDetail?.reports?.[0]?.post_details?.cover_image && (
              <Box key={"cover_image"} className={classes.imgContainerFeedback}>
                <img
                  src={feedbackDetail?.reports?.[0]?.post_details?.cover_image}
                  alt={`cover_image`}
                />
              </Box>
            )}
            {feedbackDetail?.reports?.[0]?.post_details?.images?.map((i, j) => (
              <Box key={j} className={classes.imgContainerFeedback}>
                <img src={i} alt={`section-${j}`} />
              </Box>
            ))}
            <Box className={classNames(classes.flex, classes.actionButton)}>
              {feedbackDetail?.reports?.[0]?.post_details?.type && (
                <Button
                  size="small"
                  color="primary"
                  disableElevation
                  variant="outlined"
                >
                  <FormattedMessage
                    id={`post_type_${feedbackDetail?.reports?.[0]?.post_details?.type}`}
                    defaultMessage={
                      feedbackDetail?.reports?.[0]?.post_details?.type
                    }
                  />
                </Button>
              )}
              {feedbackDetail?.reports?.[0]?.post_details?.is_deleted ===
                "NOT_DELETED" && (
                <>
                  <Button
                    size="small"
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={handleViewClickPostClick}
                  >
                    <FormattedMessage
                      id={`view_post`}
                      defaultMessage={`View Post`}
                    />
                  </Button>
                  <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    onClick={handleDeletePost}
                    className={classes.errButton}
                  >
                    <FormattedMessage
                      id={`remove_post`}
                      defaultMessage={`Remove Post`}
                    />
                  </Button>
                </>
              )}
            </Box>
            <Divider className={classNames(classes.divider)} />
            <Typography variant="h5">
              <FormattedMessage id={`reports`} defaultMessage={"Reports"} />
            </Typography>
            {feedbackDetail?.reports?.map((i, j) => {
              let imgs = [...(i?.images || []), ...(i?.urls || [])];
              return (
                <Box key={i?.id}>
                  <Typography>
                    &nbsp;
                    <b>
                      <FormattedMessage
                        id={`report`}
                        defaultMessage={"Report"}
                      />
                      -{j + 1}
                    </b>
                  </Typography>
                  <Box className={classes.reportDes}>
                    <Typography
                      color="primary"
                      className={classes.reportDesTitle}
                    >
                      {i?.title}
                    </Typography>
                    <Divider />
                    <Box className={classes.reportDesDes}>
                      <DangerouslySetInnerHTML content={i?.description} />
                    </Box>
                    {imgs?.length > 0 && (
                      <>
                        <Divider />
                        <Typography>
                          <FormattedMessage
                            id={`attachments`}
                            defaultMessage={"Attachments"}
                          />
                        </Typography>
                        <ol className={classes.attachmentsOl}>
                          {imgs?.map((img, idx) => (
                            <li key={idx}>
                              <a href={img} target="_blank">
                                <i>{img}</i>
                              </a>
                            </li>
                          ))}
                        </ol>
                      </>
                    )}
                  </Box>
                  <Typography
                    className={classNames(classes.flex, classes.reportedBy)}
                  >
                    <span>
                      <FormattedMessage
                        id={`reported_by`}
                        defaultMessage={"Reported By"}
                      />
                      : &nbsp;
                      <Typography
                        color="primary"
                        component={"span"}
                        onClick={() => openProfile(i)}
                        className={classes.reportedByName}
                      >
                        {i?.post_details?.reported_by?.name}
                      </Typography>
                    </span>
                  </Typography>
                  <Divider className={classNames(classes.divider)} />
                </Box>
              );
            })}
            <Box className={classNames(classes.flex, classes.actionButton)}>
              {feedbackDetail?.reports?.[0]?.post_details?.is_deleted ===
                "NOT_DELETED" && (
                <>
                  <Button
                    size="small"
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={handleViewClickPostClick}
                  >
                    <FormattedMessage
                      id={`view_post`}
                      defaultMessage={`View Post`}
                    />
                  </Button>
                  <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    onClick={handleDeletePost}
                    className={classes.errButton}
                  >
                    <FormattedMessage
                      id={`remove_post`}
                      defaultMessage={`Remove Post`}
                    />
                  </Button>
                </>
              )}
              <Button
                size="small"
                color="secondary"
                disableElevation
                variant="outlined"
                className={classes.actionButtonClose}
                onClick={() => setIsViewFeedbackDialogOpen(false)}
              >
                <FormattedMessage id={`close`} defaultMessage={`Close`} />
              </Button>
            </Box>
          </Box>
        }
      />
      <CustomDialog
        icon={"delete"}
        isLoading={loading}
        open={isDeletePostOpen}
        confirmOnClick={handleDeletePostConfirm}
        onClose={() => setIsDeletePostOpen(false)}
        cancelOnClick={() => setIsDeletePostOpen(false)}
        cancelText={intl.formatMessage({ id: "cancel" })}
        title={intl.formatMessage({ id: "are_you_sure" })}
        confirmText={intl.formatMessage({ id: "confirm" })}
        content={intl.formatMessage({ id: "delete_reported_post" })}
      />
    </div>
  );
};

PropTypes.UserReportTable = {
  title: PropTypes.string.isRequired,
};

export default UserReportTable;

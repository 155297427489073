import { darkPalette, lightPalette } from './palette';

const themePalette = (mode) => {
  if (mode === 'dark') {
    return darkPalette;
  }
  return lightPalette;
};

export default themePalette;

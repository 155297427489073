import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  makeStyles,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import {
  Field,
  ImageFileMUI,
  MUIForms,
  SelectMUI,
} from "components/Forms/MUIForms";
import { FormattedMessage, useIntl } from "react-intl";
import { required } from "utils/Validation";
import { chooseLang, deleteImageFunction } from "utils/myFunc";
import { useDispatch, useSelector } from "react-redux";
import { notificationAction } from "redux/notification/action";
import { getItem, removeItem } from "utils/storageFunc";
import {
  callAddImage,
  callEditImage,
  callRemoveBanner,
} from "redux/image/apiCall";
import { imageAction } from "redux/image/action";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "50%",
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  button: {
    width: 400,
    margin: "auto",
    marginTop: 20,
  },
}));

const AddImage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("Add");
  const [initialValues, setInitialValues] = useState("");

  const loading = useSelector(({ image }) => image.loading);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setType("Edit");
    }
  }, [location.pathname]);

  useEffect(() => {
    const singleBanner = getItem("singleBanner");
    if (singleBanner) {
      const parseValue = { ...JSON.parse(singleBanner) };
      parseValue.images = parseValue.images.map((image) => ({
        file: image,
        blob: image,
      }));

      setInitialValues(parseValue);
    }
    return () => {
      removeItem("singleBanner");
    };
  }, []);

  const handleSubmit = (val) => {
    const tempValue = { ...val };

    if (type === "Edit") {
      const { fileList, deleteList } = deleteImageFunction(
        initialValues?.images,
        tempValue?.images
      );
      tempValue.images = fileList?.map((image) => image?.file);

      callEditImage(
        chooseLang(lang),
        initialValues?.id,
        tempValue,
        dispatch,
        imageAction,
        () => {
          if (deleteList?.length > 0) {
            deleteList.forEach((element) => {
              callRemoveBanner(
                chooseLang(lang),
                initialValues?.id,
                { image: element },
                dispatch,
                imageAction
              );
            });
            history.push("/app/banner-images");
          } else {
            history.push("/app/banner-images");
          }
        },
        notificationAction
      );
    } else {
      tempValue.images = tempValue?.images?.map((image) => image.file);

      callAddImage(
        chooseLang(lang),
        tempValue,
        dispatch,
        imageAction,
        () => {
          history.push("/app/banner-images");
        },
        notificationAction
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">
        <FormattedMessage
          id={`${type.toLocaleLowerCase()}_banner_images`}
          defaultMessage={`${type} Banner Images`}
        />
      </Typography>
      <br />
      <MUIForms
        onSubmit={handleSubmit}
        className={classes.formDiv}
        initialValues={initialValues}
      >
        <FormControl
          fullWidth
          className={classes.formControl}
          variant="outlined"
        >
          <Field
            name="page"
            label={intl.formatMessage({
              id: "page",
              defaultMessage: "Page",
            })}
            variant="outlined"
            size="medium"
            validate={[required]}
            component={SelectMUI}
            fullWidth
          >
            <MenuItem value="home">
              <FormattedMessage id="home" defaultMessage="Home" />
            </MenuItem>
            <MenuItem value="event">
              <FormattedMessage id="event" defaultMessage="Event" />
            </MenuItem>
          </Field>
        </FormControl>
        <br />
        <br />
        <FormControl
          fullWidth
          className={classes.formControl}
          variant="outlined"
        >
          <Field
            name="type"
            label={intl.formatMessage({
              id: "type",
              defaultMessage: "Type",
            })}
            variant="outlined"
            size="medium"
            validate={[required]}
            component={SelectMUI}
            fullWidth
          >
            <MenuItem value="main">
              <FormattedMessage id="main" defaultMessage="Main" />
            </MenuItem>
            <MenuItem value="side">
              <FormattedMessage id="side" defaultMessage="side" />
            </MenuItem>
          </Field>
        </FormControl>

        <br />
        <FormControl fullWidth className={classes.formControl}>
          <Field
            type="file"
            name="images"
            fullWidth
            component={ImageFileMUI}
            multiple={true}
            validate={[required]}
          />
        </FormControl>
        <br />
        <FormControl className={classes.formControl} fullWidth>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.button}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            {intl.formatMessage({ id: "submit", defaultMessage: "Submit" })}
          </Button>
        </FormControl>
      </MUIForms>
    </Paper>
  );
};

export default AddImage;

import React, { useState, useEffect, useContext } from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import dummy from "api/dummy/dummyContents";
import LeftSidebarLayout from "./layouts/LeftSidebar";
import styles from "./appStyles-jss";
import { AppContext } from "../App/ThemeWrapper";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { callLogout } from "redux/login/apiCall";
import { loginAction } from "redux/login/action";

const useStyles = makeStyles((theme) => styles(theme));

const Dashboard = (props) => {
  const { children, user } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { mode, pageLoaded, setPageLoaded } = useContext(AppContext);
  const [openGuide, setOpenGuide] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
    history.listen(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setPageLoaded(true);
      }, 500);
    });
    // eslint-disable-next-line
  }, [history]);

  const handleOpenGuide = () => {
    console.log(openGuide);
    setOpenGuide(true);
  };

  const signOut = () => {
    callLogout(dispatch, loginAction, history);
  };

  const titleException = [
    "/app",
    "/app/crm-dashboard",
    "/app/crypto-dashboard",
  ];
  const parts = history.location.pathname.split("/");
  const place = parts[parts.length - 1].replace("-", " ");
  const profile = (userProfile) => {
    if (userProfile) {
      return {
        avatar: userProfile.photoURL || dummy.user.avatar,
        name: userProfile.displayName,
      };
    }
    return {
      avatar: dummy.user.avatar,
      name: dummy.user.name,
    };
  };
  return (
    <div
      className={classNames(
        classes.appFrameInner,
        classes.sideNav,
        mode === "dark" ? "dark-mode" : "light-mode"
      )}
    >
      <LeftSidebarLayout
        history={history}
        loadTransition={setPageLoaded}
        pageLoaded={pageLoaded}
        place={place}
        titleException={titleException}
        handleOpenGuide={handleOpenGuide}
        signOut={signOut}
        userAttr={profile(user)}
      >
        {children}
      </LeftSidebarLayout>
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
};

Dashboard.defaultProps = {
  user: null,
};

export default Dashboard;

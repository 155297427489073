import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Box,
  Button,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { PartnerUserDetailModal } from "./PartnerUserDetailModal";

import { CustomDialog } from "components/CustomComponents/CustomModal/Dialog";
import { muiTableLabels } from "utils/muiTableLabels";
import PaginationComponent from "components/CustomComponents/Pagination";
import CustomSelectField from "components/CustomComponents/CustomSelectFIeld";
import CustomSearchTextField from "components/CustomComponents/CustomSearchTextFIeld";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  popper: {
    zIndex: 1000,
  },
  popperPaper: {
    padding: theme.spacing(2),
  },
  formDiv: {
    width: "325px",
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelIconButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  searchTextField: {
    width: "50%",
    borderRadius: "10px",
  },
  image: {
    width: "60px",
    height: "60px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  btnGrp: {
    display: "flex",
    gap: "8px",
  },
  button: {
    minWidth: 110,
    borderRadius: 0,
  },
  viewButton: {
    color: "white",
    background: "green",
    "&:hover": {
      background: "darkGreen",
    },
  },
  rejectButton: {
    color: "white",
    background: "red",
    "&:hover": {
      background: "darkRed",
    },
  },
  userAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const PartnerUserReqTable = ({
  data,
  page,
  count,
  setPage,
  perPage,
  loading,
  select,
  setSelect,
  searchTerm,
  setPerPage,
  setSearchTerm,
  acceptRejectUser,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [isUserDetailDialogOpen, setIsUserDetailDialogOpen] = useState(false);

  const [userDetail, setUserDetail] = useState("");

  const handleViewUserDetailClick = (i) => () => {
    setUserDetail(data[i]);

    console.log(userDetail);
    setIsUserDetailDialogOpen(true);
  };

  const label = (v) =>
    intl.formatMessage({
      id: v,
      defaultMessage: v.charAt(0).toUpperCase() + v.slice(1),
    });

  const SelectOptions = ["pending", "accepted", "rejected"].map((i) => ({
    value: i,
    label: label(i),
  }));

  const columns = [
    {
      name: <FormattedMessage id="name" defaultMessage="Name" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="email" defaultMessage="Email" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="contactNo" defaultMessage="Contact No." />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: (
        <FormattedMessage
          id="is_profile_complete"
          defaultMessage="Is Profile Complete"
        />
      ),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return (
            <Typography color={value ? "primary" : "error"}>
              {intl.formatMessage({
                id: value ? "completed" : "incomplete",
                defaultMessage: "Incomplete",
              })}
            </Typography>
          );
        },
      },
    },
    {
      name: (
        <FormattedMessage
          id="is_stripe_verified"
          defaultMessage="Is Stripe Verified"
        />
      ),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return (
            <Typography color={value ? "primary" : "error"}>
              {intl.formatMessage({
                id: value ? "verified" : "unverified",
                defaultMessage: "Unverified",
              })}
            </Typography>
          );
        },
      },
    },
    {
      name: <FormattedMessage id="requestDate" defaultMessage="Request date" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    select === "pending" ? (
      {
        name: <FormattedMessage id="actions" defaultMessage="Actions" />,
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta) => {
            const index = tableMeta.rowIndex;
            return (
              <div className={classes.btnGrp}>
                <Button
                  color=""
                  variant="contained"
                  className={classes.button + " " + classes.viewButton}
                  onClick={handleViewUserDetailClick(index)}
                >
                  <FormattedMessage id="view" defaultMessage="View" />
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    acceptRejectUser(data[index].id, index, "approved");
                  }}
                >
                  <FormattedMessage id="accept" defaultMessage="Accept" />
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.button + " " + classes.rejectButton}
                  onClick={() =>
                    acceptRejectUser(data[index].id, index, "rejected")
                  }
                >
                  <FormattedMessage id="reject" defaultMessage="Reject" />
                </Button>
              </div>
            );
          },
        },
      }
    ) : (
      <span />
    ),
  ];

  const options = {
    count,
    page: page,
    print: false,
    filter: false,
    search: false,
    download: false,
    serverSide: true,
    viewColumns: false,
    rowsPerPage: perPage,
    selectableRows: false,
    responsive: "stacked",
    filterType: "dropdown",
    rowsPerPageOptions: [10, 20, 50, 100],
    onChangePage: (currentPage) => setPage(currentPage),
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    customFooter: () => (
      <PaginationComponent
        totalCount={Math.ceil(count / 10)}
        page={page}
        setPage={setPage}
      />
    ),
    customToolbar: () => (
      <>
        <CustomSearchTextField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CustomSelectField
          value={select}
          label={label("select")}
          options={SelectOptions}
          onChange={(e) => setSelect(e.target.value)}
        />
      </>
    ),
    textLabels: {
      ...muiTableLabels(intl),
      body: {
        noMatch: loading ? (
          <CircularProgress size={30} />
        ) : (
          intl.formatMessage({
            id: "muiTableLabels.body.noMatch",
            defaultMessage: "Sorry, no matching records found",
          })
        ),
      },
    },
  };
  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "partner_users",
          defaultMessage: " Partner Users",
        })}
        columns={columns}
        options={options}
        data={
          data?.map((i) => [
            i?.contact_name,
            i?.email,
            (i?.country_code || "") + (i?.phone || "") || "-",
            i?.is_profile_completed,
            i?.is_stripe_verified,
            i?.created_at ? moment(i?.created_at).format("DD MMM YYYY") : "",
            select === "pending" ? i?.id : "",
          ]) || []
        }
      />

      <CustomDialog
        open={isUserDetailDialogOpen}
        onClose={() => setIsUserDetailDialogOpen(false)}
        // title={userDetail?.feedback_titles?.[0]?.title}
        ContentComponent={
          <PartnerUserDetailModal
            data={userDetail}
            onClose={() => setIsUserDetailDialogOpen(false)}
          />
        }
      />
    </div>
  );
};

PropTypes.PartnerUserReqTable = {
  title: PropTypes.string.isRequired,
};

export default PartnerUserReqTable;

import {
  getWithoutHeader,
  postWithHeader,
  deleteWithHeader,
  patchWithHeader,
} from "api/myApi/ApiFunc";
import { NoticeLink, ImageLink } from "api/myApi/link";

export const callFetchImageList = (
  lang,
  params,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithoutHeader(
    `${ImageLink(lang)}`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          imageList: response.data.rows,
          imageCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = {
        message: "Error !! Please Try Again",
      };
      let payloadMsg = error?.message ?? errMsg.message;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callAddImage = (
  lang,
  val,
  dispatch,
  reducer,
  successFunction,
  notification
) => {
  const formData = new FormData();

  Object.entries(val).map((i) => {
    if (i[0] === "images") {
      i[1].forEach((element) => {
        formData.append(i[0], element);
      });
    } else {
      formData.append(i[0], i[1]);
    }
    return i;
  });
  dispatch(
    reducer({
      loading: true,
    })
  );

  postWithHeader(
    `${ImageLink(lang)}/add`,
    formData,
    (response) => {
      dispatch(
        reducer({
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Added",
            type: "success",
            timer: 3000,
          },
        })
      );
      if (successFunction) successFunction();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );

      dispatch(
        reducer({
          error: Object.values(payloadMsg)[0],
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};
export const callEditImage = (
  lang,
  id,
  val,
  dispatch,
  reducer,
  successFunction,
  notification
) => {
  const formData = new FormData();

  Object.entries(val).map((i) => {
    if (i[0] === "images") {
      i[1].forEach((element) => {
        formData.append(i[0], element);
      });
    } else {
      formData.append(i[0], i[1]);
    }
    return i;
  });

  dispatch(
    reducer({
      loading: true,
    })
  );
  patchWithHeader(
    `${ImageLink(lang)}/${id}`,
    formData,
    (response) => {
      dispatch(
        reducer({
          loading: false,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Edited",
            type: "success",
            timer: 3000,
          },
        })
      );
      if (successFunction) successFunction();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callRemoveBanner = (
  lang,
  id,
  val,
  dispatch,
  reducer,
  successFunction
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  postWithHeader(
    `${ImageLink(lang)}/remove/${id}`,
    val,
    (response) => {
      dispatch(
        reducer({
          loading: false,
        })
      );

      if (successFunction) successFunction();
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;

      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteImage = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${NoticeLink(lang)}/${id}`,
    "",
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          noticeList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Deleted",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

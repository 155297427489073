import clsx from "clsx";
import { useIntl } from "react-intl";
import React, { useCallback } from "react";
import {
  Box,
  Grid,
  makeStyles,
  IconButton,
  Typography,
  Avatar,
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@material-ui/core/styles";
/** Material UI icons */
import CloseIcon from "@material-ui/icons/Close";
import ImageIcon from "@material-ui/icons/Image";
// import UploadIcon from "@material-ui/icons/CloudUpload";
import FileIcon from "@material-ui/icons/InsertDriveFile";

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    flex: 1,
    borderWidth: 2,
    outline: "none",
    display: "flex",
    alignItems: "center",
    borderStyle: "dashed",
    flexDirection: "column",
    padding: theme.spacing(3),
    backgroundColor: "#f3f3f3",
    // margin: theme.spacing(2, 0),
    color: theme.palette.text.primary,
    transition: "border .24s ease-in-out",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.common.black,
  },
  uploadIcon: {
    color: "#3D454890",
    fontSize: theme.typography.h2.fontSize,
  },
  uploadPlaceholder: {
    textAlign: "center",
    // color: theme.palette.text.primary,
    lineHeight: 1.8,
    // ...theme.typography.body1,
  },
  selectedFileContainer: {
    justifyContent: "center",
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.light,
  },
  selectedFileContainerErr: {
    backgroundColor: theme.palette.secondary.light,
  },
  selectedFileName: {
    flex: 1,
  },
  selectedFileIcon: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(3),
    marginRight: theme.spacing(1.6),
    fontSize: theme.typography.h3.fontSize,
    backgroundColor: `${theme.palette.primary.main}60`,
    border: `1px solid ${theme.palette.primary.main}80`,
  },
  selectedImgAvatar: {
    height: "150px",
    width: "150px",
  },
  selectedFileIconErr: {
    backgroundColor: `${theme.palette.secondary.main}60`,
    border: `1px solid ${theme.palette.secondary.main}80`,
  },
  selectedFileRemove: {
    // alignSelf: "flex-end",
    color: theme.palette.common.white,
  },
}));

export const UploadFile = ({
  error,
  value,
  loading,
  onChange,
  accept = "image/png, image/jpeg ,image/jpg",
  // accept= "text/csv, application/vnd.ms-excel, .csv, .xls, .xlsx",
  multiple = false,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();

  const onFileRemove = (index) => (event) => {
    event.stopPropagation();
    const files = [...value];
    files.splice(index, 1);
    onChange(files);
  };

  const onFileDrop = useCallback((files) => {
    console.log({ files });

    onChange(
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // eslint-disable-next-line
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: onFileDrop,
    multiple,
    noKeyboard: true,
    // noClick: true,
    accept,
  });

  return (
    <>
      <section className="container">
        <div
          {...getRootProps({
            className: classes.uploadContainer,
            style: {
              borderColor: isDragActive
                ? "#2196f3"
                : isDragAccept
                ? theme.palette.primary.main
                : isDragReject
                ? theme.palette.error.main
                : "#3D454890",
            },
          })}
        >
          <input {...getInputProps()} />
          {value && value.length > 0 ? (
            value.map((file, index) => (
              <Grid
                container
                direction="row"
                alignItems="center"
                key={file.name || index}
                className={clsx(
                  classes.selectedFileContainer,
                  error ? classes.selectedFileContainerErr : ""
                )}
              >
                {file?.preview || file?.type?.includes("image") ? (
                  <Avatar
                    variant="rounded"
                    className={clsx(classes.selectedImgAvatar)}
                    src={file?.preview}
                    alt={file?.name || "uploaded file"}
                  />
                ) : (
                  <FileIcon
                    className={clsx(
                      classes.selectedFileIcon,
                      error ? classes.selectedFileIconErr : ""
                    )}
                  />
                )}

                <Box flex={1}>
                  <Typography
                    color="secondary"
                    className={classes.selectedFileName}
                  >
                    {file.name}
                  </Typography>
                  {loading && (
                    <Typography className={classes.selectedFileName}>
                      uploading...
                    </Typography>
                  )}
                </Box>
                <IconButton
                  color="secondary"
                  onClick={onFileRemove(index)}
                  className={classes.selectedFileRemove}
                >
                  <CloseIcon color="secondary" />
                </IconButton>
              </Grid>
            ))
          ) : (
            <>
              <ImageIcon className={classes.uploadIcon} />
              <p className={classes.uploadPlaceholder}>
                {intl.formatMessage({
                  id: "uploadFileHelperText",
                  defaultMessage:
                    "Click or drag media file to this area to upload",
                })}
              </p>
            </>
          )}
        </div>
      </section>
    </>
  );
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    fontSize: "100px",
    color: "#777",
  },
  pointer: {
    cursor: "pointer",
  },
  input: {
    display: "none !important",
  },
}));

const UploadImage = ({
  name,
  required = false,
  multiple = false,
  value,
  imageList,
}) => {
  const classes = useStyles();

  // console.log(imageAlt);
  const addFile = (blob, file) => {
    imageList(blob, file);
  };

  const handleChange = (e) => {
    const fileList = e.target.files;
    const files = Object.values(fileList);
    // imageFileList(files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        addFile(e.target.result, file);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className={classes.root}>
      <input
        accept="image/x-png,image/jpeg"
        className={classes.input}
        id={`contained-button-file-${name}`}
        type="file"
        name={name}
        required={required}
        onChange={(e) => {
          handleChange(e);
        }}
        multiple={multiple}
      />
      <label
        htmlFor={`contained-button-file-${name}`}
        className={classes.pointer}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="150px"
          border="1px dashed #888"
          borderRadius="4px"
          cursor="pointer"
          flexDirection="column"
        >
          <CloudUploadIcon fontSize="large" className={classes.icon} />
          Click Here to upload Images
          <br />
        </Box>

        {/* {image?.length === 0 && ( */}
        {/* <Box component="span" color="#8080808f" marginLeft="5px">
          No file chosen
        </Box> */}
        {/* )} */}
      </label>
    </div>
  );
};

export default UploadImage;

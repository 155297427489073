import { basePURL, baseURL } from "./Axios";

// export const AdminLink = `${baseURL}admin`;
export const LoginLink = `${baseURL}en/admin/login`;
export const FaqLink = (l) => `${baseURL}${l}/faq`;
export const UserLink = (l) => `${baseURL}${l}/user`;
export const AdminLink = (l) => `${baseURL}${l}/admin`;
export const EventLink = (l) => `${baseURL}${l}/event`;
export const ImageLink = (l) => `${baseURL}${l}/banner`;
export const NoticeLink = (l) => `${baseURL}${l}/notice`;
export const ReportLink = (l) => `${baseURL}${l}/report`;
export const LandingLink = (l) => `${baseURL}${l}/landing`;
export const MeetingLink = (l) => `${baseURL}${l}/meeting`;
export const ShoppingLink = (l) => `${baseURL}${l}/product`;
export const WebinarLink = (l) => `${baseURL}${l}/webinar`;
export const WithdrawLink = (l) => `${baseURL}${l}/withdraw`;
export const IndustryLink = (l) => `${baseURL}${l}/industry`;
export const OccupationLink = (l) => `${baseURL}${l}/occupation`;
export const MusicGroupLink = (l) => `${baseURL}${l}/music-group`;
export const JobCategoryLink = (l) => `${baseURL}${l}/job-category`;
export const PartnerUserLink = (l) => `${basePURL}${l}/partner-dash`;
export const EventSupportLink = (l) => `${baseURL}${l}/event-support`;
export const ServiceCategoryLink = (l) => `${baseURL}${l}/service-category`;
export const ProductCategoryLink = (l) => `${baseURL}${l}/product-category`;
//Property
export const FacilityLink = `facility`;
export const SecurityLink = `security`;
export const RoomFacilityLink = `room-facility`;
export const PropertyTypeLink = `property-type`;
export const ProperLayoutLink = `property-layout`;
export const MoveInConditionLink = `movein-condition`;
export const MiscellaneousConditionLink = `misc-condition`;
//Location
export const CountryLink = "country";
export const CurrencyLink = "currency";
export const PrefectureLink = "prefecture";
export const MunicipalityLink = "municipality";
//Payment
// export const PaymentLink = "payment";
// export const WithdrawLink = "withdraw";

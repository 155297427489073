// import { applyMiddleware, createStore, combineReducers } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import logger from 'redux-logger';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import reducers from './reducers';

// export default function configureStore(preloadedState) {
//   const middleware = [logger, thunkMiddleware];
//   const middlewareEnhancer = applyMiddleware(...middleware);
//   const enhancers = [middlewareEnhancer];
//   const composedEnhancers = composeWithDevTools(...enhancers);

//   const combineReducer = combineReducers({ ...reducers });

//   const store = createStore(combineReducer, preloadedState, composedEnhancers);

//   // if (process.env.REACT_APP_ENV !== 'production' && module.hot) {
//   //   module.hot.accept('./reducers', () => store.replaceReducer(reducers));
//   // }

//   return store;
// }
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "./reducers";

const persistConfig = {
  key: "skysales-dashboard",
  storage: storage,
  whitelist: ["login", "settings"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({ ...reducers })
);

const middleware = [logger, thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middleware);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

let store = createStore(persistedReducer, composedEnhancers);
let persistor = persistStore(store);

export { store, persistor };

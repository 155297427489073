import React, { useEffect, useState } from "react";
// import { Box, Button, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { ModalComp } from "components/fragments/ModalComp";
import PaymentWithdrawReqTable from "./PaymentWithdrawReqTable";

import {
  callFetchPaymentWithdrawReq,
  callReleaseWithdrawReq,
  callVerifyWithdrawReq,
} from "redux/payment/apiCall";
import { chooseLang } from "utils/myFunc";
import useDebounce from "utils/useDebounce";
import { paymentAction } from "redux/payment/action";
import { notificationAction } from "redux/notification/action";

const PaymentWithdrawReqList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const lang = useSelector(({ settings }) => settings.lang);
  const loading = useSelector(({ payment }) => payment.loading);
  const list = useSelector(({ payment }) => payment.paymentWithdrawReqList);
  const count = useSelector(({ payment }) => payment.paymentWithdrawReqCount);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [openConfirmReleaseDialog, setOpenConfirmReleaseDialog] = useState("");

  const debounce = useDebounce(searchTerm, 300);

  useEffect(() => {
    callFetchPaymentWithdrawReq(
      chooseLang(lang),
      {
        limit: perPage,
        name: debounce,
        status: "pending",
        offset: page * perPage,
      },
      dispatch,
      paymentAction
    );
    // eslint-disable-next-line
  }, [page, perPage, debounce]);

  const handleVerifyReq = (id, idx, val) => {
    callVerifyWithdrawReq(
      chooseLang(lang),
      id,
      idx,
      val,
      list,
      dispatch,
      paymentAction,
      notificationAction
    );
  };

  const handleReleasePayment = (id, idx, val) => {
    setOpenConfirmReleaseDialog({ id, idx, val });
  };

  const handleAccept = () => {
    callReleaseWithdrawReq(
      chooseLang(lang),
      openConfirmReleaseDialog?.id,
      openConfirmReleaseDialog?.idx,
      openConfirmReleaseDialog?.val,
      list,
      dispatch,
      paymentAction,
      notificationAction,
      () => setOpenConfirmReleaseDialog(false),
      intl
    );
  };
  return (
    <>
      <PaymentWithdrawReqTable
        data={list}
        // data={[
        //   {
        //     user_slug: "user_slug",
        //     email: "",
        //     country_code: "",
        //     phone: "",
        //     created_at: "",
        //     amount: "",
        //     status: "",
        //     id: 2,
        //   },
        // ]}
        page={page}
        count={count}
        loading={loading}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        verifyReq={handleVerifyReq}
        setSearchTerm={setSearchTerm}
        releasePayment={handleReleasePayment}
      />
      <ModalComp
        isOpen={Boolean(openConfirmReleaseDialog)}
        handleClose={() => setOpenConfirmReleaseDialog("")}
        onAccept={handleAccept}
        cancel={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
        title={
          <FormattedMessage
            id={
              openConfirmReleaseDialog?.val === "rejected"
                ? "confirmRejectDialogTitle"
                : "confirmReleaseDialogTitle"
            }
            defaultMessage={
              openConfirmReleaseDialog?.val === "rejected"
                ? "Confirm reject payment"
                : "Confirm release payment"
            }
          />
        }
        description={
          <FormattedMessage
            id={
              openConfirmReleaseDialog?.val === "rejected"
                ? "rejectReleaseDialogDes"
                : "confirmReleaseDialogDes"
            }
            defaultMessage={
              openConfirmReleaseDialog?.val === "rejected"
                ? "By clicking the 'Reject payment' button below, I hereby confirm this action and agree the SkySales Terms and Conditions and Privacy Policy."
                : "By clicking the 'Release payment' button below, I hereby confirm this action and agree the SkySales Terms and Conditions and Privacy Policy."
            }
          />
        }
        accept={
          <FormattedMessage
            id={
              openConfirmReleaseDialog?.val === "rejected"
                ? "rejectPayment"
                : "releasePayment"
            }
            defaultMessage={
              openConfirmReleaseDialog?.val === "rejected"
                ? "Reject Payment"
                : "Release Payment"
            }
          />
        }
      />
    </>
  );
};

export default PaymentWithdrawReqList;

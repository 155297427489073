import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { capitalize } from "utils/myFunc";

const generalFields = [
  {
    key: "contact_name",
    label: "contact_name",
  },
  {
    key: "contact_name_katakana",
    label: "contact_name_katakana",
  },
  {
    key: "phone",
    label: "phone_number",
  },
  {
    key: "email",
    label: "email",
  },
];

const companyFields = [
  {
    key: "company_name",
    label: "company_name",
  },
  {
    key: "company_name_katakana",
    label: "company_name_katakana",
  },
  {
    key: "company_registration",
    label: "company_established_date",
  },
  {
    key: "company_address",
    label: "company_address",
  },
  {
    key: "employee_count",
    label: "employee_count",
  },
  {
    key: "website",
    label: "website",
  },
];
const descFields = [
  {
    key: "about_me",
    label: "about",
  },
  {
    key: "features",
    label: "features_appeal",
  },
];

const imageFields = [
  {
    key: "cover_image",
    label: "cover_image",
  },
  {
    key: "logo",
    label: "logo",
  },
  {
    key: "contact_person_image",
    label: "contact_person_image",
  },
];
const useStyles = makeStyles((theme) => ({
  divider: {
    margin: "12px 0 12px",
  },
  body: {
    maxWidth: "500px",
  },
  userDetails: {
    display: "grid",
    gap: " 12px 6px",
    textAlign: "left",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  userDetailsSingle: {
    placeItems: "start",
    gridColumn: "span 1",
    "&:hover": {
      scale: "105%",
    },
  },
  userDetailsLabel: {
    whiteSpace: "nowrap",
  },
  userDetailsValue: {
    display: "block",
    lineHeight: 1,
  },
  userDetailsDivider: {
    margin: "12px 0 6px",
    gridColumn: " span 2",
  },
  userDetailsSingleDesc: {
    gridColumn: "span 2",
  },
  userDetailsValueDesc: {
    lineHeight: 1,
    paddingLeft: "4px",
    wordBreak: "break-all",
    border: "1px solid lightgrey",
    "& p": {
      padding: "0px",
      margin: "4px",
    },
  },
  images: {
    gap: "4px",
    display: "flex",
    flexWrap: "wrap",
    gridColumn: " span 2",
  },
  images_logo: { flex: 1 },
  images_cover_image: {
    width: "100%",
  },
  images_contact_person_image: { flex: 1 },
  image: {
    objectFit: "contain",
    background: "#efefefc9",
    border: "1px solid #efefefc9",
    "&:hover": {
      scale: "105%",
    },
  },
  image_logo: {
    height: "100px",
    width: "100px",
  },
  image_contact_person_image: {
    height: "100px",
    width: "100px",
  },
  image_cover_image: {
    flex: 2,
    width: "100%",
    height: "200px",
  },
}));

export const PartnerUserDetailModal = ({ data, onClose }) => {
  const classes = useStyles();
  console.log({ data });
  const selectFields = [
    {
      key: data?.industry?.name,
      label: "company_industry_type",
    },
    {
      key: data?.occupation?.name,
      label: "occupation",
    },
  ];
  return (
    <Box>
      <Typography color="primary" variant="h6">
        <TranslateComp id="partner_detail" />
      </Typography>
      <Divider className={classes.divider} />
      <Box className={classes.body}>
        <Box className={classes.userDetails}>
          {generalFields?.map((i) => (
            <div className={classes.userDetailsSingle} key={i.key}>
              <Typography
                variant="subtitle1"
                className={classes.userDetailsLabel}
              >
                <TranslateComp id={i.label} />
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.userDetailsValue}
              >
                <b>{data[i.key]}</b>
              </Typography>
            </div>
          ))}
          {companyFields?.map((i) => (
            <div key={i.key} className={classes.userDetailsSingle}>
              <Typography
                variant="subtitle1"
                className={classes.userDetailsLabel}
              >
                <TranslateComp id={i.label} />
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.userDetailsValue}
              >
                <b>{data[i.key]}</b>
              </Typography>
            </div>
          ))}
          {selectFields?.map((i) => (
            <div key={i.key} className={classes.userDetailsSingle}>
              <Typography
                variant="subtitle1"
                className={classes.userDetailsLabel}
              >
                <TranslateComp id={i.label} />
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.userDetailsValue}
              >
                <b>{i.key || "-"}</b>
              </Typography>
            </div>
          ))}
          <Divider
            className={classNames(classes.divider, classes.userDetailsDivider)}
          />
          {descFields?.map((i) => (
            <div
              key={i.key}
              className={classNames(
                classes.userDetailsSingle,
                classes.userDetailsSingleDesc
              )}
            >
              <Typography
                variant="subtitle1"
                className={classes.userDetailsLabel}
              >
                <TranslateComp id={i.label} />
              </Typography>
              {data[i.key] ? (
                <Typography
                  variant="body2"
                  component="div"
                  className={classNames(
                    classes.userDetailsValue,
                    classes.userDetailsValueDesc
                  )}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data[i.key],
                    }}
                  />
                </Typography>
              ) : (
                <div>-</div>
              )}
            </div>
          ))}
          <Divider
            className={classNames(classes.divider, classes.userDetailsDivider)}
          />

          <Box className={classes.images}>
            {imageFields.map((i) => (
              <Box key={i.key} className={classes[`images_${i.key}`]}>
                <Typography
                  variant="subtitle1"
                  className={classes.userDetailsLabel}
                >
                  <TranslateComp id={i.label} />
                </Typography>
                <a href={data?.[i.key]} target="_blank">
                  <img
                    src={data?.[i.key]}
                    alt={data?.username}
                    className={classNames(
                      classes.image,
                      classes[`image_${i.key}`]
                    )}
                  />
                </a>
              </Box>
            ))}
          </Box>
          <br />
        </Box>
      </Box>
      <Button
        fullWidth
        color="primary"
        onClick={onClose}
        variant="outlined"
        className={classes.button}
      >
        <TranslateComp id="close" />
      </Button>
    </Box>
  );
};

const TranslateComp = ({ id }) => {
  return (
    <FormattedMessage
      id={id}
      defaultMessage={capitalize(id).replace(/_/g, " ")}
    />
  );
};

import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import PartnerUserReqTable from "./PartnerUserReqTable";
import { ModalComp } from "components/fragments/ModalComp";

import {
  callFetchPartnerUserReq,
  callAcceptRejectPartnerReq,
} from "redux/partnerUser/apiCall";
import { chooseLang } from "utils/myFunc";
import useDebounce from "utils/useDebounce";
import { partnerUserAction } from "redux/partnerUser/action";
import { notificationAction } from "redux/notification/action";

const PartnerUserReqList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const lang = useSelector(({ settings }) => settings.lang);
  const list = useSelector(({ partnerUser }) => partnerUser.list);
  const count = useSelector(({ partnerUser }) => partnerUser.count);
  const loading = useSelector(({ partnerUser }) => partnerUser.loading);

  const [des, setDes] = useState("");
  const [page, setPage] = useState(0);
  const [desErr, setDesErr] = useState("");
  const [dialog, setDialog] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [select, setSelect] = useState("pending");
  const [searchTerm, setSearchTerm] = useState("");

  const debounce = useDebounce(searchTerm, 300);

  useEffect(() => {
    callFetchPartnerUserReq(
      chooseLang(lang),
      {
        limit: perPage,
        name: debounce,
        status: select,
        offset: page * perPage,
      },
      dispatch,
      partnerUserAction
    );
    // eslint-disable-next-line
  }, [page, perPage, select, debounce]);

  const handleOnChange = (e) => {
    if (desErr) setDesErr("");
    setDes(e?.target?.value);
  };

  const handleAcceptRejectUser = (id, idx, val) => {
    setDialog({ id, idx, val });
  };

  const handleAccept = () => {
    if (dialog?.val === "rejected" && !des) {
      let errMsg = intl.formatMessage({
        id: "please_enter_des",
        defaultMessage: "Please enter description",
      });
      setDesErr(errMsg);
      dispatch(
        notificationAction({
          data: {
            message: intl.formatMessage({
              id: `please_correct_err_and_try-again`,
              defaultMessage: `Please correct error and try again`,
            }),
            type: "warning",
            timer: 3000,
          },
        })
      );
      return;
    }

    callAcceptRejectPartnerReq(
      chooseLang(lang),
      dialog?.id,
      dialog?.idx,
      dialog?.val,
      des,
      list,
      dispatch,
      partnerUserAction,
      notificationAction,
      () => {
        setDes("");
        setDialog(false);
      },
      intl
    );
  };

  const handleCloseDialog = () => {
    setDes("");
    setDialog("");
    setDesErr("");
  };
  return (
    <>
      <PartnerUserReqTable
        data={list}
        page={page}
        count={count}
        select={select}
        loading={loading}
        setPage={setPage}
        perPage={perPage}
        setSelect={setSelect}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        acceptRejectUser={handleAcceptRejectUser}
      />
      <ModalComp
        loading={loading}
        onAccept={handleAccept}
        isOpen={Boolean(dialog)}
        handleClose={handleCloseDialog}
        cancel={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
        title={
          <FormattedMessage
            id={
              dialog?.val === "rejected"
                ? "confirmRejectPartnerUserTitle"
                : "confirmAcceptPartnerUserTitle"
            }
            defaultMessage={
              dialog?.val === "rejected"
                ? "Confirm reject partner User"
                : "Confirm accept partner User"
            }
          />
        }
        description={
          <>
            <div>
              <FormattedMessage
                id={
                  dialog?.val === "rejected"
                    ? "enter_reason_for_rejection"
                    : "enter_reason_for_acceptance_opt"
                }
                defaultMessage="Please enter a reason (optional) to confirm your action."
              />
              {/* // change lang acc to accept reject */}
            </div>
            <div>
              <TextField
                fullWidth
                multiline
                value={des}
                minRows={4}
                maxRows={6}
                variant="outlined"
                helperText={desErr}
                error={Boolean(desErr)}
                onChange={handleOnChange}
              />
            </div>
            <div>
              <br />
            </div>
          </>
        }
        accept={
          <FormattedMessage
            id={dialog?.val === "rejected" ? "reject" : "accept"}
            defaultMessage={dialog?.val === "rejected" ? "Reject" : "Accept"}
          />
        }
      />
    </>
  );
};

export default PartnerUserReqList;

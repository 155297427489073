import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserTable from "./UserTable";
import { callFetchUserList } from "redux/user/apiCall";
import { userAction } from "redux/user/action";
import { chooseLang } from "utils/myFunc";
import useDebounce from "utils/useDebounce";

const UserList = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const userList = useSelector(({ user }) => user.userList);
  const loading = useSelector(({ user }) => user.loading);

  const userCount = useSelector(({ user }) => user.userCount);

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  const debounce = useDebounce(searchTerm, 300);

  useEffect(() => {
    callFetchUserList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
        search_query: searchTerm,
      },
      dispatch,
      userAction
    );
    // eslint-disable-next-line
  }, [page, perPage, debounce]);

  return (
    <UserTable
      data={userList}
      type={type}
      setType={setType}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      page={page}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      userCount={userCount}
      loading={loading}
    />
  );
};

export default UserList;

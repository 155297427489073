import React from 'react';
import { Pagination } from '@material-ui/lab';
import styled from 'styled-components';

const PaginationWrapper = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const PaginationComponent = ({ totalCount, page, setPage }) => {
  return (
    <PaginationWrapper
      color="primary"
      count={totalCount}
      page={page + 1}
      onChange={(e, value) => {
        console.log(e, '');
        setPage(value - 1);
      }}
    />
  );
};

export default PaginationComponent;

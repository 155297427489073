import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";

import {
  callDeleteEventSupport,
  callFetchEventSupportList,
} from "redux/event-support/apiCall";
import { eventSupportAction } from "redux/event-support/action";
import EventSupportTable from "./EventSupportTable";

const EventSupportList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const eventSupportList = useSelector(
    ({ eventSupport }) => eventSupport.eventSupportList
  );
  const eventSupportCount = useSelector(
    ({ eventSupport }) => eventSupport.eventSupportCount
  );

  const lang = useSelector(({ settings }) => settings.lang);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchEventSupportList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      eventSupportAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [lang, page, perPage]);

  return (
    <>
      <EventSupportTable
        data={eventSupportList}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        industryCount={eventSupportCount}
        setSearchTerm={setSearchTerm}
        deleteSingleData={(id, idx) => {
          callDeleteEventSupport(
            chooseLang(lang),
            id,
            idx,
            eventSupportList,
            dispatch,
            eventSupportAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default EventSupportList;

/** Main Libraries */
import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/** External components */
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

/** Material UI icons */
import SuccessIcon from "@material-ui/icons/Done";
import WarningIcon from "@material-ui/icons/WarningRounded";
import ErrorIcon from "@material-ui/icons/PriorityHigh";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

/** Local functions/hooks */
/** Local components */

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: theme.spacing(2),
    },
    field: {},
    title: {
      textAlign: "center",
    },
    content: {
      textAlign: "center",
    },
    button: {
      padding: theme.spacing(1, 2),
      margin: theme.spacing(1, 2),

      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1, 0),
      },
      "&:disabled": {
        opacity: "0.5",
        color: "#fff",
        cursor: "not-allowed",
      },
    },
    icon: {
      fontSize: theme.typography.h3.fontSize,
    },
    iconContainer: {
      // background: theme.palette.warning.light,
      color: theme.palette.common.white,
      padding: theme.spacing(2),
      border: `5px solid ${theme.palette.background.paper}`,
      borderRadius: 200,
      top: `-${theme.typography.h3.fontSize}`,
      position: "absolute",
      alignSelf: "center",
    },
    dialogPaper: {
      overflowY: "unset",
      maxWidth: "800px",
      minWidth: theme.spacing(52),
    },
    rtl: {
      direction: "rtl",
    },
    ltr: {
      direction: "ltr",
    },
    contentContainer: {
      overflowY: "auto",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: theme.spacing(0.5),
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "white",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    contentContainerIcon: {
      marginTop: theme.typography.h3.fontSize,
    },
  };
});

export const CustomDialog = ({
  open,
  onClose,
  title,
  ContentComponent,
  IconComponent,
  icon,
  content,
  contentSecondary,
  contentSecondaryStyle,
  confirmText,
  confirmButtonProps,
  confirmButtonStyle,
  confirmOnClick,
  cancelText,
  cancelButtonProps,
  cancelButtonStyle,
  cancelOnClick,
  isLoading,
  primaryColor,
  fullScreen,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  const getPrimaryColor = () => {
    return primaryColor
      ? primaryColor
      : icon === "success"
      ? theme.palette.success.main
      : icon === "error" || icon === "delete"
      ? theme.palette.error.main
      : icon === "warning"
      ? theme.palette.warning.main
      : theme.palette.text.primary;
  };

  const IconWrapper = ({ CustomIcon }) => {
    return (
      <div
        className={classes.iconContainer}
        style={{
          background: getPrimaryColor(),
        }}
      >
        {CustomIcon ? (
          <CustomIcon className={classes.icon} />
        ) : icon === "success" ? (
          <SuccessIcon className={classes.icon} />
        ) : icon === "error" ? (
          <ErrorIcon className={classes.icon} />
        ) : icon === "warning" ? (
          <WarningIcon className={classes.icon} />
        ) : icon === "delete" ? (
          <DeleteIcon className={classes.icon} />
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.container}
      classes={{
        paper: clsx(classes.dialogPaper, classes.ltr),
      }}
      fullScreen={fullScreen}
      {...props}
    >
      {(IconComponent || icon) && !fullScreen && (
        <IconWrapper CustomIcon={IconComponent} />
      )}

      <div
        className={clsx(
          classes.contentContainer,
          IconComponent || icon
            ? classes.contentContainerIcon
            : classes.contentContainerNoIcon
        )}
      >
        {title && (
          <DialogTitle
            className={classes.title}
            style={{
              color: getPrimaryColor(),
            }}
          >
            {title}
          </DialogTitle>
        )}

        {(content || ContentComponent) && (
          <DialogContent>
            <DialogContentText component="div" style={{ textAlign: "center" }}>
              {content}

              {contentSecondary && (
                <DialogContentText
                  component="span"
                  style={{ display: "block", ...contentSecondaryStyle }}
                >
                  {contentSecondary}
                </DialogContentText>
              )}
            </DialogContentText>

            {ContentComponent}
          </DialogContent>
        )}

        {(confirmText || cancelText) && (
          <DialogActions
            style={{
              display: mobileBreakpoint ? "block" : "flex",
            }}
          >
            {confirmText && (
              <Button
                onClick={confirmOnClick}
                color="primary"
                type="button"
                variant="contained"
                fullWidth
                className={clsx(classes.button)}
                {...confirmButtonProps}
                style={{
                  whiteSpace: "no-wrap",
                  margin: mobileBreakpoint
                    ? theme.spacing(1, 0)
                    : theme.spacing(1, 2),
                  [confirmButtonProps?.variant === "outlined"
                    ? "borderColor"
                    : confirmButtonProps?.variant === "text"
                    ? "color"
                    : "backgroundColor"]: getPrimaryColor(),
                  ...confirmButtonStyle,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} thickness={4} />
                ) : (
                  confirmText
                )}
              </Button>
            )}

            {cancelText && (
              <Button
                onClick={cancelOnClick}
                color="default"
                type="button"
                variant="outlined"
                fullWidth
                className={clsx(classes.button)}
                {...cancelButtonProps}
                style={{
                  whiteSpace: "no-wrap",
                  margin: mobileBreakpoint
                    ? theme.spacing(1, 0)
                    : theme.spacing(1, 2),
                  ...cancelButtonStyle,
                }}
              >
                {cancelText}
              </Button>
            )}
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
};

import React, { Fragment } from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import dataMenu from "api/ui/menu";
import { injectIntl } from "react-intl";
import styles from "../appStyles-jss";
import { useContext } from "react";
import { AppContext } from "../../App/ThemeWrapper";
import CustomHeader from "components/CustomComponents/CustomHeader";
import CustomSidebar from "components/CustomComponents/CustomSidebar";

const LeftSidebarLayout = (props) => {
  const { classes, children, pageLoaded } = props;
  const { sidebarOpen } = useContext(AppContext);
  return (
    <Fragment>
      <CustomHeader />
      <CustomSidebar dataMenu={dataMenu} />
      <main
        className={classNames(
          classes.content,
          !sidebarOpen ? classes.contentPaddingLeft : ""
        )}
        id="mainContent"
      >
        <section
          className={classNames(classes.mainWrap, classes.sidebarLayout)}
        >
          {!pageLoaded && (
            <img
              src="/images/spinner.gif"
              alt="spinner"
              className={classes.circularProgress}
            />
          )}
          <Fade
            in={pageLoaded}
            mountOnEnter
            unmountOnExit
            {...(pageLoaded ? { timeout: 700 } : {})}
          >
            <div className={!pageLoaded ? classes.hideApp : ""}>{children}</div>
          </Fade>
        </section>
      </main>
    </Fragment>
  );
};

LeftSidebarLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  loadTransition: PropTypes.func.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  place: PropTypes.string.isRequired,
  titleException: PropTypes.array.isRequired,
  handleOpenGuide: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  isLogin: PropTypes.bool,
  userAttr: PropTypes.object.isRequired,
};

export default withStyles(styles)(injectIntl(LeftSidebarLayout));

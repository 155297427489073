import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import PaginationComponent from "components/CustomComponents/Pagination";
import { muiTableLabels } from "utils/muiTableLabels";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  popper: {
    zIndex: 1000,
  },
  popperPaper: {
    padding: theme.spacing(2),
  },
  formDiv: {
    width: "325px",
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelIconButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  searchTextField: {
    width: "50%",
    borderRadius: "10px",
  },
}));

const MeetingTable = ({
  data,
  title,
  page,
  setPage,
  perPage,
  setPerPage,
  meetingCount,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const columns = [
    {
      name: (
        <FormattedMessage id="meeting_title" defaultMessage="Meeting Title" />
      ),
      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage
          id="meeting_description"
          defaultMessage="Meeting Description"
        />
      ),

      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Box width="200px">{value}</Box>;
        },
      },
    },
    {
      name: (
        <FormattedMessage
          id="meeting_category"
          defaultMessage="Meeting Category"
        />
      ),

      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage id="meeting_time" defaultMessage="Meeting Time" />
      ),

      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("MMMM Do YYYY, h:mm: a")}</>;
        },
      },
    },
    {
      name: (
        <FormattedMessage id="company_name" defaultMessage="Company Name" />
      ),
      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage
          id="company_name_katakana"
          defaultMessage="Company Name Katakana"
        />
      ),

      options: {
        filter: true,
      },
    },
    {
      name: (
        <FormattedMessage
          id="contact_person_name"
          defaultMessage="Contact Person Name"
        />
      ),

      options: {
        filter: true,
      },
    },

    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <></>;
        },
      },
    },
  ];
  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const tempData = data.map((singleData) => [
        singleData?.topic,
        singleData?.agenda,
        singleData?.category,
        singleData?.time,
        singleData?.user?.company_name,
        singleData?.user?.company_name_katakana,
        singleData?.user?.contact_name,

        singleData.id,
      ]);
      setColumnData(tempData);
    }
  }, [data]);

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    page: page,
    count: meetingCount,
    onChangePage: (currentPage) => setPage(currentPage),
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsPerPage: perPage,
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    serverSide: true,
    print: false,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
    selectableRows: false,
    customFooter: () => {
      return (
        <PaginationComponent
          totalCount={Math.ceil(meetingCount / 10)}
          page={page}
          setPage={setPage}
        />
      );
    },
    customToolbar: () => {
      return (
        <>
          {/* <CustomSearchTextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}
          {/* &nbsp; &nbsp; &nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleClick('bottom-end', e);
              setType('add');
              setDrawerType('property');
            }}
          >
            Add {title}
          </Button> */}
        </>
      );
    },
    textLabels: {
      ...muiTableLabels(intl),
    },
  };
  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "meeting_list",
          defaultMessage: "Meeting List",
        })}
        data={columnData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

PropTypes.MeetingTable = {
  title: PropTypes.string.isRequired,
};

export default MeetingTable;

import {
  patchWithHeader,
  getWithHeader,
  deleteWithHeader,
} from "api/myApi/ApiFunc";
import { ReportLink, UserLink } from "api/myApi/link";
import { feedbackAction } from "./action";

export const callFetchFeedback = (lang, params, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  getWithHeader(
    `${UserLink(lang)}/admin-report`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          feedbackList: response.data.rows,
          feedbackCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callDeleteFeedback = (
  lang,
  id,
  idx,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  deleteWithHeader(
    `${UserLink(lang)}/report/${id}`,
    "",
    (response) => {
      const tempList = [...dataList];
      tempList.splice(idx, 1);
      dispatch(
        reducer({
          loading: false,
          feedbackList: [...tempList],
        })
      );
      dispatch(
        notification({
          data: {
            message: "Successfully Deleted",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
    }
  );
};

export const callPatchFeedback = (
  lang,
  id,
  idx,
  status,
  dataList,
  dispatch,
  reducer,
  notification
) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  patchWithHeader(
    `${UserLink(lang)}/report/${id}`,
    { status },
    (response) => {
      const tempList = [...dataList];
      tempList[idx] = {
        ...tempList[idx],
        status,
        // event_category_translations: [
        //   { name: response.data.name_en, language: 'en' },
        //   { name: response.data.name_jp, language: 'jp' },
        // ],
      };
      dispatch(
        reducer({
          loading: false,
          feedbackList: tempList,
        })
      );
      dispatch(
        notification({
          data: {
            message: "Status Changed Successfully",
            type: "success",
            timer: 3000,
          },
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        notification({
          data: {
            message: payloadMsg,
            type: "error",
            timer: 3000,
          },
        })
      );
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callFetchReport =
  ({ lang, ...params }, successFunc, errorFunc) =>
  async (dispatch) => {
    dispatch(
      feedbackAction({
        loading: true,
      })
    );
    getWithHeader(
      `${ReportLink(lang)}/`,
      { params },
      (res) => {
        dispatch(
          feedbackAction({
            loading: false,
            reportList: res.data.rows,
            reportCount: res.data.count,
          })
        );
        if (successFunc) successFunc(res?.data);
      },
      (error) => {
        let errMsg = "エラー が発生しました！もう一度お試しください";
        let payloadMsg = error?.response?.data?.message ?? errMsg;
        console.error(payloadMsg, "payload-msg");
        dispatch(
          feedbackAction({
            error: payloadMsg,
            loading: false,
          })
        );
        if (errorFunc) errorFunc();
      }
    );
  };

export const callDeleteReportedPost =
  ({ lang, ...data }, successFunc, errorFunc) =>
  async (dispatch) => {
    dispatch(
      feedbackAction({
        loading: true,
      })
    );
    patchWithHeader(
      `${ReportLink(lang)}/delete-by-admin`,
      data,
      (res) => {
        dispatch(
          feedbackAction({
            loading: false,
          })
        );
        if (successFunc) successFunc(res?.data);
      },
      (error) => {
        let errMsg = "エラー が発生しました！もう一度お試しください";
        let payloadMsg = error?.response?.data?.message ?? errMsg;
        console.error(error, payloadMsg, "payload-msg");
        dispatch(
          feedbackAction({
            error: payloadMsg,
            loading: false,
          })
        );
        if (errorFunc) errorFunc(payloadMsg);
      }
    );
  };

import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";

import EventSupportGroupTable from "./EventSupportGroupTable";
import {
  callAddEventSupportGroup,
  callDeleteEventSupportGroup,
  callEditEventSupportGroup,
  callFetchEventSupportGroupList,
} from "redux/event-support/apiCall";
import { eventSupportAction } from "redux/event-support/action";

const EventSupportGroupList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const eventSupportGroupList = useSelector(
    ({ eventSupport }) => eventSupport.eventSupportGroupList
  );
  const eventSupportGroupCount = useSelector(
    ({ eventSupport }) => eventSupport.eventSupportGroupCount
  );

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchEventSupportGroupList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      eventSupportAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleSubmit = (val) => {
    const { idIdx, ...rest } = val;
    if (type === "edit") {
      callEditEventSupportGroup(
        chooseLang(lang),
        idIdx.id,
        idIdx.idx,
        rest,
        eventSupportGroupList,
        dispatch,
        eventSupportAction,
        () => setOpen(false),
        notificationAction
      );
    } else {
      callAddEventSupportGroup(
        chooseLang(lang),
        rest,
        eventSupportGroupList,
        dispatch,
        eventSupportAction,
        () => setOpen(false),
        notificationAction
      );
    }
  };

  return (
    <>
      <EventSupportGroupTable
        data={eventSupportGroupList}
        handleSubmit={handleSubmit}
        type={type}
        setType={setType}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        industryCount={eventSupportGroupCount}
        setSearchTerm={setSearchTerm}
        deleteSingleData={(id, idx) => {
          callDeleteEventSupportGroup(
            chooseLang(lang),
            id,
            idx,
            eventSupportGroupList,
            dispatch,
            eventSupportAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default EventSupportGroupList;

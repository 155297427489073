import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

const LanguageProvider = (props) => {
  const { messages, children } = props;
  const locale = useSelector(({ settings }) => settings.lang);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  messages: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;

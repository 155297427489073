import React, { useState } from "react";
import {
  makeStyles,
  Button,
  FormControl,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
// import IndustryTable from './IndustryTable';
import { MUIForms, Field, TextFieldMUI } from "components/Forms/MUIForms";
import PaperBlock from "components/fragments/PaperBlock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { required } from "utils/Validation";
import { callChangePassword } from "redux/login/apiCall";
import { loginAction } from "redux/login/action";
import { notificationAction } from "redux/notification/action";
import { useIntl, FormattedMessage } from "react-intl";
import { chooseLang } from "utils/myFunc";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "400px",
    margin: "auto",
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [initialPassword, setInitialPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const passwordLoading = useSelector(({ login }) => login.passwordLoading);

  const lang = useSelector(({ settings }) => settings.lang);

  const handleSubmit = (val) => {
    if (confirmPassword && !confirmPasswordError) {
      callChangePassword(
        chooseLang(lang),
        val,
        dispatch,
        loginAction,
        () => setInitialPassword(""),
        notificationAction
      );
    } else {
      setConfirmPasswordError("required");
    }
  };

  return (
    <PaperBlock
      title={intl.formatMessage({
        id: "change_password",
        defaultMessage: "Change Password",
      })}
    >
      <MUIForms
        className={classes.form}
        onSubmit={handleSubmit}
        initialValues={initialPassword}
      >
        <Field
          name="old_password"
          label={intl.formatMessage({
            id: "old_password",
            defaultMessage: "Old Password",
          })}
          variant="outlined"
          component={TextFieldMUI}
          type={showOldPassword ? "text" : "password"}
          margin="normal"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  edge="end"
                >
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          validate={[required]}
        />
        <Field
          name="password"
          label={intl.formatMessage({
            id: "new_password",
            defaultMessage: "New Password",
          })}
          variant="outlined"
          component={TextFieldMUI}
          margin="normal"
          fullWidth
          renderValue={(value) => setNewPassword(value)}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          validate={[required]}
        />
        <TextField
          name="password"
          label={intl.formatMessage({
            id: "confirm_new_password",
            defaultMessage: "Confirm New Password",
          })}
          type={showConfirmPassword ? "text" : "password"}
          variant="outlined"
          value={confirmPassword}
          margin="normal"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            if (newPassword !== e.target.value) {
              setConfirmPasswordError("Password did not match");
            } else {
              setConfirmPasswordError("");
            }
          }}
          error={Boolean(confirmPasswordError)}
          helperText={confirmPasswordError && confirmPasswordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  edge="end"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <FormControl margin="normal" fullWidth>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={passwordLoading}
          >
            <FormattedMessage id="save" defaultMessage="Save" />
            {passwordLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </FormControl>
      </MUIForms>
    </PaperBlock>
  );
};

export default ChangePassword;

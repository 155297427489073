import { getWithHeader } from 'api/myApi/ApiFunc';
import { MeetingLink } from 'api/myApi/link';

export const callFetchMeetingList = (lang, params, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithHeader(
    `${MeetingLink(lang)}/admin-list`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          meetingList: response.data.rows,
          meetingCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;

      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
    }
  );
};

export const callFetchTotalMeeting = (lang, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );

  getWithHeader(
    `${MeetingLink(lang)}/admin-count`,
    '',
    (response) => {
      dispatch(
        reducer({
          loading: false,
          totalMeeting: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = 'There was error !! Please Try Again';
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
    }
  );
};

import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  callFetchReport,
  callDeleteReportedPost,
} from "redux/feedback/apiCall";
import { chooseLang } from "utils/myFunc";
import useDebounce from "utils/useDebounce";
import UserReportTable from "./UseReportTable";
import { notificationAction } from "redux/notification/action";

const UserReports = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const lang = useSelector(({ settings }) => settings.lang);
  const loading = useSelector(({ feedback }) => feedback.loading);
  const list = useSelector(({ feedback }) => feedback.reportList);
  const count = useSelector(({ feedback }) => feedback.reportCount);

  const [page, setPage] = useState(0);
  const [type, setType] = useState("add");
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [closeDialogs, setCloseDialogs] = useState(false);
  const debounce = useDebounce(searchTerm, 300);

  useEffect(() => {
    callFetchReports();
    // eslint-disable-next-line
  }, [page, perPage, debounce]);

  const callFetchReports = () =>
    dispatch(
      callFetchReport(
        {
          limit: perPage,
          offset: page * perPage,
          lang: chooseLang(lang),
          search_query: debounce,
          // type: match.params.id === "improvements" ? "feedback" : "bug",
        },
        () => setCloseDialogs(false)
      )
    );
  const handleDeleteReportedPostSuccess = () => {
    dispatch(
      notificationAction({
        data: {
          message: intl.formatMessage({
            id: "delete_reported_post_success",
            defaultMessage: "Successfully deleted reported post",
          }),
          type: "success",
          timer: 3000,
        },
      })
    );
    callFetchReports();
    setCloseDialogs(true);
  };

  const handleDeleteReportedPostError = (e) => {
    dispatch(
      notificationAction({
        data: {
          message:
            e ||
            intl.formatMessage({
              id: "delete_reported_post_error",
              defaultMessage: "Error deleting reported post",
            }),
          type: "error",
          timer: 3000,
        },
      })
    );
  };
  const onDeletePostConfirm = (d) => {
    dispatch(
      callDeleteReportedPost(
        d,
        handleDeleteReportedPostSuccess,
        handleDeleteReportedPostError
      )
    );
  };

  return (
    <UserReportTable
      data={list}
      type={type}
      page={page}
      count={count}
      loading={loading}
      setType={setType}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      searchTerm={searchTerm}
      title={match.params.id}
      closeDialogs={closeDialogs}
      setSearchTerm={setSearchTerm}
      deleteSingleData={onDeletePostConfirm}
    />
  );
};

export default UserReports;

import React from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Button, IconButton, Typography } from "@material-ui/core";

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle(width) {
  // const top = 50 + rand();
  // const left = 50 + rand();
  const top = 50;
  const left = 50;
  // console.log(height, width);
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width,
    // height
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    borderRadius: theme.spacing(1),
    backgroundColor: "#F7F7F7",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeIconButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    marginBottom: 2,
  },
  childrenDiv: {
    marginTop: "10px",
  },
  textAlignCenter: { textAlign: "center" },
  button: {
    minWidth: 110,
    borderRadius: theme.spacing(0.5),
  },
}));

export const ModalComp = ({
  isOpen,
  cancel,
  accept,
  onAccept,
  handleClose,
  width = "500px",
  loading = false,
  title = "modal-title",
  description = "modal-description",
  // height = '400px',
}) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle(width));

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={description}
    >
      <div style={modalStyle} className={classes.paper}>
        <IconButton className={classes.closeIconButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <div className={classes.childrenDiv}>
          <Box>
            <Typography component="p" className={classes.textAlignCenter}>
              <strong>{title}</strong>
            </Typography>
            <br />
            <Typography component="p">{description}</Typography>
            <hr />
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              {cancel && (
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  className={classes.button}
                >
                  {cancel}
                </Button>
              )}
              {accept && (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  className={classes.button}
                  onClick={onAccept && onAccept}
                >
                  {loading && <CircularProgress size={20} color="secondary" />}
                  &nbsp;{accept}
                </Button>
              )}
            </Box>
          </Box>
        </div>
      </div>
    </Modal>
  );
};

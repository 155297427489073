import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notificationAction } from "redux/notification/action";
import { chooseLang } from "utils/myFunc";
import OccupationTable from "./OccupationTable";
import {
  callAddOccupation,
  callDeleteOccupation,
  callEditOccupation,
  callFetchOccupationList,
} from "redux/occupation/apiCall";
import { occupationAction } from "redux/occupation/action";

const OccupationList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const occupationList = useSelector(
    ({ occupation }) => occupation.occupationList
  );
  const occupationCount = useSelector(
    ({ occupation }) => occupation.occupationCount
  );

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    callFetchOccupationList(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
      },
      dispatch,
      occupationAction,
      notificationAction
    );
    // eslint-disable-next-line
  }, [page, perPage]);

  const handleSubmit = (val) => {
    const { idIdx, ...rest } = val;
    if (type === "edit") {
      callEditOccupation(
        chooseLang(lang),
        idIdx.id,
        idIdx.idx,
        rest,
        occupationList,
        dispatch,
        occupationAction,
        () => setOpen(false),
        notificationAction
      );
    } else {
      callAddOccupation(
        chooseLang(lang),
        rest,
        occupationList,
        dispatch,
        occupationAction,
        () => setOpen(false),
        notificationAction
      );
    }
  };

  return (
    <>
      <OccupationTable
        data={occupationList}
        handleSubmit={handleSubmit}
        type={type}
        setType={setType}
        title={"Occupation"}
        open={open}
        setOpen={setOpen}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        searchTerm={searchTerm}
        industryCount={occupationCount}
        setSearchTerm={setSearchTerm}
        deleteSingleData={(id, idx) => {
          callDeleteOccupation(
            chooseLang(lang),
            id,
            idx,
            occupationList,
            dispatch,
            occupationAction,
            notificationAction
          );
        }}
      />
    </>
  );
};

export default OccupationList;

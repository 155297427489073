import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, makeStyles, CircularProgress } from "@material-ui/core";

import PaginationComponent from "components/CustomComponents/Pagination";
import CustomSearchTextField from "components/CustomComponents/CustomSearchTextFIeld";
import { muiTableLabels } from "utils/muiTableLabels";

const useStyles = makeStyles((theme) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  popper: {
    zIndex: 1000,
  },
  popperPaper: {
    padding: theme.spacing(2),
  },
  formDiv: {
    width: "325px",
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelIconButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  searchTextField: {
    width: "50%",
    borderRadius: "10px",
  },
  image: {
    width: "60px",
    height: "60px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  btnGrp: {
    display: "flex",
    gap: "8px",
  },
  viewButton: {
    minWidth: 110,
    borderRadius: 0,
  },
  userAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const PaymentWithdrawReqTable = ({
  data,
  page,
  count,
  setPage,
  perPage,
  loading,
  searchTerm,
  setPerPage,
  setSearchTerm,
  verifyReq,
  releasePayment,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const columns = [
    {
      name: <FormattedMessage id="name" defaultMessage="Name" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="email" defaultMessage="Email" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="contactNo" defaultMessage="Contact No." />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="requestDate" defaultMessage="Request date" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="amount" defaultMessage="Amount" />,
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: <FormattedMessage id="actions" defaultMessage="Actions" />,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          return (
            <div className={classes.btnGrp}>
              {/* <Button
                variant="contained"
                className={classes.viewButton}
                onClick={() => {
                  verifyReq(data[index].id, index, value);
                }}
              >
                <FormattedMessage id="verify" defaultMessage="Verify" />
              </Button>
              &emsp; */}
              <Button
                color="primary"
                variant="contained"
                className={classes.viewButton}
                onClick={() => {
                  releasePayment(data[index].id, index, "completed");
                }}
              >
                <FormattedMessage
                  id="releasePayment"
                  defaultMessage="Release Payment"
                />
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className={classes.viewButton}
                onClick={() => {
                  releasePayment(data[index].id, index, "rejected");
                }}
              >
                <FormattedMessage
                  id="rejectPayment"
                  defaultMessage="Reject Payment"
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    count,
    page: page,
    print: false,
    filter: false,
    search: false,
    download: false,
    serverSide: true,
    viewColumns: false,
    rowsPerPage: perPage,
    selectableRows: false,
    responsive: "stacked",
    filterType: "dropdown",
    rowsPerPageOptions: [10, 20, 50, 100],
    onChangePage: (currentPage) => setPage(currentPage),
    onChangeRowsPerPage: (numberOfRows) => setPerPage(numberOfRows),
    customFooter: () => (
      <PaginationComponent
        totalCount={Math.ceil(count / 10)}
        page={page}
        setPage={setPage}
      />
    ),
    customToolbar: () => (
      <CustomSearchTextField
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    ),
    textLabels: {
      ...muiTableLabels(intl),
      body: {
        noMatch: loading ? (
          <CircularProgress size={30} />
        ) : (
          intl.formatMessage({
            id: "muiTableLabels.body.noMatch",
            defaultMessage: "Sorry, no matching records found",
          })
        ),
      },
    },
  };
  return (
    <div className={classes.table}>
      <MUIDataTable
        title={intl.formatMessage({
          id: "withdrawRequests",
          defaultMessage: "Withdraw requests",
        })}
        columns={columns}
        options={options}
        data={
          data?.map((i) => [
            i?.user_slug,
            i?.email,
            i?.country_code + i?.phone,
            i?.created_at ? moment(i?.created_at).format("DD MMM YYYY") : "",
            i?.amount,
            i?.status,
            i?.id,
          ]) || []
        }
      />
    </div>
  );
};

PropTypes.PaymentWithdrawReqTable = {
  title: PropTypes.string.isRequired,
};

export default PaymentWithdrawReqTable;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import PaymentWithdrawHisTable from "./PaymentWithdrawHisTable";

import { chooseLang } from "utils/myFunc";
import useDebounce from "utils/useDebounce";
import { paymentAction } from "redux/payment/action";
import { callFetchPaymentWithdrawReq } from "redux/payment/apiCall";

const PaymentWithdrawHisList = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const loading = useSelector(({ payment }) => payment.loading);
  const list = useSelector(({ payment }) => payment.paymentWithdrawReqList);
  const count = useSelector(({ payment }) => payment.paymentWithdrawReqCount);

  const lang = useSelector(({ settings }) => settings.lang);
  const [type, setType] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  const debounce = useDebounce(searchTerm, 300);

  useEffect(() => {
    callFetchPaymentWithdrawReq(
      chooseLang(lang),
      {
        offset: page * perPage,
        limit: perPage,
        search_query: debounce,
      },
      dispatch,
      paymentAction
    );
    // eslint-disable-next-line
  }, [page, perPage, debounce]);

  return (
    <PaymentWithdrawHisTable
      data={list}
      type={type}
      page={page}
      count={count}
      loading={loading}
      setType={setType}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    />
  );
};

export default PaymentWithdrawHisList;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import styles from "components/fragments/Header/header-jss";
import { InputLabel } from "@material-ui/core";

const withStyles = makeStyles((theme) => styles(theme));

const Toggle = ({ value, onToggle, values, messages }) => {
  const intl = useIntl();
  const classes = withStyles();
  const [lang, setLang] = useState(value);
  const handleChange = (event) => {
    setLang(event.target.value);
    onToggle(event);
  };
  return (
    <form>
      <FormControl variant="outlined" margin="dense">
        <InputLabel>
          <FormattedMessage
            id="change_language"
            defaultMessage="Change Language"
          />
        </InputLabel>
        <Select
          className={classes.inputLang}
          value={lang}
          name="lang"
          onChange={handleChange}
          variant="outlined"
          margin="dense"
          label={intl.formatMessage({
            id: "change_language",
            defaultMessage: "Change Language",
          })}
        >
          {values &&
            values.map((val) => (
              <MenuItem key={val} className={classes.langItem} value={val}>
                <i className={val} />
                {messages[val] ? intl.formatMessage(messages[val]) : val}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </form>
  );
};

Toggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
};

export default Toggle;

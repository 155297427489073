export const muiTableLabels = (intl) => ({
  body: {
    noMatch: intl.formatMessage({
      id: "muiTableLabels.body.noMatch",
      defaultMessage: "Sorry, no matching records found",
    }),
    loadingEntity: intl.formatMessage({
      id: "muiTableLabels.body.loadingEntity",
      defaultMessage: "Loading... Please wait....",
    }),
  },
  pagination: {
    next: intl.formatMessage({
      id: "muiTableLabels.pagination.next",
      defaultMessage: "Next Page",
    }),
    previous: intl.formatMessage({
      id: "muiTableLabels.pagination.previous",
      defaultMessage: "Previous Page",
    }),
    rowsPerPage: intl.formatMessage({
      id: "muiTableLabels.pagination.rowsPerPage",
      defaultMessage: "Rows per page:",
    }),
    displayRows: intl.formatMessage({
      id: "muiTableLabels.pagination.displayRows",
      defaultMessage: "of",
    }),
  },
  toolbar: {
    search: intl.formatMessage({
      id: "muiTableLabels.toolbar.search",
      defaultMessage: "Search",
    }),
    downloadCsv: intl.formatMessage({
      id: "muiTableLabels.toolbar.downloadCsv",
      defaultMessage: "Download CSV",
    }),
    print: intl.formatMessage({
      id: "muiTableLabels.toolbar.print",
      defaultMessage: "Print",
    }),
    viewColumns: intl.formatMessage({
      id: "muiTableLabels.toolbar.viewColumns",
      defaultMessage: "View Columns",
    }),
    filterTable: intl.formatMessage({
      id: "muiTableLabels.toolbar.filterTable",
      defaultMessage: "Filter Table",
    }),
  },
  filter: {
    all: intl.formatMessage({
      id: "muiTableLabels.filter.all",
      defaultMessage: "All",
    }),
    title: intl.formatMessage({
      id: "muiTableLabels.filter.title",
      defaultMessage: "FILTERS",
    }),
    reset: intl.formatMessage({
      id: "muiTableLabels.filter.reset",
      defaultMessage: "RESET",
    }),
  },
  viewColumns: {
    title: intl.formatMessage({
      id: "muiTableLabels.viewColumns.title",
      defaultMessage: "Show Columns",
    }),
    titleAria: intl.formatMessage({
      id: "muiTableLabels.viewColumns.titleAria",
      defaultMessage: "Show/Hide Table Columns",
    }),
  },
  selectedRows: {
    text: intl.formatMessage({
      id: "muiTableLabels.selectedRows.text",
      defaultMessage: "rows(s) selected",
    }),
    delete: intl.formatMessage({
      id: "muiTableLabels.selectedRows.delete",
      defaultMessage: "Delete",
    }),
    deleteAria: intl.formatMessage({
      id: "muiTableLabels.selectedRows.deleteAria",
      defaultMessage: "Delete Selected Rows",
    }),
  },
});

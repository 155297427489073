import styled from "styled-components";
import CKEditor from "@ckeditor/ckeditor5-react";
import React from "react";
import ClassicEditor from "ckeditor5-custom-build-image-upload";
import { FormHelperText } from "@material-ui/core";

const EditorWrapper = styled.div`
  .ck-reset {
    border: ${({ error }) => (error ? "1px solid #f44336" : "unset")};
    border-radius: 3px;
  }
  .ck .ck-content {
    height: ${({ height }) => (height ? height : "10rem")};
  }
  a {
    color: #4599ff;
  }
  .ck-editor__editable_inline {
    border: 1px solid #8080804f !important;
    height: ${({ height }) => (height ? height : "10rem")};
  }
  .error {
    margin-left: 16px;
  }
`;

export const CkEditor = ({ value, onChange, error, height, ...rest }) => {
  return (
    <EditorWrapper height={height} error={error}>
      <CKEditor
        editor={ClassicEditor}
        {...rest}
        data={value}
        onChange={(_event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        onInit={() => {}}
        config={{
          language: "en",
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "underline",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "indent",
              "outdent",
              "|",
              "blockQuote",
              "insertTable",
              "undo",
              "redo",
            ],
          },
          table: {
            contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
          },
        }}
      />
      {error && (
        <FormHelperText error className="error">
          {error}
        </FormHelperText>
      )}
    </EditorWrapper>
  );
};

import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  fade,
} from "@material-ui/core";
import LocaleToggle from "containers/LocaleToggle";
const useStyles = makeStyles((theme) => ({
  myAppBar: {
    //   position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(12),
  },
  search: {
    marginLeft: theme.spacing(5),
    position: "relative",
    borderRadius: "30px",
    border: "1px solid #e8edf2",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(18),
      width: "auto",
    },
  },
  searchIcon: {
    right: "0",
    top: "0",
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchRoot: {
    color: "inherit",
  },
  searchInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const CustomHeader = () => {
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      color="inherit"
      className={classes.myAppBar}
      elevation={0}
    >
      <Toolbar className={classes.toolBar}>
        <Typography variant="h6" className={classes.title}>
          skysales
        </Typography>
        {/* <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          // onClick={handleDrawerClose}
        >
          <MenuIcon />
        </IconButton> */}
        {/* {open ? (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerClose}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <CloseIcon />
          </IconButton>
        )} */}

        {/* <div className={classes.search}>
          <InputBase
            placeholder="Search Here..."
            classes={{ root: classes.searchRoot, input: classes.searchInput }}
          />
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
        </div> */}
        <LocaleToggle />
      </Toolbar>
    </AppBar>
  );
};

export default CustomHeader;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import { deleteImage } from '../../../api/myApi/uploadImages';

const PicturesChooserWrapper = styled.div`
  justify-content: start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    width: 100px;
    height: 100px;
    padding: 10px;
    border: 1px dotted #aaa;
    border-radius: 10px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    img {
      border-radius: 10px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .delete {
      position: absolute;
      top: -2px;
      right: 2px;
      font-size: 20px;
      cursor: pointer;
      color: #e62a2a;
    }

    .feature {
      position: absolute;
      left: 2px;
      top: -10px;
      font-size: 30px;
      cursor: pointer;
      color: #eee;
    }

    .star {
      color: gold;
    }
  }

  .no-pic {
    padding: 0 20px;
    color: #03a9f4;
  }
`;
const PicturesChooser = ({
  value,
  imageList,
  length,
  setStarValue,
  showStar = false,
  starValue,
  disabled = false,
}) => {
  const [star, setStar] = useState(starValue);

  useEffect(() => {
    setStarValue(star);
    // eslint-disable-next-line
  }, [star]);
  const deleteFile = (a) => {
    // if (value[a].includes("https://sherpa-chat-files.s3.amazonaws.com/")) {
    //   deleteImage(value[a]);
    // }

    if (a === star) setStar();
    if (a < star) setStar(star - 1);
    if (a < length) {
      // addnewLength(length - 1);
    }
    value.splice(a, 1);
    // valueFiles.splice(a, 1);
    imageList(value);
    // imageFileList(valueFiles);
  };

  if (value.length !== 0) {
    return (
      <PicturesChooserWrapper>
        {value.map((src, key) => (
          <div key={key}>
            <img alt="" src={src?.blob} />
            {!disabled && (
              <span className="delete" onClick={() => deleteFile(key)}>
                ✘
              </span>
            )}
            {showStar && (
              <span
                className={`feature ${star === key ? 'star' : ''} `}
                onClick={() => {
                  setStar(key);
                }}
              >
                ★
              </span>
            )}
          </div>
        ))}
      </PicturesChooserWrapper>
    );
  }
  return (
    <PicturesChooserWrapper>
      {' '}
      <span className="no-pic">No pictures selected yet !!!</span>
    </PicturesChooserWrapper>
  );
};

export default PicturesChooser;

import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { NavLink, useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import Chip from "@material-ui/core/Chip";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { injectIntl, FormattedMessage } from "react-intl";
import Icon from "@material-ui/core/Icon";
import messages from "api/ui/menuMessages";
import styles from "./sidebar-jss";
import { AppContext } from "containers/App/ThemeWrapper";
import menuList from "api/ui/menu";
import { callLogout } from "redux/login/apiCall";
import { useDispatch } from "react-redux";
import { loginAction } from "redux/login/action";
import { notificationAction } from "redux/notification/action";

const MainMenu = (props) => {
  const { classes, intl } = props;
  const dispatch = useDispatch();

  const { subMenuOpen, menuFunction } = useContext(AppContext);
  const history = useHistory();

  const getMenus = (menuArray) =>
    menuArray.map((item, index) => {
      if (item.child) {
        return (
          <div key={index.toString()}>
            <ListItem
              button
              className={classNames(
                classes.head,
                item.icon ? classes.iconed : "",
                subMenuOpen?.indexOf(item.key) > -1 ? classes.opened : ""
              )}
              onClick={() => menuFunction(item.key, item.keyParent)}
            >
              {item.icon && (
                <ListItemIcon className={classes.icon}>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
              )}
              <ListItemText
                classes={{ primary: classes.primary }}
                variant="inset"
                primary={intl.formatMessage({
                  id: item.key,
                  defaultMessage: item.name,
                })}
                onClick={() => history.push(item.link)}
              />
              {item?.child?.length > 0 && (
                <>
                  {subMenuOpen?.indexOf(item.key) > -1 ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </>
              )}
            </ListItem>
            <Collapse
              component="div"
              className={classNames(
                classes.nolist,
                item.keyParent ? classes.child : "",
                classes.child
              )}
              in={
                item?.child?.length > 0 && subMenuOpen?.indexOf(item.key) > -1
              }
              timeout="auto"
              unmountOnExit
            >
              <List className={classes.dense} component="nav" dense>
                {getMenus(item.child, "key")}
              </List>
            </Collapse>
          </div>
        );
      }
      if (item.title) {
        return (
          <ListSubheader
            disableSticky
            key={index.toString()}
            component="div"
            className={classes.title}
          >
            {messages[item.key] !== undefined ? (
              <FormattedMessage {...messages[item.key]} />
            ) : (
              item.name
            )}
          </ListSubheader>
        );
      }
      return (
        <ListItem
          key={index.toString()}
          button
          exact
          className={classes.nested}
          activeClassName={classes.active}
          component={NavLink}
          to={item.link}
        >
          <ListItemText
            classes={{ primary: classes.primary }}
            variant="inset"
            primary={intl.formatMessage({
              id: item.key,
              defaultMessage: item.name,
            })}
          />
          {item.badge && (
            <Chip
              color="primary"
              label={item.badge}
              className={classes.badge}
            />
          )}
        </ListItem>
      );
    });
  return (
    <div className={classes.leftMainMenu}>
      {getMenus(menuList)}
      <ListItem
        button
        className={classNames(classes.head, classes.iconed)}
        onClick={() =>
          callLogout(
            dispatch,
            loginAction,
            notificationAction,
            () => window.location.reload(),
            history
          )
        }
      >
        <ListItemIcon className={classes.icon}>
          <Icon>exit_to_app</Icon>
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.primary }}
          variant="inset"
          primary={intl.formatMessage({
            id: "logout",
            defaultMessage: "Logout",
          })}
        />
      </ListItem>
    </div>
  );
};

export default withStyles(styles)(injectIntl(MainMenu));

import { getWithHeader } from "api/myApi/ApiFunc";
import { UserLink } from "api/myApi/link";

export const callFetchUserList = (lang, params, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  getWithHeader(
    `${UserLink(lang)}/list-admin`,
    { params },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          userList: response.data.rows,
          userCount: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callFetchSingleUser = (lang, id, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  getWithHeader(
    `${UserLink(lang)}/by-admin/${id}`,
    "",
    (response) => {
      dispatch(
        reducer({
          loading: false,
          singleUser: response.data.user,
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

export const callFetchTotalUser = (lang, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  getWithHeader(
    `${UserLink(lang)}/total-user`,
    "",
    (response) => {
      dispatch(
        reducer({
          loading: false,
          totalUser: response.data.count,
        })
      );
    },
    (error) => {
      let errMsg = "There was error !! Please Try Again";
      let payloadMsg = error?.response?.data?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
        })
      );
      // message('error', Object.values(payloadMsg)[0]);
    }
  );
};

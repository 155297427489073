import Axios from "axios";
import { store } from "redux/configureStore";

const dev = {
  fe: "http://52.68.1.178",
  base: "http://52.68.1.178",
  partner: "http://52.68.1.178",
};

const prod = {
  fe: "https://skysales.jp",
  base: "https://api.skysales.jp",
  partner: "https://partner.api.skysales.jp",
};

const local = "http://localhost";

const checkIsDev = () => process.env.REACT_APP_ENV === "development";

const generateURL = ({
  key = "base",
  port = "3008",
  isLocal = false,
  apiVersion = "v1",
  apiPrefix = "api",
  isDev = checkIsDev(),
}) => {
  const baseEndpoint = isDev
    ? `${isLocal ? local : dev[key]}:${port}`
    : prod[key];
  return `${baseEndpoint}/${apiPrefix}/${apiVersion}/`;
};

export const baseURL = generateURL({});
export const feURL = generateURL({ key: "fe", port: "3000" });
export const basePURL = generateURL({
  port: "3010",
  key: "partner",
  // isLocal: true,
});

let urls = {
  // development: "https://api.skysales.jp/api/v1/",
  development: generateURL({ isDev: true }),
  production: generateURL({ isDev: false }),
};

export const axios = Axios.create({
  baseURL: urls[process.env.REACT_APP_ENV],
});
// axios with header
export const authAxios = Axios.create({
  baseURL: urls[process.env.REACT_APP_ENV],
});
// axios intercepting changes in storage
authAxios.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state?.login?.token?.access_token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
